
import RSCard from "ui/RSCard";
import { useEffect, useRef, useState } from "react";
import { analyticsCity, getCityList, saveCity } from "api/admin/city";
import RSTable from "ui/RSTable";
import ISChipDialog from "ui/ISChipDialog";
import ISForm from "ui/ISForm";
import { Skeleton, Stack, Typography } from "@mui/material";


export default function CityList({ countryID }) {

  const [cities, setCities] = useState()
  
  const fetchCity = async () => {
    const data = await getCityList(countryID)
    if(data) setCities(data)
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'City',
      render: city=> city.name
    },
    {
      title: '城市',
      render: city => city.hide === '0'?city.name_cn:city.name_cn+' (隐藏)',
    },
    {
      title: 'State',
      dataIndex: 'state'
    },
    {
      title: 'Lat',
      dataIndex: 'lat'
    },
    {
      title: 'Lng',
      dataIndex: 'lng'
    },
    {
      title: '操作',
      render: city => 
        <Stack direction="row">
          <CityEditor city={city} reloadList={fetchCity} />
          <CityAnalytics city={city} />
        </Stack>
    }
  ]

  useEffect(() => {
    if(countryID !== null) fetchCity()
  }, [countryID])

  return (
    <RSCard 
      title="城市"
    >
      <RSTable data={cities} columns={columns} />
    </RSCard>
  )
}

/**
 * 城市信息编辑
 * @param {*} param0 
 * @returns 
 */
function CityEditor({ city, reloadList }) {

  const form = [
    {
      contents: [
        {
          ele: 'input',
          name: 'name',
          label: 'Name'
        },
        {
          ele: 'input',
          name: 'name_cn',
          label: '名称'
        }
      ]
    },
    {
      contents: [
        {
          ele: 'input',
          name: 'lat',
          label: 'Lat'
        },
        {
          ele: 'input',
          name: 'lng',
          label: 'Lng'
        }
      ]
    },
    {
      contents: [
        {
          ele: 'checkbox',
          name: 'hide',
          label: '隐藏'
        },
        {
          ele: 'checkbox',
          name: 'flat',
          label: '社会公寓'
        }
      ]
    }
  ]

  const ref = useRef()

  const handleSave = async (data) => {
    await saveCity(data)
    ref.current.close()
    reloadList()
  }

  return (
    <ISChipDialog
      chipLabel="编辑"
      dialogTitle="编辑城市"
      ref={ref}
    >
      <ISForm data={city} form={form} onSave={handleSave} />
    </ISChipDialog>
  )
}


function CityAnalytics({city}) {

  const ref = useRef()
  const [data, setData] = useState()

  const handleOpen = async () => {
    const res = await analyticsCity(city.id)
    if(res) setData(res)
  }

  return (
    <ISChipDialog
      chipLabel="分析"
      dialogTitle="数据分析"
      onOpen={handleOpen}
      ref={ref}
    >
      {data ? 
      <Stack>
        <Typography>在线房源：{data.property}</Typography>
        <Typography>近年成单：{data.booking}</Typography>
      </Stack>
      :
      <Skeleton />}
    </ISChipDialog>
  ) 
}