import { Close } from "@mui/icons-material"
import { Box, Button, Chip, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material"
import { forwardRef, useImperativeHandle, useState } from "react"

/**
 * @param chipType optional null is chip | text
 * @param chipLabel
 * @param dialogTitle
 * @param onOpen
 * @param iconAction 右上角的图标按钮，只能放一个
 */
const ISChipDialog = forwardRef((props, ref) => {

  const {
    icon,
    chipType,
    chipLabel,
    dialogTitle,
    onOpen,
    onClose,
    variant,
    iconAction,
    children,
    noContentPadding,
    ...rest
  } = props

  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
    if(onOpen) onOpen()
  }

  const closeDialog = () => {
    setOpen(false)
    if(onClose) onClose()
  }

  useImperativeHandle(ref, () => ({
    open() { openDialog() },
    close() { closeDialog() },
    isPresent() { return open }
  }))

  return (
    <>
    {!chipType && <Chip label={chipLabel} onClick={openDialog} />}
    {chipType === 'text' && <Typography onClick={openDialog} sx={{cursor: 'pointer'}}>{chipLabel}</Typography>}
    {chipType === 'button' && <Button onClick={openDialog} variant={variant}>{chipLabel}</Button>}
    {chipType === 'icon' && <IconButton onClick={openDialog}>{icon}</IconButton>}
    <Dialog
      open={open}
      onClose={closeDialog}
      {...rest}
    >
      <DialogTitle sx={{ background: '#eee', paddingRight: '1rem', paddingY: '0.5rem' }}> 
        <Stack direction="row" alignItems="center">
          <Typography variant="h6">{dialogTitle?dialogTitle:chipLabel}</Typography>
          <Box flexGrow={1} />
          {iconAction}
          <IconButton onClick={closeDialog}>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers sx={{padding: noContentPadding?0:'16px 24px'}}>
        {children}
      </DialogContent>

    </Dialog>
    </>
  )
})

export default ISChipDialog