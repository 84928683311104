import { Stack } from "@mui/material";

export default function RowStack({ children, ...props }) {

  return (
    <Stack direction="row" alignItems="center" spacing={1} {...props}>
      {children}
    </Stack>
  )

}