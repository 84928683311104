import { updateBooking } from "api/admin/booking";
import RSSelector from "ui/RSSelector";

/**
 * 上级代理设置
 * @param {*} booking 
 * @returns 
 */
export default function SuperiorSelector({ booking, setBooking }) {

  const options = [
    {key: '0', value: '-'},
    {key: '1', value: 'Amber'},
    {key: '2', value: 'Uhomes'}
  ]

  const handleSelect = (option) => {
    const data = {id: booking.id, superior_id: option.key}
    setBooking({...booking, ...data})
    updateBooking(data)
  }

  return <RSSelector selectedKey={booking.superior_id} options={options} onSelect={handleSelect} />
}