import { Chip } from "@mui/material";

/**
 * Chip 点击新开页面
 * @param {*} label - Chip label
 * @param {*} url - https://... 如果url是空的则隐藏chip
 * @returns 
 */
export default function LinkChip({ label, url }) {

  const handleClick = () => {
    window.open(url)
  }

  return (
    <>
    {
      url && url !== '' && 
      <Chip label={label} onClick={handleClick} />
    }
    </>
  )
}