import { Chip, Stack, Typography } from "@mui/material";
import { deleteNoOSSImg, deleteUselessImg } from "api/admin/developer/dataClean";
import { AUAccommodationImg } from "api/admin/developer/dataRefine";
import { useState } from "react";
import RowStack from "ui/RowStack";


export default function DataCleanPage() {

  const [text, setText] = useState('')
  const [stop, setStop] = useState(false)
  const handleDeleteUselessImg = async () => {
    const data = await deleteUselessImg()
    if(data) {
      setText(data)
      handleDeleteUselessImg()
    }
  }


  const handleDeleteNoOSSImg = async () => {
    setStop(true)
    const data = await deleteNoOSSImg()
    if(data) {
      setText(data)
      handleDeleteNoOSSImg()
    }
  }

  const handleAddImg = async () => {
    setStop(true)
    const data = await AUAccommodationImg()
    if(data) {
      setText(data)
      handleAddImg()
    }
  }
 
  return (
    <Stack>
      {false && 
      <RowStack>
        无 accommodation_id 图片
        <Chip label="删除" onClick={handleDeleteUselessImg} />
      </RowStack>
      }

      {false && 
      <RowStack>
        <Typography>删除无 oss 图片</Typography>
        {!stop && <Chip label="开始" onClick={handleDeleteNoOSSImg} />}
      </RowStack>
      }

      <RowStack>
        <Typography>补图片</Typography>
        {!stop && <Chip label="开始" onClick={handleAddImg} />}
      </RowStack>

      <Typography>{text}</Typography>
    </Stack>
  )
}