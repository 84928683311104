/* 企业微信登录 */
import { Button } from "@mui/material"
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import RSDialog from "ui/RSDialog"

export default function WeComSignInButton() 
{
  const state = 'wecom'
  const appID = 'wwf7822ee49ef0f95c' // 企业ID
  const agentID = '1000006' // 应用ID 51ROOM 企业微信登录
  const redirectUri = 'https://admin.51room.com/auth/wecom' // 需要和授权回调域一致
  const url = 'https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid='+appID+'&redirect_uri='+redirectUri+'&response_type=code&scope=snsapi_base&state='+state+'&agentid='+agentID+'#wechat_redirect'

  const [open, setOpen] = useState(false)
  const nav = useNavigate()

  const openDialog = () => {
    if(process.env.NODE_ENV === 'development') nav('/auth/wecom')
    else setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
    <Button variant="contained" onClick={openDialog}>企业微信登录</Button>
    <RSDialog
      open={open}
      onClose={closeDialog}
      maxWidth="xs"
    >
      <iframe 
        height="380"
        title="Wechat QRCode"
        frameborder="0"
        sandbox="allow-scripts allow-same-origin allow-top-navigation"
        style={{overflow: "hidden"}}
        src={url} 
      />
    </RSDialog>
    </>
  )
}