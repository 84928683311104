import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";
import { filterProperty } from "api/web/property";
import PropertyGrid from "components/property/PropertyGrid";
import CitySelector from "components/selector/CitySelector";
import { countryIDToDefaultCityID } from "configs/country";
import HideSelector from "components/selector/HideSelector";
import RowStack from "ui/RowStack";
import PropertyTypeSelector from "../../selector/PropertyTypeSelector";

/**
 * Card 房源列表
 * @returns 
 */
export default function PropertyList({ 
  countryID,
  noCity, // 如中国香港, 北爱，单个城市不需要城市选择器 
  noType,
  actions
}) {

  const [filter, setFilter] = useState()
  const [properties, setProperties] = useState()
  const [pages, setPages] = useState(1)

  useEffect(() => {
    const id = countryIDToDefaultCityID(countryID)
    setFilter({
      page: 1,
      city_id: id,
      hide: 0,
      type: 9
    })
  }, [])

  useEffect(() => {
    if(filter) fetchProperty()
  }, [filter])

  const fetchProperty = async () => {
    setProperties(null)
    const res = await filterProperty(filter)
    if(res) {
      setProperties(res.properties)
      setPages(res.pages)
    }
  }

  const handleChangePage = (e, v) => {
    setFilter({
      ...filter,
      page: v
    })
  }


  /**
   * 选择城市
   * @param {*} option 
   */
  const handleSelectCity = (option) => {
    setFilter({
      ...filter,
      page: 1,
      city_id: option.key
    })
  }

  const handleToggleHide = (option) => {
    setFilter({
      ...filter,
      page: 1,
      hide: option.key
    })
  }

  const handleChangeType = (option) => {
    setFilter({
      ...filter,
      page: 1,
      type: option.key
    })
  }

  return (
    <RSCard
      title={"房源"}
      action={
        filter && 
        <RowStack>
          { /* 城市选择器 */
            !noCity &&
            <CitySelector 
              cityID={filter.city_id}
              countryID={countryID}
              onSelect={handleSelectCity}
            />
          }

          { actions && 
            actions.map(action => action)
          }

          { /* 房型类型 仅英国和澳洲需要 */
            !noType &&
            <PropertyTypeSelector type={filter.type} onSelect={handleChangeType} />
          }

          { /* 显示筛选器 */ }
          <HideSelector hide={filter.mark_hide} onSelect={handleToggleHide} />
        
         
        </RowStack>
      }
    >
      {filter &&
      <PropertyGrid 
        properties={properties}
        pages={pages}
        page={filter.page}
        handleChangePage={handleChangePage}
        reloadProperties={fetchProperty}
      />
      }
    </RSCard>
  )
}