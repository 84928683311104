import { Chip, Stack } from "@mui/material";
import { getNewBookings } from "api/admin/booking";
import Booking from "components/booking/Booking";
import BookingList from "components/booking/BookingList";
import BookingCustomer from "components/booking/table/BookingCustomer";
import BookingLease from "components/booking/table/BookingLease";
import BookingProperty from "components/booking/table/BookingProperty";
import CountrySelector from "components/selector/CountrySelector";
import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";
import RSSelector from "ui/RSSelector";
import RSTable from "ui/RSTable";
import RowStack from "ui/RowStack";

/**
 * 新订单管理页面
 * @returns 
 */
export default function NewBookings() {

  const [bookings, setBookings] = useState(null)
  const [filter, setFilter] = useState()

  const fetchBookings = async () => {
    setBookings(null)
    const data = await getNewBookings(filter)
    if(data) setBookings(data)
    else setBookings([])
  }

  useEffect(() => {
    setFilter({
      country: null,
      onlyMine: 0
    })
  }, [])

  useEffect(() => {
    if(filter) fetchBookings()
  }, [filter])

  const handleSelectCountry = (option) => {
    setFilter({
      ...filter,
      country: option.key
    })
  }

  const handleSelectMine = (option) => {
    setFilter({
      ...filter,
      onlyMine: option.key
    })
  }

  return (
    <RSCard 
      title="新订单管理" 
      action={
        filter &&
        <RowStack>
          <Chip label="刷新" onClick={fetchBookings} />
          <CountrySelector countryID={filter.country} onSelect={handleSelectCountry} />
          <MyBookingsSelector onlyMine={filter.onlyMine} onSelect={handleSelectMine} />
        </RowStack>
      }
    >
      <BookingList bookings={bookings} setBookings={setBookings} />
    </RSCard>
  )
}



// 选择全部/我跟进的

function MyBookingsSelector({ onlyMine, onSelect }) {

  const options = [
    {key: '0', value: '全部订单'},
    {key: '1', value: '我跟进的'}
  ]

  return <RSSelector selectedKey={onlyMine} options={options} onSelect={onSelect} />
}