import { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import RSCard from "ui/RSCard";
import InvoiceList from "./components/InvoiceList";
import { getUnpaidCommission } from "api/finance";
import CommissionSummary from "./components/CommissionSummary";
import { countryIDtoCurrency } from "configs/country";
import AddANewCommission from "./components/AddANewCommission";
import RSYearSelector from "ui/RSYearSelector";
import dayjs from "dayjs";
import ProviderSearchForCommission from "./components/ProviderSearchForCommission";

/**
 * 页面 佣金管理
 * @returns 
 */
export default function Commission() {

  const [currency, setCurrency] = useState(0)
  const [invoices, setInvoices] = useState(null)
  const [year, setYear] = useState()
  const [state, setState] = useState(0)

  const fetchInvoices = async () => {
    if(!year) return false
    setInvoices(null)
    const data = await getUnpaidCommission(year, currency, state)
    if(data) setInvoices(data)
  }

  useEffect(() => {
    const y = year?year:dayjs().format('YYYY')
    setYear(Number(y))
  }, [])

  useEffect(() => {
    fetchInvoices()
  }, [currency, year, state])

  const handleAdd = (invoice) => {
    setInvoices([invoice, ...invoices])
  }

  const Action = () => 
    <Stack direction="row" spacing={1}>
      <ProviderSearchForCommission />
      <AddANewCommission callback={handleAdd} />
      <RSYearSelector year={year} onSelect={setYear} />
    </Stack>

  return (
    <Grid container>
      <Grid item xs={2}>
        <CommissionSummary setCurrency={setCurrency} year={year} state={state} setState={setState} />
      </Grid>

      <Grid item xs={10}>
        <RSCard
          title={"应收佣金 "+countryIDtoCurrency(currency)}
          action={<Action />}
        >
          <InvoiceList invoices={invoices} setInvoices={setInvoices} />
        </RSCard>
      </Grid>
    </Grid>
  )
}