import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import MenuItems from "../components/MenuItems";

const menu = {
  "text": "业务数据",
  "icon": InsertChartOutlinedIcon,
  "permission": "analytics",
  "sub": [
    {
      "text": "英国",
      "path": "/analytics/uk"
    },
    {
      "text": "澳洲",
      "path": "/analytics/au"
    },
    {
      "text": "香港",
      "path": "/analytics/hk"
    },    
  ]
}

export default function AnalyticsMenu() {

  return <MenuItems menu={menu} />
}