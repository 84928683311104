import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'app',
  initialState: {
    user: null,
    googleMapLoaded: false
  },
  reducers: {
    setUser:(state, action) => {
      state.user = action.payload
    },
    setGoogleMapLoaded:(state, action) => {
      state.googleMapLoaded = action.payload
    },
  },
})

export const { 
  setUser,
  setGoogleMapLoaded
} = slice.actions
export const appStates = state => state.app
export default slice.reducer