import { IconButton } from "@mui/material"
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material"
import RSTable from "ui/RSTable"
import agentBookingsColumns from "components/agent/componnets/agentBookingsColumns"

/**
 * 订单列表 用于选择订单
 * @param { array } bookings 
 * @param { string } side left | right 用于箭头方向
 * @param { function } onSelect 选中后的方法
 * @returns 
 */
export default function BookingTableForSelector({ bookings, side, onSelect }) {

  const columns = agentBookingsColumns()
  columns.push(
    {
      title: '',
      render: (booking) => <IconButton onClick={()=>onSelect(booking)}>{side==='left' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}</IconButton>
    }
  )

  return (
    <RSTable columns={columns} data={bookings} />
  )
}