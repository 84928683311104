import { Box, Stack } from '@mui/material'
import SiderToggleButton from './components/SiderToggleButton';
import UserMenu from './components/UserMenu';
import Search from './components/Search';

export default function Top() {


  return (
    <Box>
      <Stack 
        direction="row"
        spacing={1}
        sx={{ 
          padding: 1,
          bgcolor: '#eee', 
          displayPrint: 'none'
        }}
        alignItems="center"
      >
        <Box>
          <SiderToggleButton />
        </Box>

       

        <Box flexGrow={1}></Box>
        <Search />
        <UserMenu />
      </Stack>
    </Box>
  )
}

