import { changeBookingState } from "api/admin/booking";
import RSSelector from "ui/RSSelector";

/**
 * 订单状态选择器
 * @param { object } booking 
 * @param { function } update
 * @returns 
 */
export default function BookingStateSelector({ booking }) {

  const options = [
    {key: '0', value: "办理中"},
    {key: '1', value: "已完成", color: "success"},
    {key: '2', value: "已取消", color: "error", variant: 'outlined'}
  ]

  const handleUpdate = async (option) => {
    await changeBookingState(booking.id, option.key)
  } 

  return <RSSelector options={options} selectedKey={booking.state} onSelect={handleUpdate} />
}