import { getUninvoicedBookings } from "api/finance";
import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";
import RSTable from "ui/RSTable";
import { startLoading, stopLoading } from "utils/layout";
import uninvoicedBookingsColumns from "./unInvoicedBookingsColumns";
import dayjs from "dayjs";
import RSYearSelector from "ui/RSYearSelector";


export default function UninvoicedBookings() {

  const columns = uninvoicedBookingsColumns()
  const [bookings, setBookings] = useState([])
  const [year, setYear] = useState()

  const fetchBookings = async () => {
    setBookings([])
    startLoading()
    const data = await getUninvoicedBookings(year)
    if(data) setBookings(data)
    stopLoading()
  }

  useEffect(() => {
    const y = year?year:dayjs().format('YYYY')
    setYear(Number(y))
  }, [])

  useEffect(() => {
    if(year) fetchBookings()
  }, [year])

  return (
    <RSCard title="待结算订单 - 未发Invoice" action={<RSYearSelector year={year} onSelect={setYear} />}>
      <RSTable data={bookings} columns={columns} />
    </RSCard>
  )
}