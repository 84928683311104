import { Stack, Typography } from "@mui/material";
import { lease } from "utils/booking";

/**
 * 订单租期
 * @param { object } booking 
 * @returns 
 */
export default function BookingLease({ booking }) {
  return (
    <Stack spacing={0}>
      <Typography>{lease(booking.check_in, booking.check_out)}</Typography>
      <Typography variant="caption">{booking.check_in}</Typography>
      <Typography variant="caption">{booking.check_out}</Typography>
    </Stack>
  )
}