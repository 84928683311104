
import { useState } from "react";
import { getAgent } from "api/agent";
import RSTextButton from "ui/RSTextButton";
import RSDialog from "ui/RSDialog";
import { Chip, Grid, Stack } from "@mui/material";
import AgentInfo from "./componnets/AgentInfo";
import AgentUnpaidBookings from "./componnets/AgentUnpaidBookings";
import AgentAccounts from "./componnets/AgentAccounts";

/**
 * 渠道名称 点击后打开渠道信息窗口
 * @param { string } agentName
 * @param { string } agentID
 * @param { function } callback Agent 信息更新后传回信息 
 * @returns 
 */

export default function Agent({ agentName, agentID, callback, display = 'text' }) {

  const [agent, setAgent] = useState()
  const [open, setOpen] = useState(false)

  const fetchAgent = async () => {
    const data = await getAgent(agentID)
    if(data) setAgent(data)
  } 

  const openDialog = () => {
    setOpen(true)
    fetchAgent()
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
  <>
    {display === 'text' && <RSTextButton onClick={openDialog} text={agentID+' '+agentName} />}
    {display === 'chip' && <Chip onClick={openDialog} label={agentName} />}
    <RSDialog
      open={open}
      onClose={closeDialog}
      title={agentName}
      maxWidth="xl"
      fullWidth
    >
      <Grid container>
        <Grid item xs={3}>
          <Stack>
            <AgentInfo 
              agent={agent} 
              setAgent={setAgent} 
              callback={callback} 
            />
            <AgentAccounts 
              agent={agent} 
              setAgent={setAgent} 
              callback={callback} 
            />
          </Stack>
        </Grid>

        <Grid item xs={9}>
          <AgentUnpaidBookings agentID={agentID} />
        </Grid>
      </Grid>
    </RSDialog>
  </>
  )
}