/* PID */

import { Typography } from "@mui/material";
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import RowStack from "ui/RowStack";

export default function PID({ id }){
  return (
    <RowStack>
      <BookmarkBorderOutlinedIcon />
      <Typography>编号 {id}</Typography>
    </RowStack>
  )
}