import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import MenuItems from "../components/MenuItems";

const menu = {
  "text": "财务管理",
  "icon": SavingsOutlinedIcon,
  "permission": "agent",
  "sub": [
    {
      "text": "收支明细",
      "path": "/finance/transactions"
    },    
    {
      "text": "佣金结算",
      "path": "/finance/commission"
    },    
    {
      "text": "待结订单",
      "path": "/finance/uninvoiced-bookings"
    },
    {
      "text": "客户返现",
      "path": "/finance/cashback"
    },
  ]
}

export default function FinanceMenu() {

  return <MenuItems menu={menu} />
}