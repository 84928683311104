import { useSelector } from "react-redux";
import { propertyStates } from "redux/propertySlice";
import ChipLink from "ui/ChipLink";

/**
 * 房源官网
 * @returns 
 */
export default function OfficialLink() {
  const { property } = useSelector(propertyStates)
  console.log(property.source_url)
  if(property.source_url === '') return null
  const label = "官网"
  const url = property.source_url
  return <ChipLink label={label} href={url} />
}