/* 房源供应商 数据分析 */

import { Skeleton, Stack, Typography } from "@mui/material";
import { getAnalyticsData } from "api/provider";
import { useState } from "react";
import ISChipDialog from "ui/ISChipDialog";

export default function ProviderAnalytics({ providerID }) {

  const [data, setData] = useState(null)

  const fetchData = async () => {
    const res = await getAnalyticsData(providerID)
    if(res) setData(res)
  }

  const handleOpen = () => {
    if(!data) fetchData()
  }

  return (
    <ISChipDialog
      chipLabel="分析"
      onOpen={handleOpen}
    >
      {!data && <Skeleton />}
      {
        data &&
        <Stack>
          <Typography>上线房源：{data.property_live}</Typography>
          <Typography>隐藏房源：{data.property_hide}</Typography>
          <Typography>近年成单：{data.complete_booking}</Typography>
        </Stack>
      }
    </ISChipDialog>
  )
}