import { useRef } from "react";
import PropertyDialog from "./PropertyDialog";
import { Chip } from "@mui/material";


export default function PropertyID({ pid }) {

  const ref = useRef()

  const openDialog = () => {
    ref.current.open()
  }

  return (
    <>
    <Chip label={pid} onClick={openDialog} />
    <PropertyDialog ref={ref} propertyID={pid} />
    </>
  )
}