import { Chip, Grid } from "@mui/material"
import { updateBooking } from "api/admin/booking"
import { getUnpaidBookings } from "api/admin/provider"
import BookingTableForSelector from "components/booking/table/BookingTableForSelector"
import { useState } from "react"
import RSCard from "ui/RSCard"
import RSDialog from "ui/RSDialog"


export default function InvoiceBookingSelector({ invoice, bookings, setBookings }) {

  const [open, setOpen] = useState(false)
  const [pendingBookings, setPendingBookings] = useState([])

  const openDialog = () => {
    setOpen(true)
    fetchBookings()
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const fetchBookings = async () => {
    const data = await getUnpaidBookings(invoice.provider_id)
    if(data) setPendingBookings(data)
  }

  // 加入订单
  const handleSelectLeft = (booking) => {
    setBookings([booking, ...bookings])
    setPendingBookings(pendingBookings.filter(b => b.id !== booking.id))
    updateBooking({id: booking.id, commission_invoice_id: invoice.id})
  }

  // 移出订单
  const handleSelectRight = (booking) => {
    setPendingBookings([booking, ...pendingBookings])
    setBookings(bookings.filter(b => b.id !== booking.id))
    updateBooking({id: booking.id, commission_invoice_id: 0})
  }

  return (
    <>
    <Chip label="筛选订单" onClick={openDialog} />
    <RSDialog
      title="佣金结算 - 订单筛选"
      open={open}
      onClose={closeDialog}
      fullScreen
    >
      <Grid container>
        <Grid item xs={6}>
          <RSCard title="待结算">
            <BookingTableForSelector bookings={pendingBookings} side="left" onSelect={handleSelectLeft} />
          </RSCard>
        </Grid>

        <Grid item xs={6}>
          <RSCard title={invoice.reference_number}>
            <BookingTableForSelector bookings={bookings} side="right" onSelect={handleSelectRight}  />
          </RSCard>
        </Grid>
      </Grid>
    </RSDialog>
    </>
  )
}