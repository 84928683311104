import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import App from './App';
// import reportWebVitals from './reportWebVitals';

// Redux
import store from 'redux/store';
import { Provider as ReduxProvider } from 'react-redux'

// Mui
import { ThemeProvider } from '@mui/material';
import theme from 'theme';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (process.env.NODE_ENV === "production") console.log = function no_console() {};

root.render(
  <ReduxProvider store={store}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
