import RSSelector from "ui/RSSelector";

/**
 * 客户信息-性别选择器
 * @param { object } booking 
 * @param { function } update
 * @returns 
 */
export default function GenderSelector({ booking, update }) {

  const options = [
    {key: '0', value: "女"},
    {key: '1', value: "男"}
  ]

  const handleUpdate = async (option) => {
    update({ id: booking.id, customer_gender: option.key})
  } 

  return <RSSelector options={options} selectedKey={booking.customer_gender} onSelect={handleUpdate} prefix="性别：" />
}