import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material"
import { createContext } from "react"
import { Close } from "@mui/icons-material"
import PropertyView from "./view/PropertyView"
import { useSelector } from "react-redux"
import { closePropertyDialog, propertyStates } from "redux/propertySlice"
import RowStack from "ui/RowStack"
import Spacer from "ui/Spacer"
import PropertyEditor from "./editor/PropertyEditor"

export const PropertyContext = createContext()

export default function PropertyDialog() {
  
  const { open, property } = useSelector(propertyStates)

  return (
    <Dialog
      open={open}
      fullWidth
      onClose={closePropertyDialog}
      maxWidth="md"
    >
      <DialogTitle 
        sx={{ 
          background: '#eee', 
          paddingRight: '1rem', 
          paddingY: '0.5rem'
        }}
      >
        <RowStack>
          <Stack spacing={0}>
            <Typography variant="h6">{property?property.name:'Loading...'}</Typography>
            <Typography variant="caption">{property?property.address:''}</Typography>
          </Stack>
          <Spacer />
          <IconButton onClick={closePropertyDialog}>
            <Close />
          </IconButton>
        </RowStack>
      </DialogTitle>

      <DialogContent>
        <Grid container mt={1}>
          <Grid item xs={12} lg={6}>
            {/* 房源信息展示 和前台同步 */}
            {property ? <PropertyView /> : <Skeleton />}
          </Grid>
    
          <Grid item xs={12} lg={6}>
            {/* 房源信息编辑 */}
            {property ? <PropertyEditor /> : <Skeleton />}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}