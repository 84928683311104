import { AddOutlined } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { addProviderProperty } from "api/admin/provider";
import { getCountryByID } from "configs/country";
import { startLoading, stopLoading } from "utils/layout";

/**
 * 新建房源按钮
 */
export default function PropertyAddNewIconButton({ provider, callback }) {

  const handleAddNew = async () => {
    startLoading()
    const country = getCountryByID(provider.country_id)
    const data = {
      name: '新建公寓-待编辑',
      mark_hide: 1,
      provider: provider.name,
      provider_id: provider.id,
      country_id: provider.country_id,
      city: country.defaultCityName,
      city_id: country.defaultCityID,
    }
    await addProviderProperty(data)
    stopLoading()
    callback()
  }

  return (
    <IconButton onClick={handleAddNew}>
      <AddOutlined />
    </IconButton>
  )
}