import { useDispatch, useSelector } from "react-redux";
import { Box, LinearProgress, styled } from '@mui/material';
import { siderStates } from 'redux/siderSlice';
import Sider from './sider/Sider';
import { Outlet, useNavigate } from "react-router-dom";
import Top from "./top/Top";
import RSSnackbar from "./snack/RSSnackbar";
import RSLoader from "./loader/RSLoader";
import { appStates, setGoogleMapLoaded, setUser } from "redux/appSlice";
import { getMyInfo } from "api/admin/me";
import { useEffect } from "react";
import AuthCard from "pages/auth/components/AuthCard";
import { setOpen } from "redux/snackSlice";
import PropertyDialog from "components/property/PropertyDialog";
import { LoadScript } from "@react-google-maps/api";
import { googleMapsApiKey } from "configs/google";

const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

export default function Layout() {

  const { user } = useSelector(appStates)
  const { siderOpen } = useSelector(siderStates)

  const dispatch = useDispatch()
  const nav = useNavigate()

  const fetchData = async () => {
    const data = await getMyInfo()
    if(data) {
      dispatch(setUser(data))
    } else {
      dispatch(setOpen(false))
      nav('auth/signIn')
    }
  }

  useEffect(()=>{
    fetchData()
  }, [])

  const handleLoaded = () => {
    dispatch(setGoogleMapLoaded(true))
  }

  return (
    <>
    {!user ? 
      <AuthCard>
        <LinearProgress />
      </AuthCard> 

    : 
    
    <Box display='flex'>
      <Sider />
      <Main open={siderOpen}>
        <Top />
        <Box sx={{background: '#fff', minHeight: '93vh'}}>
          <Box p={2}>
            <Outlet />
          </Box>
        </Box>
      </Main>
    </Box>
    
    }
    
    <RSLoader />
    <RSSnackbar />

    <LoadScript googleMapsApiKey={googleMapsApiKey} onLoad={handleLoaded} />
    <PropertyDialog />
    </>
    
  )
}

