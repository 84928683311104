import { Box, Divider, Stack, Typography } from "@mui/material";
import { updateCommission } from "api/finance";
import CurrencySelector from "components/selector/CurrencySelector";
import RSTextEditor from "ui/RSTextEditor";
import InvoiceStateSelector from "./InvoiceStateSelector";

/**
 * 佣金结算记录信息编辑
 * @param {*} invoice
 * @param {*} setInvoice
 * @param {*} onUpdate
 * @returns 
 */
export default function InvoiceInfo({ invoice, setInvoice, onUpdate }) {

  const update = (data) => {
    setInvoice({
      ...invoice,
      ...data
    })
    updateCommission(data)
    if(onUpdate) onUpdate(data)
  }

  return (
    <Stack>
      <Stack direction="row">
        <RSTextEditor item={invoice} entity="reference_number" callback={update} />
        <Box flexGrow={1} />
        <InvoiceStateSelector invoice={invoice} update={update} />
      </Stack>
     
      <Divider />
      <RSTextEditor item={invoice} entity="send_date" callback={update} prefix="发送时间：" />
      <Stack direction="row" alignItems='center'>
        <Typography>应收：</Typography>
        <CurrencySelector currencyID={invoice.amount_currency_type} onSelect={(option)=>update({id: invoice.id, amount_currency_type:option.key})} />
        <RSTextEditor item={invoice} entity='amount' callback={update}/>
      </Stack>
      <Divider />
      <RSTextEditor item={invoice} entity='date' callback={update} prefix="到账时间：" />
      <Stack direction="row" alignItems='center'>
        <Typography>实收：</Typography>
        <CurrencySelector currencyID={invoice.currency_type} onSelect={(option)=>update({id: invoice.id, currency_type:option.key})} />
        <RSTextEditor item={invoice} entity='total' callback={update} />
      </Stack>
      <Divider />
      <RSTextEditor item={invoice} entity='note' callback={update} prefix="备注：" />
      <Divider />
    </Stack>
  )
}