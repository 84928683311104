import { Button, Stack, Typography } from "@mui/material";
import { filterRooms } from "api/hk";
import { RoomTypeSelector } from "components/property/editor/HKRoomEditor";
import { useState } from "react";
import ISChipDialog from "ui/ISChipDialog";
import RSDialog from "ui/RSDialog";
import RSTable from "ui/RSTable";
import RSTextEditor from "ui/RSTextEditor";
import RowStack from "ui/RowStack";
import { formatPrice } from "utils/strFormat";
import { HKBathroom, HKKitchen, HKRoomTypeName } from "./HKRoomTypeHelper";
import PropertyID from "components/property/PropertyID";

/**
 * 香港房型价格筛选
 * @returns 
 */
export default function HKRoomFilter() {

  const [filter, setFilter] = useState({
    type: '0',
    ensuite: '0',
    min_price: '5000',
    max_price: '8000'
  })
  const [open, setOpen] = useState(false)
  const [rooms, setRooms] = useState()


  const fetchRooms = async () => {
    const data = await filterRooms(filter)
    if(data) setRooms(data)
    else setRooms([])
  }

  const handleFilter = () => {
    setOpen(true)
    fetchRooms()
  }

  const handleClose = () => {
    setRooms(null)
    setOpen(false)
  }

  const updateFilterPrice = (data) => {
    setFilter({
      ...filter,
      ...data
    })
  }

  const columns = [
    {
      title: '编号',
      render: item => <PropertyID pid={item.id} />
    },
    {
      title: '房源',
      dataIndex: 'name'
    },
    {
      title: '价格',
      render: item => formatPrice(item.price)
    },
    {
      title: '备注',
      dataIndex: 'note'
    }
  ]

  return (
    <ISChipDialog
      chipLabel="筛选"
      dialogTitle="房型价格筛选"
    >
      {
        filter && 
        <Stack>
          <RoomTypeSelector roomtype={filter} updateRoomType={(roomtype)=>setFilter({...filter, ...roomtype})} />
          <RowStack>
            <Typography>价格区间 HKD $</Typography>
            <RSTextEditor item={filter} entity="min_price" width={100} callback={updateFilterPrice} />
            <Typography> ~ </Typography>
            <RSTextEditor item={filter} entity="max_price"  width={100} callback={updateFilterPrice} />
          </RowStack>
          <Button variant="contained" onClick={handleFilter}>筛选</Button>
        </Stack>
      }
    
      <RSDialog
        title="筛选结果"
        open={open}
        onClose={handleClose}
      >
        <Stack>
          <RowStack>
            <Typography>{HKRoomTypeName(filter.type)}</Typography>,
            <Typography>{HKBathroom(filter.type, filter.ensuite)}</Typography>,
            <Typography>{HKKitchen(filter.type)}</Typography>,
            <Typography>价格区间 HKD ${filter.min_price} ~ {filter.max_price}</Typography>
          </RowStack>
          {
            rooms && <RSTable data={rooms} columns={columns} />
          }
        </Stack>
      </RSDialog>
    </ISChipDialog>
  )
}