import { configureStore } from '@reduxjs/toolkit'
import AppSlice from './appSlice'

// Layout
import snackSlice from 'redux/snackSlice'
import siderSlice from 'redux/siderSlice'
import loaderSlice from './loaderSlice'
import staffsSlice from './staffsSlice'
import propertySlice from './propertySlice'

export default configureStore({
  reducer: {
    app: AppSlice,
    loader: loaderSlice,
    sider: siderSlice,
    snack: snackSlice,
    staffs: staffsSlice,
    property: propertySlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
})