import { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import { getAllUnpaidBookings } from "api/agent";
import RSCard from "ui/RSCard";
import RSTable from "ui/RSTable";
import unpaidBookingsColumns from "pages/agent/unpaid-bookings/unpaidBookingsColumns";
import RSYearSelector from "ui/RSYearSelector";
import dayjs from "dayjs";

export default function AgentAllUnpaidBookings() {

  const [bookings, setBookings] = useState()
  const [year, setYear] = useState()
  const columns = unpaidBookingsColumns()

  const fetchBookings = async () => {
    setBookings(null)
    const data = await getAllUnpaidBookings(year)
    if(data) setBookings(data)
    else setBookings([])
  }

  useEffect(() => {
    const y = year?year:dayjs().format('YYYY')
    setYear(Number(y))
  }, [])

  useEffect(() => {
    if(year) fetchBookings()
  }, [year])

  return (
    <RSCard title="未结算的渠道订单" action={<RSYearSelector year={year} onSelect={setYear} />}>
      {!bookings && <Skeleton />}
      {bookings && <RSTable data={bookings} columns={columns} />}
    </RSCard>
  )
}