import { call } from "./core";

/* 房源 */

/**
 * 房源列表
 * @param {*} filter 
 * @returns 
 */
export const getPropertyList = async (filter) => {
  return await call('admin/property/property/getPropertyList', filter)
}

/* 搜索房源 */
export const searchProperty = async (keywords, page) => {
  return await call ('web/property/search', {keywords: keywords, page:page})
}

export const getProperty = async (propertyID) => {
  return await call('admin/property/property/getProperty/'+propertyID)
}

export const updateProperty = async (data) => {
  await call('admin/property/property/updateProperty', data)
}

export const deleteProperty = async (propertyID) => {
  await call('admin/property/property/deleteProperty/'+propertyID)
}

export const getFloorPlan = async (propertyID) => {
  return await call('admin/property/floorplan/getFloorPlan/'+propertyID)
}

export const uploadFloorPlan = async (data) => {
  await call('admin/property/floorplan/uploadFloorPlan', data, 'upload')
}

export const deleteFloorPlan = async (imgID) => {
  await call('admin/property/floorplan/deleteFloorPlan/'+imgID)
}

export const getImgs = async (propertyID) => {
  return await call('admin/property/property/getImgs/'+propertyID)
}

export const sortImgs = async (propertyID, data) => {
  return await call('admin/property/property/sortImgs/'+propertyID, data)
}

export const uploadImg = async (data) => {
  await call('admin/property/property/uploadImg', data, 'upload')
}

export const deleteImg = async (imgID) => {
  await call('admin/property/property/deleteImg/'+imgID)
}

/* Comments */

export const getPropertyComments = async (propertyID) => {
  return await call('admin/property/comment/getPropertyComments/'+propertyID)
}

export const addPropertyComment = async (data) => {
  return await call('admin/property/comment/addPropertyComment', data)
}

export const delPropertyComment = async (commentID) => {
  await call('admin/property/comment/delPropertyComment/'+commentID)
}

/* Promotions */
export const updatePropertyPromotion = async (data) => {
  await call('admin/property/promotion/update', data)
}

export const deletePropertyPromotion = async (promotionID) => {
  await call('admin/property/promotion/delete/'+promotionID)
}
