import { Divider, Stack } from "@mui/material";
import PropertyImgs from "./PropertyImgs";
import PropertyFeatures from "./PropertyFeatures";
import PropertyMedias from "./PropertyMedias";
import PropertyIntroduction from "./PropertyIntroduction";
import PropertyRentBox from "./PropertyRentBox";
import PropertyPromotions from "./PropertyPromotions";
import PropertyRooms from "./PropertyRooms";

export default function PropertyView() {

  return (
    <Stack>
      <PropertyImgs />
      <PropertyFeatures divider />
      <PropertyRentBox />
      <PropertyMedias />
      <PropertyPromotions />
      <PropertyIntroduction />
      <Divider />
      <PropertyRooms />
    </Stack>
  )
}