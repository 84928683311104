/* 香港 房型价格编辑器 */

import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Chip, Divider, IconButton, Stack } from "@mui/material";
import { addPrice, addRoomType, delPrice, delRoomType, updatePrice, updateRoomType } from "api/room";
import FullyBookedSelector from "components/selector/FullyBookedSelector";
import { HKRoomEnsuite, HKRoomType } from "configs/roomtype";
import ISCardActions from "ui/ISCardActions";
import ISChipDialog from "ui/ISChipDialog";
import RSSelector from "ui/RSSelector";
import RSTextEditor from "ui/RSTextEditor";
import RowStack from "ui/RowStack";
import Spacer from "ui/Spacer";
import { startLoading } from "utils/layout";
import RoomTypeImgEditor from "./RoomTypeImgEditor";
import { useContext } from "react";
import { PropertyContext } from "components/property/PropertyDialog";

export default function HKRoomEditor() {

  const {property, fetchProperty} = useContext(PropertyContext)

  
  // 新建房型
  const handleAddRoomType = async () => {
    startLoading()
    await addRoomType(property.id)
    fetchProperty()
  }

  // 房型信息更新
  const handleUpdateRoomType = async (updatedRoomType) => {
    startLoading()
    await updateRoomType(updatedRoomType)
    fetchProperty()
  };


  // 删除房型
  const handleDelRoomType = async (roomtypeID) => {
    if(window.confirm("确定删除房型和价格?")) {
      startLoading()
      await delRoomType(roomtypeID)
      fetchProperty()
    }
   
  }

  // 添加价格
  const handleAddPrice = async (roomtypeID) => {
    startLoading()
    await addPrice(roomtypeID)
    fetchProperty()
  }

  // 编辑价格
  const handleUpdatePrice = async (updatedPrice) => {
    startLoading()
    await updatePrice(updatedPrice)
    fetchProperty()
  }

  // 删除价格
  const handleDelPrice = async (priceID) => {
    if(window.confirm("确定删除价格?")) {

      startLoading()
      await delPrice(priceID)
      fetchProperty()
    }
  }

  return (
    <ISChipDialog
      chipLabel="房型"
      dialogTitle="房型价格编辑"
      maxWidth="sm"
      fullWidth
      iconAction={
        <IconButton onClick={handleAddRoomType}>
          <Add />
        </IconButton>
      }
    >
      <Stack>
      {
        property && property.roomtypes && property.roomtypes.map(roomtype => 
          <Card key={roomtype.id}>
            <CardHeader 
              sx={{background: "#eee"}} 
              title={
                <ISCardActions>
                  <RoomTypeSelector roomtype={roomtype} updateRoomType={handleUpdateRoomType} />
                  <Box flexGrow={1} />
                  <Chip label="删除房型" onClick={()=>handleDelRoomType(roomtype.id)} />
                </ISCardActions>
              } 
            />
            <Divider />
            <CardContent>
              <Stack>

                <Stack direction="row">
                  <RSTextEditor 
                    prefix="房型备注: "
                    item={roomtype} 
                    entity="description"
                    callback={handleUpdateRoomType}
                  />
                  <Spacer />
                  <RoomTypeImgEditor imgs={property.imgs} roomtype={roomtype} callback={handleUpdateRoomType} />
                  <Chip label="新建价格" onClick={()=>handleAddPrice(roomtype.id)} />
                </Stack>

                {
                  roomtype.prices.map(price => 
                    <PriceEditor key={price.id} price={price} updatePrice={handleUpdatePrice} delPrice={handleDelPrice} />
                  )
                }
              </Stack>

            </CardContent>
          </Card>
        )
      }

      </Stack>
    </ISChipDialog>
  )
}

/**
 * 房型选择器
 * @param {*} param0 
 * @returns 
 */
export function RoomTypeSelector({ roomtype, updateRoomType }) {

  const handelSelectRoomType = (option) => {
    updateRoomType({
      id: roomtype.id,
      type: option.key
    })
  }

  const handelSelectRoomEnsuite = (option) => {
    updateRoomType({
      id: roomtype.id,
      ensuite: option.key
    })
  }

  return (
    <Stack direction="row">
      <RSSelector selectedKey={roomtype.type} options={HKRoomType} onSelect={handelSelectRoomType} />
      {(Number(roomtype.type) < Number("2") || Number(roomtype.type) > Number("3") ) && <RSSelector selectedKey={roomtype.ensuite} options={HKRoomEnsuite} onSelect={handelSelectRoomEnsuite} />}
      {(Number(roomtype.type) < Number("2") || Number(roomtype.type) > Number("3") )&& <Chip label="公用厨房"/>}
      {(Number(roomtype.type) === Number("2") || Number(roomtype.type) === Number("3") ) && <Chip label="独立卫浴"/>}
      {(Number(roomtype.type) === Number("2") || Number(roomtype.type) === Number("3") ) && <Chip label="独立厨房"/>}
    </Stack>
  )
}

/**
 * 价格编辑器
 * @param {*} param0 
 * @returns 
 */
function PriceEditor({ price, updatePrice, delPrice }) {

  const handleUpdatePrice = (data) => {
    updatePrice({
      roomtype_id: price.roomtype_id,
      ...data
    })
  }

  const handleUpdateState = (option) => {
    updatePrice({
      roomtype_id: price.roomtype_id,
      id: price.id,
      state: option.key
    })
  }

  const handleDelPrice = () => {
    delPrice(price.id)
  }

  return (
    <Stack>

      <Divider />

      <Stack direction="row" alignItems="center">
        <RSTextEditor 
          prefix="HKD $"
          suffix=" /月"
          item={price}
          entity="price"
          callback={handleUpdatePrice}
        />
        <Box flexGrow={1} />

        <RowStack>
          <Chip label="删除价格" onClick={handleDelPrice} />
          <FullyBookedSelector state={price.state} onSelect={handleUpdateState} />
        </RowStack>
      </Stack>

      <RSTextEditor 
        prefix="价格备注："
        item={price}
        entity="note"
        callback={handleUpdatePrice}
      />
    </Stack>
  )
}