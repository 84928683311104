import ExpandableTypography from "ui/ExpandableTypography";
import { propertyStates } from "redux/propertySlice";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";

/**
 * 房源介绍文字 
 */
export default function PropertyIntroduction() {
  const { property } = useSelector(propertyStates)
  if(property && property.intro !== '') {
    return (
      <Stack>
        <ExpandableTypography text={property.intro} />
      </Stack>
    )
  }
  else return null
}