import { Button, Chip, Stack, TextField } from "@mui/material"
import { refund } from "api/payment"
import { useEffect, useRef, useState } from "react"
import Dialog from "ui/Dialog"
import { hasPermission, showFail, startLoading, stopLoading } from "utils/layout"

/**
 * 退款按钮
 * 用支付系统直接退款，然后通知客户已退款，可以自动生成代付出账记录
 */
export default function ButtonRefund({ payment, callback }) {
  const ref = useRef()
  const [amount, setAmount] = useState(0)

  useEffect(() => {
    if(payment) {
      const totalAmount = Number(payment.fee) + Number(payment.amount)
      setAmount(totalAmount)
    }
  }, [payment])
  
  if(!hasPermission('finance')) return null
  if(payment.status === '4' && payment.partial_refund === '0') return null 
  

 
  
  const handleClick = async () => {
    ref.current.open()
  }

  const handleChange = (e) => {
    setAmount(e.target.value)
  }

  const handleRefund = async () => {
    const totalAmount = Number(payment.fee) + Number(payment.amount)
    if(Number(amount) > totalAmount) showFail('金额过大')

    else if(window.confirm('确认退款?')) {
      startLoading()
      await refund({payment_id: payment.id, refund_amount: amount})
      ref.current.close()
      stopLoading()
      callback()
    }
  }

  return (
    <>
      <Chip label="退款" variant="outlined" color="error" onClick={handleClick} />
      <Dialog
        ref={ref}
        title="退款"
      >
        <Stack>
          <TextField name="amount" type='number' value={amount} onChange={handleChange} />
          <Button variant="contained" color="error" onClick={handleRefund}>退款</Button>
        </Stack>
      </Dialog>
    </>
  )
}