import { call } from "../core";

/* 城市 */

/**
 * 某国家城市或区域列表
 * @param {*} countryID
 */
export const getCityList = async (countryID) => {
  return await call('admin/property/city/getList/'+countryID)
}

/**
 * 保存城市信息
 * @param {*} data 
 */
export const saveCity = async (data) => {
  await call('admin/property/city/save', data)
}


export const analyticsCity = async (cityID) => {
  return await call('admin/property/city/analytics/'+cityID)
}