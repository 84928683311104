import { Dialog, DialogContent, LinearProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { loaderStates } from "redux/loaderSlice";

export default function RSLoader() {

  const { loading } = useSelector(loaderStates)

  return (
    <Dialog
      open={loading}
      maxWidth="xs"
    >
      <DialogContent sx={{width: 100}}>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  )
}