import { deleteMedia, updateMedia } from "api/admin/property-editor/media";
import { useRef } from "react";
import ISChipDialog from "ui/ISChipDialog";
import RSTable from "ui/RSTable";
import ISForm from "ui/ISForm";
import { IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { startLoading, stopLoading } from "utils/layout";
import { useSelector } from "react-redux";
import { fetchProperty, propertyStates } from "redux/propertySlice";

export default function MediaEditor() {
  const { property } = useSelector(propertyStates)

  const columns = [
    {
      title: '标题',
      dataIndex: 'title'
    },
    {
      title: '编辑',
      render: item => <Editor media={item} />
    }
  ]

  const addMedia = async () => {
    startLoading()
    await updateMedia({property_id: property.id, title: '新视频-待编辑'})
    await fetchProperty(property.id)
    stopLoading()
  }

  return (
    <ISChipDialog
      chipLabel="全景"
      dialogTitle="全景"
      fullWidth
      iconAction={
       
        <IconButton onClick={addMedia}>
          <AddOutlined />
        </IconButton>
      }
    >
      <RSTable data={property.medias} columns={columns} />
    </ISChipDialog>
  )
}


function Editor({ media }) {
  const { property } = useSelector(propertyStates)
  const form = [
    {
      ele: 'input',
      name: 'title',
      label: '标题'
    },
    {
      ele: 'note',
      name: 'content',
      label: '代码'
    },
  ]

  const ref = useRef()

  const handleSave = async (data) => {
    await updateMedia(data)
    ref.current.close()
    await fetchProperty(property.id)
    stopLoading()
  }

  const handleDelete = async () => {
    await deleteMedia(media.id)
    ref.current.close()
    await fetchProperty(property.id)
    stopLoading()
  }

  return (
    <ISChipDialog
      ref={ref}
      chipLabel="编辑"
      dialogTitle="编辑视频"
      fullWidth
    >
      <ISForm 
        data={media}
        form={form}
        onSave={handleSave}
        onDelete={handleDelete}
      />
    </ISChipDialog>
  )
}