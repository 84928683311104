import { updateProperty } from "api/property";
import { useSelector } from "react-redux";
import { fetchProperty, propertyStates } from "redux/propertySlice";
import { useRef } from "react";
import ISChipDialog from "ui/ISChipDialog";
import ISForm from "ui/ISForm";
import { Grid, Stack, Typography } from "@mui/material";

/**
 * 房源信息编辑
 * @returns 
 */
export default function PropertyInfoEditor() {

  const { property } = useSelector(propertyStates)

  const ref = useRef()

  const form = [
    {
      ele: 'input',
      name: 'name',
      label: '名称'
    },
    {
      ele: 'input',
      name: 'address',
      label: '地址'
    },
    {
      contents: [
        {
          ele: 'input',
          name: 'lat',
          label: 'Lat'
        },
        {
          ele: 'input',
          name: 'lng',
          label: 'Lng'
        }
      ]
    },
    {
      contents: [
        {
          ele: 'input',
          name: 'type',
          label: '房型(1 Bed Flat)'
        },
        {
          ele: 'input',
          name: 'rent',
          label: '租金'
        },
        {
          ele: 'date',
          name: 'avaliable_date',
          label: '空房'
        },
      ]
    },
    {
      contents: [
        {
          ele: 'input',
          name: 'bedroom',
          label: '卧室'
        },
        {
          ele: 'input',
          name: 'bathroom',
          label: '卫浴'
        },
        {
          ele: 'input',
          name: 'sq',
          label: '面积平米'
        },
        {
          ele: 'input',
          name: 'floor',
          label: '楼层'
        },
      ]
    },
    {
      ele: 'input',
      name: 'source_url',
      label: '官网'
    },
    {
      ele: 'input',
      name: 'feature',
      label: '特色'
    },
    {
      ele: 'note',
      name: 'intro',
      label: '介绍'
    }
  ]

  const handleSave = async (data) => {
    await updateProperty(data)
    fetchProperty(property.id)
    ref.current.close()
  }

  return (
    <ISChipDialog
      ref={ref}
      chipLabel="介绍"
      dialogTitle="编辑信息"
      fullWidth
      maxWidth="md"
    >
      <Grid container>
        <Grid item xs={8}>
          <ISForm data={property} form={form} onSave={handleSave} />
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography>
              地址格式：街道，城市，邮编<br/>
              46 Blair Street, London, E14 0JL
            </Typography>

            <Typography>
              房型固定文字包括大小写：<br/>
              Studio,<br/>
              1 Bed Flat,<br/>
              2 Bed Flat,<br/>
              Student Accommodation,<br/>
              其它可按照首字母大写的格式填写
            </Typography>

            <Typography>
              租金栏社会房源必填
            </Typography>

            <Typography>
              介绍文字格式<br/>
              【介绍】地区介绍，房源介绍，周边介绍，设施介绍<br/>
              【交通】周边学校，公共交通距离<br/><br/>
               注意段落之间需要空行，如：<br/>
               文字段落<br/>
               空行<br/>
               文字段落<br/>
               注意文字最后不要加空行
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </ISChipDialog>
  )
}