export const HKRoomType = [
  {key: "0", value: "单人间"},
  {key: "1", value: "双人间"},
  {key: "2", value: "开放间"},
  {key: "3", value: "整租"},
  {key: "4", value: "多人间"}
]

export const HKRoomEnsuite = [
  {key: "0", value: "公用卫浴"},
  {key: "1", value: "独立卫浴"}
]

export const HKRoomKitchen = [
  {key: "0", value: "公用厨房"},
  {key: "1", value: "独立厨房"}
]

export const RoomType = [
  {key: "0", value: "选择房型"},
  {key: "1", value: "Studio"},
  {key: "2", value: "Ensuite"},
  {key: "3", value: "Room"},
  {key: "4", value: "One Bed"},
  {key: "5", value: "Two Bed"},
  {key: "6", value: "Twin Room"}
]

export const RoomTypeDescription = [
  {key: "0", value: ""},
  {key: "1", value: "独立卫浴, 独立厨房"},
  {key: "2", value: "独立卫浴, 公用厨房"},
  {key: "3", value: "公用卫浴, 公用厨房"},
  {key: "4", value: "一室一厅"},
  {key: "5", value: "两室一厅"},
  {key: "6", value: "一间房间两张床"}
]