import { Chip } from "@mui/material";

/**
 * 外部链接 Chip
 * @param {string} label - Chip 标签
 * @param {string} url - 如何url不存在或为空，Chip将不显示
 */
export default function ExternalChipLink({ label, url }) {
  return(
    <>
    {
      url && url.trim() !== "" && 
      <Chip label={label} onClick={()=>window.open(url)} />
    }
    </>
  )
}