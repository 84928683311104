import RSSelector from "ui/RSSelector";

export default function FullyBookedSelector({ state, onSelect }) {

  const options = [
    {key: '0', value: '空房'},
    {key: '1', value: '满租', color: 'error', variant: 'outlined'}
  ]

  return (
    <RSSelector options={options} selectedKey={state} onSelect={onSelect} />
  )
}