/**
 * 房源信息列表
 * 户型图，地图，视频，全景
 */

import RowStack from "ui/RowStack";
import { propertyStates } from "redux/propertySlice";
import { Divider, Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import ViewQuiltOutlinedIcon from '@mui/icons-material/ViewQuiltOutlined';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'
import Dialog from "ui/Dialog";
import { useRef } from "react";
import Link from "ui/Link";
import GoogleMapButton from "./media/GoogleMapButton";
import Video from "./media/Video";
import Floor from "./media/Floor";
import Size from "./media/Size";
import Bedroom from "./media/Bedroom";
import Bathroom from "./media/Bathroom";
import Available from "./media/Available";
import PID from "./media/PID";

export default function PropertyMedias() {

  const { property } = useSelector(propertyStates)

  return (
    <Grid container sx={{marginTop: '0px !important', marginLeft: '-16px !important'}} >

        <Grid item xs={6}>
          <PID id={property.id} />
        </Grid>

      {
        // 空房
        property.avaliable_date && property.avaliable_date !== '0000-00-00' && 
        <Grid item xs={6}>
          <Available date={property.avaliable_date} />
        </Grid>
      }

      {
        // 卧室 
        property.bedroom !== '0' && 
        <Grid item xs={6}>
          <Bedroom number={property.bedroom} />
        </Grid>
      }

      {
        // 卫 
        property.bathroom !== '0' && 
        <Grid item xs={6}>
          <Bathroom number={property.bathroom} />
        </Grid>
      }

      {
        // 楼层 
        property.floor !== '0' && 
        <Grid item xs={6}>
          <Floor number={property.floor} />
        </Grid>
      }

      {
        // 面积 
        Number(property.sq) > 0 && 
        <Grid item xs={6}>
          <Size number={property.sq} />
        </Grid>
      }



      { // 地图
        property.lat !== '' && property.lng !== '' &&
        <Grid item xs={6}><GoogleMapButton /></Grid>
      }
      { // 户型图
        property.floorplans.length > 0 && 
        <Grid item xs={6}><Floorplan /></Grid>
      }
      
      {property.videos.map((video, index) => <Grid item xs={6} key={index}><Video video={video} /></Grid>)}
      {property.medias.map((media, index) => <Grid item xs={6} key={index}><Media media={media} /></Grid>)}
      <Grid item xs={12}><Divider /></Grid>
    </Grid>
  )
}

/**
 * 户型图
 */
function Floorplan() {
  const ref = useRef()
  const { property } = useSelector(propertyStates)
  if(property.floorplans.length === 0) return null

  
  const openDialog = () => {
    ref.current.open()
  }
  
  return (
    <Grid item xs={6}>
      <RowStack>
        <ViewQuiltOutlinedIcon />
        <Link onClick={openDialog}>
          <Typography>户型</Typography>
        </Link>
        <Dialog
          title="户型图"
          ref={ref}
          noContentPadding
        >
          {property.floorplans.map( img => 
            <img 
              width='100%'
              src={img.url} 
              key={img.id} 
              alt={property.name} 
            />
          )}
        </Dialog>
      </RowStack>
    </Grid>
  )
}

/**
 * iFrame 外部媒体
 */
function Media({ media }) {
  const ref = useRef()

  const openDialog = () => {
    ref.current.open()
  }

  const content = media.content 
    .replace(/<iframe(.*?)width="[^"]*"(.*?)/, '<iframe$1width="100%"$2')
    .replace(/<iframe(.*?)height="[^"]*"(.*?)/, '<iframe$1height="400"$2')
    .replace(/<iframe(?!.*?width=")(.*?)>/, '<iframe width="100%"$1>')
    .replace(/<iframe(?!.*?height=")(.*?)>/, '<iframe height="400"$1>');

  return (
    <RowStack>
      <PlayCircleOutlineOutlinedIcon />
      <Link onClick={openDialog}>
        <Typography>{media.title}</Typography>
      </Link>
      <Dialog
        title={media.title}
        ref={ref}
        noContentPadding
        fullWidth
      >
        <div
          style={{ minHeight: '400px', width: '100%'}} 
          dangerouslySetInnerHTML={{ __html:  content }} 
        />
      </Dialog>
    </RowStack>
  )
}