import { call } from "api/core";

/* 房源编辑 - 视频 */

/**
 * 视频列表
 * @param {*} propertyID 
 * @returns 
 */
export const getMediaList = async (propertyID) => {
  return await call('admin/property/media/getList/'+propertyID)
}

/**
 * 添加/更新 视频
 * @param {*} data 
 */
export const updateMedia = async (data) => {
  await call('admin/property/media/save', data)
}

/**
 * 删除视频
 * @param {*} mediaID 
 */
export const deleteMedia = async (mediaID) => {
  await call('admin/property/media/delete/'+mediaID)
}

/**
 * 上传视频
 * @param {*} data 
 */
export const uploadVideo = async (data) => {
  await call('admin/property/video/upload', data, 'upload')
}

/**
 * 更新视频
 * @param {*} data 
 */
export const updateVideo = async (data) => {
  await call('admin/property/video/update', data)
}