/* 卫浴 */

import { Typography } from "@mui/material";
import BathroomOutlinedIcon from '@mui/icons-material/BathroomOutlined';
import RowStack from "ui/RowStack";

export default function Bathroom({ number }){
  return (
    <RowStack>
      <BathroomOutlinedIcon />
      <Typography>{number} 卫</Typography>
    </RowStack>
  )
}