import { RoomType } from "configs/roomtype";
import RSSelector from "ui/RSSelector";

/**
 * 通用房型类别选择器
 * @param { string } type - '0', '1', '9'
 * @param { function } onSelect - return selected {key, value}
 * @returns 
 */
export default function RoomTypeSelector({ type, onSelect }) {

  return <RSSelector selectedKey={type} options={RoomType} onSelect={onSelect} />
}