import { Chip, Pagination, Stack } from "@mui/material";
import { getProviderInvoices } from "api/admin/provider";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import RSDialog from "ui/RSDialog";
import InvoiceList from "pages/finance/commission/components/InvoiceList";

/**
 * 某房源的佣金纪录
 * @param { string } providerID
 * @param { string } providerName
 * @returns 
 */
export default function ProviderCommission({ providerID, providerName = '' }) {

  const ref = useRef()

  const openDialog = () => {
    ref.current.open()
  }

  return (
    <>
    <Chip label={providerName} onClick={openDialog} />
    <ProviderCommissionDialog providerID={providerID} providerName={providerName} ref={ref} />
    </>
  )
}


export const ProviderCommissionDialog = forwardRef((props, ref) => {

  const { providerID, providerName } = props
  const [open, setOpen] = useState(false)
  const [invoices, setInvoices] = useState()
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  const openDialog = () => {
    setOpen(true)
    fetchInvoices(1)
  }

  const closeDialog = () => {
    setOpen(false)
    setInvoices(null)
    setPage(1)
    setPages(1)
  }

  useImperativeHandle(ref, ()=> ({
    open() {
      openDialog()
    }
  }))
  
  const fetchInvoices = async (page) => {
    const data = await getProviderInvoices(providerID, page)
    if(data) {
      const updatedInvoices = data.invoices.map(invoice => {invoice.provider = providerName; return invoice})
      setInvoices(updatedInvoices)
      setPages(data.pages)
    }
  }

  const handleChangePage = (e, v) => {
    setInvoices(null)
    setPage(v)
    fetchInvoices(v)
  }

  return (
    <RSDialog
      title={providerName}
      open={open}
      onClose={closeDialog}
      fullWidth
      maxWidth="lg"
    >
      <Stack>
        <InvoiceList invoices={invoices} setInvoices={setInvoices} />
        {pages > 1 && <Pagination count={pages} page={page} onChange={handleChangePage} /> }
      </Stack>
    </RSDialog>
  )
})