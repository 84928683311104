import { Chip } from "@mui/material";
import { getCashbacks, 
payCashback, 
updateCashback} from "api/finance";
import Booking from "components/booking/Booking";
import InvoiceChip from "components/provider/commission/InvoiceChip";
import { SimpleCurrencySelector } from "components/selector/CurrencySelector";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";
import RSTable from "ui/RSTable";
import RSTextEditor from "ui/RSTextEditor";
import RSYearSelector from "ui/RSYearSelector";
import { startLoading, stopLoading } from "utils/layout";

/**
 * 客户返现列表
 * @returns 
 */
export default function Cashback() {

  const [cashbacks, setCashbacks] = useState([])
  const [year, setYear] = useState()

  const columns = [
    {
      title: '#',
      render: (cashback, index) => {return index+1}
    },
    {
      title: '订单 ID',
      render: (cashback) => <Booking bookingID={cashback.booking_id} />
    },
    {
      title: '客户',
      dataIndex: 'customer_name'
    },
    {
      title: '佣金结算',
      render: (cashback) => <InvoiceChip invoiceID={cashback.commission_invoice_id} paid={cashback.paid} />
    },
    {
      title: '货币',
      render: (cashback) => <SimpleCurrencySelector currencyID={cashback.currency} onSelect={(option)=>updateCashback({id: cashback.id, currency: option.key})} />
    },
    {
      title: '金额',
      render: (cashback) => <RSTextEditor item={cashback} entity="amount" callback={updateCashback} />
    },
    {
      title: '备注',
      render: (cashback) => <RSTextEditor item={cashback} entity="note" callback={updateCashback} />
    },
    {
      title: '账户',
      render: (cashback) => <RSTextEditor item={cashback} entity="account" callback={updateCashback} />
    },
    {
      title: '',
      render: (cashback) => <Chip label="已支付" onClick={()=>handlePaid(cashback.id)} />
    }
  ]

  const fetchCashbacks = async () => {
    setCashbacks()
    startLoading()
    const data = await getCashbacks(year)
    if(data) setCashbacks(data)
    stopLoading()
  }

  useEffect(() => {
    const y = year?year:dayjs().format('YYYY')
    setYear(Number(y))
  }, [])

  useEffect(() => {
    if(year) fetchCashbacks()
  }, [year])

  const handlePaid = (cashbackID) => {
    if(window.confirm("确定已支付")) {
      setCashbacks(cashbacks.filter(item => item.id !== cashbackID)) 
      payCashback(cashbackID)
    }
  }

  return (
    <RSCard title="客户返现" action={year && <RSYearSelector year={year} onSelect={setYear} />}>
      <RSTable data={cashbacks} columns={columns} />
    </RSCard>
  )
}