import { Chip } from "@mui/material"
import { updateBooking } from "api/admin/booking"
import { useState } from "react"
import RSDialog from "ui/RSDialog"
import RSTextEditor from "ui/RSTextEditor"

export default function CustomerID({ booking, closeBookingDialog }) {

  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
    
  }

  const handleUpdate = (data) => {
    updateBooking(data)
    closeBookingDialog()
    closeDialog()
  }

  return (
    <>
    <Chip label="切换客户" onClick={openDialog} />
    <RSDialog
      title="切换客户"
      open={open}
      onClose={closeDialog}
    >
      <RSTextEditor item={booking} entity="customer_id" callback={handleUpdate} prefix="客户ID：" />
    </RSDialog>
    </>
  )
}