import { addPropertyComment, delPropertyComment, getPropertyComments } from "api/property";
import StaffComments from "./StaffComments";

export default function PropertyComments({ propertyID }) {

  return (
    <StaffComments 
      title="房源备注"
      getComments={()=>getPropertyComments(propertyID)}
      addComment={(message)=>addPropertyComment({tid: propertyID, message: message})}
      delComment={(commentID)=>delPropertyComment(commentID)}
    />
  )
}