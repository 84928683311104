import { useRef } from "react";
import ISChipDialog from "ui/ISChipDialog";
import PaymentForm from "./PaymentForm";

/**
 * 英镑代付 新建按钮
 * @param {obj} booking 
 * @param {func} callback
 * @returns 
 */
export default function PaymentAdd({ 
  booking, 
  callback 
}) {

  const ref = useRef()

  const payment = {
    customer_id: booking.customer_id,
    booking_id: booking.id,
    currency: 'GBP',
    status: 0,
    amount: 0,
    note: '',
  }

  const handleCallback = () => {
    ref.current.close()
    if(callback) callback()
  }

  return (
    <ISChipDialog 
      ref={ref}
      chipLabel="英镑"
      dialogTitle="英镑代付"
      children={<PaymentForm payment={payment} callback={handleCallback} />}
    />
  )
}