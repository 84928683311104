import { Add } from "@mui/icons-material";
import { Box, Card, CardContent, CardHeader, Chip, Divider, IconButton, Stack, Typography } from "@mui/material";
import { addPrice, addRoomType, delPrice, delRoomType, updatePrice, updateRoomType } from "api/room";
import FullyBookedSelector from "components/selector/FullyBookedSelector";
import { RoomTypeDescription } from "configs/roomtype";
import ISCardActions from "ui/ISCardActions";
import ISChipDialog from "ui/ISChipDialog";
import { useSelector } from "react-redux";
import { fetchProperty, propertyStates } from "redux/propertySlice";
import RSTextEditor from "ui/RSTextEditor";
import RowStack from "ui/RowStack";
import Spacer from "ui/Spacer";
import { startLoading } from "utils/layout";
import RoomTypeImgEditor from "./RoomTypeImgEditor";
import RoomTypeSelector from "components/selector/RoomTypeSelector";
import { getValueByKey } from "utils/common";
import { countryIDtoCurrency, countryIDtoLeaseUnit } from "configs/country";

/* 通用 房型价格编辑器 */
export default function RoomTypeAndPriceEditor() {

  const { property } = useSelector(propertyStates)

  
  // 新建房型
  const handleAddRoomType = async () => {
    startLoading()
    await addRoomType(property.id)
    fetchProperty(property.id)
  }

  // 房型信息更新
  const handleUpdateRoomType = async (updatedRoomType) => {
    startLoading()
    await updateRoomType(updatedRoomType)
    fetchProperty(property.id)
  };

  // 删除房型
  const handleDelRoomType = async (roomtypeID) => {
    if(window.confirm("确定删除房型和价格?")) {
      startLoading()
      await delRoomType(roomtypeID)
      fetchProperty(property.id)
    }
   
  }

  // 添加价格
  const handleAddPrice = async (roomtypeID) => {
    startLoading()
    await addPrice(roomtypeID)
    fetchProperty(property.id)
  }

  // 编辑价格
  const handleUpdatePrice = async (updatedPrice) => {
    startLoading()
    await updatePrice(updatedPrice)
    fetchProperty(property.id)
  }

  // 删除价格
  const handleDelPrice = async (priceID) => {
    if(window.confirm("确定删除价格?")) {

      startLoading()
      await delPrice(priceID)
      fetchProperty(property.id)
    }
  }

  return (
    <ISChipDialog
      chipLabel="房型价格"
      dialogTitle="房型价格编辑"
      maxWidth="sm"
      fullWidth
      iconAction={
        <IconButton onClick={handleAddRoomType}>
          <Add />
        </IconButton>
      }
    >
      <Stack>
      {
        property && property.roomtypes && property.roomtypes.map(roomtype => 
          <Card key={roomtype.id}>
            <CardHeader 
              sx={{background: "#eee"}} 
              title={
                <ISCardActions>
                  <RoomTypeSelector type={roomtype.type} onSelect={(option) => handleUpdateRoomType({id: roomtype.id, type: option.key})} />
                  <Typography>{getValueByKey(RoomTypeDescription, roomtype.type)}</Typography>
                  <Box flexGrow={1} />
                  <Chip label="删除房型" onClick={()=>handleDelRoomType(roomtype.id)} />
                </ISCardActions>
              } 
            />
            <Divider />
            <CardContent>
              <Stack>

                <Stack direction="row">
                  <Stack>
                    <RSTextEditor 
                      prefix="房型名称: "
                      item={roomtype} 
                      entity="name"
                      callback={handleUpdateRoomType}
                    />
                    <RSTextEditor 
                      prefix="房型备注: "
                      item={roomtype} 
                      entity="description"
                      callback={handleUpdateRoomType}
                    />
                  </Stack>
                  <Spacer />
                  <RoomTypeImgEditor imgs={property.imgs} roomtype={roomtype} callback={handleUpdateRoomType} />
                  <Chip label="新建价格" onClick={()=>handleAddPrice(roomtype.id)} />
                </Stack>

                {
                  roomtype.prices.map(price => 
                    <PriceEditor key={price.id} price={price} updatePrice={handleUpdatePrice} delPrice={handleDelPrice} />
                  )
                }
              </Stack>

            </CardContent>
          </Card>
        )
      }

      </Stack>
    </ISChipDialog>
  )
}


/**
 * 价格编辑器
 * @param {*} param0 
 * @returns 
 */
function PriceEditor({ price, updatePrice, delPrice }) {

  const { property } = useSelector(propertyStates)

  const handleUpdatePrice = (data) => {
    updatePrice({
      roomtype_id: price.roomtype_id,
      ...data
    })
  }

  const handleUpdateState = (option) => {
    updatePrice({
      roomtype_id: price.roomtype_id,
      id: price.id,
      state: option.key
    })
  }

  const handleDelPrice = () => {
    delPrice(price.id)
  }

  return (
    <Stack>

      <Divider />

      <Stack direction="row" alignItems="center">
        <RSTextEditor 
          prefix={countryIDtoCurrency(property.country_id)}
          suffix={"/"+countryIDtoLeaseUnit(property.country_id)}
          item={price}
          entity="price"
          callback={handleUpdatePrice}
        />
        <Box flexGrow={1} />

        <RowStack>
          <Chip label="删除价格" onClick={handleDelPrice} />
          <FullyBookedSelector state={price.state} onSelect={handleUpdateState} />
        </RowStack>
      </Stack>

      <RSTextEditor
        prefix="开始时间: "
        item={price}
        entity="start_date"
        callback={handleUpdatePrice}
      />

      <RSTextEditor
        prefix={`租期: `}
        suffix={countryIDtoLeaseUnit(property.country_id)}
        item={price}
        entity="weeks"
        callback={handleUpdatePrice}
      />

      <RSTextEditor 
        prefix="价格备注："
        item={price}
        entity="note"
        callback={handleUpdatePrice}
      />
    </Stack>
  )
}