import { useRef } from "react";
import ISChipDialog from "ui/ISChipDialog";
import { useSelector } from "react-redux";
import { fetchProperty, propertyStates } from "redux/propertySlice";
import { Stack, Typography } from "@mui/material";
import RowStack from "ui/RowStack";
import Spacer from "ui/Spacer";
import ISForm from "ui/ISForm";
import { deletePropertyPromotion, updatePropertyPromotion } from "api/property";
import { Add } from "@mui/icons-material";
import { stopLoading } from "utils/layout";

/* 优惠编辑 */
export default function PromotionEditor() {
  const { property } = useSelector(propertyStates)

  const handleUpdate = async () => {
    await fetchProperty(property.id)
    stopLoading()
  }
 
  return (
    <ISChipDialog
      chipLabel="优惠"
      dialogTitle="优惠活动"
      iconAction={<AddNewButton property={property} onUpdate={handleUpdate} />}
      fullWidth
    > 
      <Stack>
        {property.promotions.map((promotion, index) =>
          <RowStack key={index}>
            <Stack spacing={0.5}>
              <Typography>
                <b><span style={{color: 'red'}}>{promotion.title}: </span></b>
                {promotion.content}
              </Typography>
              <Typography>有效期：{promotion.expiry_date}</Typography>
            </Stack>
            <Spacer />
            <EditorDialog promotion={promotion} onUpdate={handleUpdate} />
          </RowStack>
        )}
      </Stack>
    </ISChipDialog>
  )
}

const fields = [
  {
    contents: [
      {
        ele: 'input',
        name: 'title',
        label: '标签'
      },
      {
        ele: 'date',
        name: 'expiry_date',
        label: '有效期'
      }
    ]

  },
  {
    ele: 'input',
    name: 'content',
    label: '优惠'
  }
]

function AddNewButton({ property, onUpdate }) {

  const promotion = {
    property_id: property.id,
    title: '',
    content: '',
    expiry_date: null
  }

  const ref= useRef()


  const handleSave = async (data) => {
    await updatePropertyPromotion(data) 
    ref.current.close()
    onUpdate()
  }

  return (
    <ISChipDialog
      chipLabel="编辑"
      chipType='icon'
      icon={<Add />}
      ref={ref}
    >
      <ISForm data={promotion} form={fields} onSave={handleSave} />
    </ISChipDialog>
  )
}


function EditorDialog({ promotion, onUpdate }) {

  const ref = useRef()

  const handleSave = async (data) => {
    await updatePropertyPromotion(data) 
    ref.current.close()
    onUpdate()
  }

  const handleDel = async () => {
    await deletePropertyPromotion(promotion.id)
    ref.current.close()
    onUpdate()
  }

  return (
    <ISChipDialog
      ref={ref}
      chipLabel="编辑"
      fullWidth
    >
      <ISForm data={promotion} form={fields} onSave={handleSave} onDelete={handleDel} />
    </ISChipDialog>
  )
}