import { Stack, Typography } from "@mui/material";
import { formatName } from "utils/booking";

/**
 * 订单租客名
 * @param { object } booking 
 * @returns 
 */
export default function BookingCustomer({ booking }) {
  return (
    <Stack spacing={0}>
      <Typography>{booking.customer_id}</Typography>
      <Typography>{booking.customer_name}</Typography>
      <Typography>{formatName(booking.customer_name_en)}</Typography>
    </Stack>
  )
}