import { Box, Chip, Stack, Typography } from "@mui/material";
import { Outlet, useNavigate } from "react-router-dom";
import { countryIDToName, countryIDtoCode, countryIDtoFlag } from "configs/country";


export default function PropertyLayout({
  countryID,
  noCity
}) {

  const country = `/${countryIDtoCode(countryID)}/`
  const nav = useNavigate()

  return (
    <Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        {countryIDtoFlag(countryID)}
        <Typography>{countryIDToName(countryID)}</Typography>
        <Box flexGrow={1} />
        {!noCity && <Chip label="城市" onClick={()=>nav(country+'city')} />}
        <Chip label="房源" onClick={()=>nav(country+'property')} />
        <Chip label="供应商" onClick={()=>nav(country+'provider')} />
      </Stack>

      <Outlet />
    </Stack>
  )
}