import { useEffect, useState } from "react";
import { getBookingPayments } from "api/payment";
import RSCard from "ui/RSCard";
import PaymentAdd from "./PaymentAdd";
import PaymentList from "./PaymentList";

/**
 * 英镑代付 Card
 * @param {*} booking 
 * @returns 
 */
export default function PaymentCard({ booking, reloadTransactions }) {

  const [payments, setPayments] = useState(null)
  const fetchPayments = async () => {
    setPayments(null)
    const data = await getBookingPayments(booking.id)
    if(data) setPayments(data)
    else setPayments([])
  }

  useEffect(() => {
    fetchPayments()
  }, [])


  return (
    <RSCard
      title="代付"
      action={<PaymentAdd booking={booking} callback={fetchPayments} />}
    >
     <PaymentList payments={payments} reloadPayments={fetchPayments} reloadTransactions={reloadTransactions} />
    </RSCard>

  )
}