import { Stack } from "@mui/material";

export default function ISCardActions({ children }) {

  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      {children}
    </Stack>
  )

}