import RSTable from "ui/RSTable";
import bookingTableColumns from "./table/bookingTableColumns";

/**
 * 订单列表
 * @param {*} bookings
 * @param {*} setBookings
 * @returns 
 */
export default function BookingList({ bookings, setBookings }) {
  const columns = bookingTableColumns(bookings, setBookings)
  return <RSTable columns={columns} data={bookings} />
}