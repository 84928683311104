import { call } from "./core";

/* 财务 */

/**
 * 未结算订单
 * @returns 
 */
export const getUninvoicedBookings = async (year) => {
  return await call('admin/finance/commission/getUninvoicedBookings/'+year)
}

/**
 * 未支付佣金统计
 * @param { string } currency optional
 */
export const getUnpaidCommissionSummary = async (year, state) => {
  return await call('admin/finance/commission/getUnpaidCommissionSummary', {year: year, state: state})
}

/**
 * 未支付佣金
 * @param { string } currency optional
 */
export const getUnpaidCommission = async (year, currency, state) => {
  return await call('admin/finance/commission/getUnpaidCommission', {year: year, currency: currency, state: state})
}

/**
 * 新建佣金
 * @param { string } providerID 
 * @returns Provider Object
 */
export const addANewCommission = async (providerID) => {
  return await call('admin/provider/commission/addANewCommission/'+providerID)
}

/**
 * 更新佣金记录
 * @param { object } data
 */
export const updateCommission = async (data) => {
  await call('admin/finance/commission/updateCommission', data)
}

/**
 * 佣金记录
 * @param { string } commissionID 
 * @returns 
 */
export const getCommission = async (commissionID) => {
  return await call('admin/finance/commission/getCommission/'+commissionID)
}

/**
 * 佣金相关订单
 * @param { string } commissionID 
 * @returns 
 */
export const getCommissionBookings = async (commissionID) => {
  return await call('admin/finance/commission/getCommissionBookings/'+commissionID)
}

/**
 * 删除佣金记录
 * @param { string } commissionID 
 */
export const deleteCommission = async (commissionID) => {
  await call('admin/finance/commission/deleteCommission/'+commissionID)
}

/**
 * 返现列表
 * @param {*} year 
 * @returns 
 */
export const getCashbacks = async (year) => {
  return await call('admin/finance/cashback/getCashbacks/'+year)
}

/**
 * 更新返现信息
 * @param {*} data
 */

export const updateCashback = async (data) => {
  await call('admin/finance/cashback/updateCashback', data)
}

/**
 * 支付返现
 * @param {*} cashbackID
 */
export const payCashback = async (cashbakcID) => {
  await call('admin/finance/cashback/payCashback/'+cashbakcID)
}

/**
 * 某月的收支明细
 * @param {*} $year 
 * @param {*} $month 
 * @returns 
 */
export const getTransactions = async (account, year, month) => {
  return await call('admin/finance/transaction/getTransactions', {account: account, year: year, month: month})
}

/**
 * 更新收支明细
 * @param {*} data 
 */
export const updateTransaction = async (data) => {
  await call ('admin/finance/transaction/updateTransaction', data)
}