import RSSelector from "ui/RSSelector";

/**
 * 隐藏选择器
 * @param { string } typeID - '0', '1'
 * @param { function } onSelect - return selected {key, value}
 * @returns 
 */
export default function HideSelector({ hide, onSelect }) {

  const options = [
    {key: "0", value: "仅显示"},
    {key: "1", value: "仅隐藏"}
  ]

  return <RSSelector selectedKey={hide} options={options} onSelect={onSelect} />
}