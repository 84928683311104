import RSSelector from "ui/RSSelector";

/**
 * 房源类别选择器
 * @param { string } typeID - '0', '1', '9'
 * @param { function } onSelect - return selected {key, value}
 * @returns 
 */
export default function PropertyTypeSelector({ type, onSelect }) {

  const options = [
    {key: "9", value: "全部类型"},
    {key: "0", value: "学生公寓"},
    {key: "1", value: "社会公寓"}
  ]

  return <RSSelector selectedKey={type} options={options} onSelect={onSelect} />
}