import { Link as MuiLink } from '@mui/material'
import { useNavigate } from 'react-router-dom'

/**
 * 代替<a>
 * @param {*} href
 * @param {*} nav - 是否用 react router
 * @returns 
 */
export default function Link({
  href,
  children,
  onClick
}) {

  const navigate = useNavigate()

  const handleClick = () => {
    if(href) {
      const pattern = /https?:\/\//;
      if(pattern.test(href)) window.open(href, '_blank')
      else navigate(href)
    }
    if(onClick) onClick()
  }

  return (
    <MuiLink underline="hover" onClick={handleClick} sx={{textDecoration: 'none', color: 'inherit', cursor: 'pointer'}}>
      {children}
    </MuiLink>
  )
}