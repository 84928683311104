/* 卧室 */

import { Typography } from "@mui/material";
import BedroomParentOutlinedIcon from '@mui/icons-material/BedroomParentOutlined';
import RowStack from "ui/RowStack";

export default function Bedroom({ number }){
  return (
    <RowStack>
      <BedroomParentOutlinedIcon />
      <Typography>{number} 卧</Typography>
    </RowStack>
  )
}