import { Chip, LinearProgress, Stack, TextField } from "@mui/material";
import { searchProvider } from "api/admin/provider";
import { useState } from "react";
import RSDialog from "ui/RSDialog";

export default function ProviderSearch({ label="搜索供应商", callback }) {

  const [keywords, setKeywords] = useState('')
  const [providers, setProviders] = useState([])
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
  }
  
  const closeDialog = () => {
    setOpen(false)
  }

  const handleChange = (e) => {
    setKeywords(e.target.value)
  }

  const handlePress = (e) => {
    if(e.key === 'Enter' && keywords) search()
  }

  const search = async () => {
    setLoading(true)
    const data = await searchProvider(keywords)
    if(data) setProviders(data)
    setLoading(false)
  }

  const handleCallback = (provider) => {
    closeDialog()
    if(callback) callback(provider)
  }

  return (
    <>
    <Chip label={label} onClick={openDialog} />
    <RSDialog
      title="供应商搜索" 
      open={open}
      onClose={closeDialog}
      maxWidth="xs"
      fullWidth
    >
       <Stack>
        <TextField 
          value={keywords} 
          onChange={handleChange} 
          onKeyDown={handlePress}
          placeholder="供应商名称" 
          disabled={loading}
        />
        <Stack>
          {loading && <LinearProgress />}
          {providers.map(provider => 
          <Stack direction="row" key={provider.id} >
            <Chip label={provider.name} onClick={()=>handleCallback(provider)} />
          </Stack>
          )}
        </Stack>
      </Stack>
    </RSDialog>
    </>
  )
}