/* 楼层 */

import { Typography } from "@mui/material";
import StraightenRoundedIcon from '@mui/icons-material/StraightenRounded';
import RowStack from "ui/RowStack";

export default function Size({ number }){
  return (
    <RowStack>
      <StraightenRoundedIcon />
      <Typography>约 {number}m<sup>2</sup></Typography>
    </RowStack>
  )
}