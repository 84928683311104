/**
 * 房源价格, 预定, 咨询 Box
 * 用于无房型分类的房源
 */

import RowStack from "ui/RowStack";
import Spacer from "ui/Spacer";
import { propertyStates } from "redux/propertySlice";
import { bookingPrice } from "libs/booking";
import { Divider, Stack, Typography } from "@mui/material";
import { useSelector } from "react-redux";

export default function PropertyRentBox() {

  const { property } = useSelector(propertyStates)

  if( property.roomtypes.length > 0 ) return null

  return (
    <Stack>
      <RowStack>
        <Stack spacing={0.5}>
          <Typography variant="h5">{bookingPrice(property.rent, property.country_id)}</Typography>
        </Stack>

        <Spacer />

        <RowStack>

        </RowStack>
      </RowStack>
      <Divider />
    </Stack>
  )
}