import { call } from "api/core";

/* 城市 */

/**
 * 城市名选项
 * @param {*} countryID 
 * @returns 
 */
export const getCityOptions = async (countryID) => {
  return await call(`web/city/getOptions/${countryID}`)
}

