import { Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material"

/*
 const columns = [
    {
      title: 'delivery_country_code',
      render: () => ("GB"),
    },
    {
      title: 'delivery_post_code',
      dataIndex: 'postcode',
    },
  ]
*/

/**
 * 
 * @param { array } data 表单数据
 * @param { array } columns 表单列 示例看文件
 * @returns 
 */
export default function RSTable({data, columns, noHead}) {

  return (
    <>
    {!data && <Skeleton />}
    {data &&
    <Table>
      { !noHead &&
      <TableHead>
        <TableRow>
          {columns.map(col=> <TableCell key={col.title} width={col.width}>{col.title}</TableCell>)}
        </TableRow>
      </TableHead>
      }
      {data && 
      <TableBody>
        {data.map((d, index) => 
        <TableRow key={d.id+'-'+index}>
          {columns.map(col=> 
            <TableCell 
              key={col.title+'-'+index}
            >
              {d[col.dataIndex]}
              {col.render && col.render(d, index)}
            </TableCell>)}
        </TableRow>
        )}
      </TableBody>
      }
    </Table>
    }
    </>
  )
}