/**
 * 房源搜索 
 * 用于框架右上角搜索功能
 */

import { Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import RSTable from "ui/RSTable";
import PropertyClickableID from "./PropertyClickableID";
import { searchProperty } from "api/property";

export default function PropertySearch() {

  const [keywords, setKeywords] = useState('')
  // const [page, setPage] = useState(1)
  const [properties, setProperties] = useState([])

  const handleChange = (e) => {
    setKeywords(e.target.value)
  }

  const handlePress = (e) => {
    if(e.key === 'Enter' && keywords) search()
  }

  const search = async () => {
    setProperties(null)
    const data = await searchProperty(keywords, 1)
    if(data) setProperties(data)
  }

  const columns = [
    {
      title: 'ID',
      render: (property) => <PropertyClickableID propertyID={property.id} />
    },
    {
      title: '房源',
      render: (property) => 
        <Stack spacing={0}>
          <Typography>{property.city}</Typography>
          <Typography>{property.name}</Typography>
        </Stack>
    },
  ]

  return (
    <Stack>
      <TextField 
        fullWidth
        placeholder="房源ID, 名称"
        onChange={handleChange} 
        onKeyDown={handlePress}
      />
      <RSTable columns={columns} data={properties} />
    </Stack>
  )
}