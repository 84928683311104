import WeComSignInButton from "./components/WeComSignInButton";
import AuthCard from "./components/AuthCard";

export default function SignIn() {

  return (
    <AuthCard>
      <WeComSignInButton />
    </AuthCard>
  )
}