import { Alert, Card, Stack, Typography } from "@mui/material";
import { countryIDtoFlag } from "configs/country";
import RSTable from "ui/RSTable";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ISChipDialog from "ui/ISChipDialog";
import { useState } from "react";
import BookingList from "components/booking/BookingList";
import { getStaffBookings } from "api/admin/booking";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend
);


export default function PerformanceCardContent({ data }) {

  const filter = {year: data.year, staff_id: data.staff.id}

  const columns = [
    {
      title: '地区',
      render: item => countryIDtoFlag(item.country)
    },
    {
      title: '跟进',
      render: item => <BookingFilter num={item.pending} filter={{...filter, country_id: item.country, state: 0}} />
    },
    {
      title: '完成',
      render: item => <BookingFilter num={item.complete} filter={{...filter, country_id: item.country, state: 1}} />
    },
    {
      title: '渠道',
      render: item => <BookingFilter num={item.channel} filter={{...filter, country_id: item.country, state: 1, channel: 1}} />
    },
    {
      title: '流量',
      render: item => <BookingFilter num={item.traffic} filter={{...filter, country_id: item.country, state: 1, channel: 0}} />
    },
  ]

  return (
    <Stack>
      <Alert>企微收款 RMB {data.service_charge}</Alert>
      <Bar data={data.chart_data} />
      <Card>
        <RSTable data={data.bookings} columns={columns} />
      </Card>
    </Stack>
  )
}

function BookingFilter({ num, filter }) {

  const [bookings, setBookings] = useState()

  const fetchBookings = async () => {
    if(!bookings) {
      const data = await getStaffBookings(filter)
      if(data) setBookings(data)
    }
  }

  return (
    <>
    { Number(num) > 0 ?
      <ISChipDialog
        chipType="text"
        chipLabel={num}
        dialogTitle="订单列表"
        onOpen={fetchBookings}
        maxWidth="lg"
        fullWidth
      >
        <BookingList bookings={bookings} /> 
      </ISChipDialog>
      :
      <Typography>{num}</Typography>
    }
    </>
  )
}