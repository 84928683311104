import { HKRoomType, HKRoomEnsuite, HKRoomKitchen } from "configs/roomtype"
import { getValueByKey } from "utils/common"

/**
 * 香港房型名称
 * @param {*} roomtype
 */
export function HKRoomTypeName(roomtype) {
  return getValueByKey(HKRoomType, roomtype)
}


/**
 * 香港房型 卫浴
 * @param {*} roomtype 
 * @param {*} bathtype 
 * @returns 
 */
export function HKBathroom(roomtype, bathtype) {
  const myRoomType = Number(roomtype)
  const myBathType = Number(bathtype)
  if(myRoomType >= 2) return HKRoomEnsuite[1].value
  else {
    if(myBathType) return HKRoomEnsuite[1].value
    else return HKRoomEnsuite[0].value
  }
}

/**
 * 香港房型 厨房
 * @param {*} roomtype 
 * @returns 
 */
export function HKKitchen(roomtype) {
  const myRoomType = Number(roomtype)
  if(myRoomType >= 2) return HKRoomKitchen[1].value
  else return HKRoomKitchen[0].value
}