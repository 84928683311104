import { Stack, Typography } from "@mui/material";
import { countryIDtoFlag } from "configs/country";

/**
 * 订单房源
 * @param { object } booking 
 * @returns 
 */
export default function BookingProperty({ booking }) {
  return (
    <Stack spacing={0}>
      <Typography> {countryIDtoFlag(booking.country_id)} {booking.provider}</Typography>
      <Typography variant="caption">{booking.accommodation}</Typography>
      <Typography variant="caption">{booking.room_type}</Typography>
    </Stack>
  )
}