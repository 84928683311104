import { Box } from "@mui/material";

/**
 * 图片
 * @param {*} ratio - 宽长比
 * @param {*} src
 * @param {*} alt
 * @returns 
 */
export default function Image(props) {
  
  const {ratio, src, alt, onClick} = props

  return (
    <Box sx={{ position: "relative", cursor: 'pointer' }} onClick={onClick}>
      <Box sx={{  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  "& > *": { height: "100%", width: "100%" }
              }}
      >
        <img
          src={src} 
          alt={alt} 
          width='100%'
        />
      </Box>
      <Box style={{ paddingBottom: (ratio) * 100 + "%" }} />
    </Box>
  )
}