import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { useRef } from "react";
import ISChipDialog from "ui/ISChipDialog";
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import PaymentState from "./PaymentState";
import PaymentForm from "./PaymentForm";
import PaymentButton from "./PaymentButton";
import RowStack from "ui/RowStack";

/**
 * 英镑代付 列表
 * @param {array} payments 
 * @param {func} reloadPayments
 * @returns 
 */
export default function PaymentList ({ payments, reloadPayments, reloadTransactions }) {

  return (
    <Stack>
    {!payments && <Skeleton />}
    
    {payments && payments.map((payment, index) => 
      <PaymentRow key={index} payment={payment} reloadPayments={reloadPayments} reloadTransactions={reloadTransactions} />
    )}
    </Stack>
  )
}

/**
 * 英镑代付 列表元素
 * @param {*} payment
 * @param {*} reloadPayments
 * @returns 
 */
function PaymentRow({ payment, reloadPayments, reloadTransactions }) {

  return (
    <Stack spacing={0.5}>
      <Stack key={payment.id} direction="row" alignItems="center">
        <Stack spacing={0}>
          <PaymentOutlinedIcon title='123' />
          <Typography fontSize={9}>{payment.id}</Typography>
        </Stack>
        
        <Stack spacing={0}>
          <PaymentText payment={payment} reloadPayments={reloadPayments} />
          
          <Typography variant="caption">{payment.note}</Typography>
        </Stack>
       
        <Box flexGrow={1} />

        <RowStack>
          <PaymentState state={payment.status} />
          <PaymentButton payment={payment} reloadPayments={reloadPayments} reloadTransactions={reloadTransactions} />
        </RowStack>
      </Stack>
     
      <Divider />
    </Stack>
  )
}

function PaymentText({ payment, reloadPayments }) {

  const ref = useRef()

  const handlereloadPayments = () => {
    ref.current.close()
    reloadPayments()
  }

  return (
    <ISChipDialog 
      ref={ref}
      chipType="text"
      chipLabel={"£" + payment.amount + '+£' + payment.fee}
      dialogTitle="英镑代付"
      children={<PaymentForm payment={payment} callback={handlereloadPayments} />}
    />
  )
}