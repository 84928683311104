import { Card, CardContent, CardHeader, Divider, Typography } from "@mui/material"

/**
 * 卡片
 * @param { string } title 标题
 * @param { component } action option 动作栏 
 * @param { component } children option 卡片内容
 * @returns 
 */
export default function RSCard({title, action, noContentPadding, children}) {

  return (
    <Card>
      <CardHeader 
        sx={{background: "#eee"}} 
        title={<Typography variant="body2" sx={{fontWeight: 'bold'}}>{title}</Typography>} 
        action={action}
      />
      <Divider />
      <CardContent sx={noContentPadding?{padding: 0}:{}}>
        {children}
      </CardContent>
    </Card>
  )
}