import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api'
import { getHKPropertyListForMap, getHKUniversities } from 'api/hk';
import { googleMapsApiKey } from 'configs/google';
import { useEffect, useRef, useState } from 'react';
import RSCard from 'ui/RSCard'

/**
 * 香港地图 展示大学和房源
 */
export default function HKMap() {

  const [universities, setUniversities] = useState()
  const [properties, setProperties] = useState()
  const containerStyle = {
    width: '100%',
    height: '750px'
  };

  // Hong Kong
  const center = {
    lat: 22.3526405,
    lng: 113.9742462
  };

  const fetchUniversity = async () => {
    const data = await getHKUniversities()
    if(data) setUniversities(data)
  }

  const fetchProperties = async () => {
    const data = await getHKPropertyListForMap()
    if(data) setProperties(data)
  }



  useEffect(() => {
    fetchUniversity()
    fetchProperties()
  }, [])

  return (
    <RSCard title="香港地图" noContentPadding>
      <LoadScript googleMapsApiKey={googleMapsApiKey}>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={11}
        >
          {
            universities && universities.map((university, index) => 
              <UniversityMarker 
                key={'university-' + index} 
                university={university}
              />
            )
          }

          {
            properties && properties.map((property, index) => 
              <PropertyMarker 
                key={'property-' + index} 
                property={property}
              />
            )
          }
        </GoogleMap>
      </LoadScript>
    </RSCard>
  )
}

// 学校 Marker
function UniversityMarker({ university }) {
  return (
    <Marker 
      title={university.name_cn} 
      position={{lat: Number(university.lat), lng: Number(university.lng)}} 
      icon={{
        url: "https://mt.googleapis.com/vt/icon/name=icons/onion/SHARED-mymaps-container_4x.png,icons/onion/1726-university_4x.png&highlight=e65100&scale=4",
        scaledSize: new window.google.maps.Size(20, 20), // 自定义图标的大小
        origin: new window.google.maps.Point(0, 0), // 图标的原点
        anchor: new window.google.maps.Point(10, 10) // 图标的锚点
      }}
    />
  )
}

// 房源 Marker
function PropertyMarker({ property }) {
  const ref = useRef()

  const openDialog = () => {
    ref.current.open()
  }

  return (
    <Marker 
      title={property.name} 
      position={{lat: Number(property.lat), lng: Number(property.lng)}} 
      icon={{
        url: "https://img.51room.com/images/map/property_marker.png",
        scaledSize: new window.google.maps.Size(20, 20), // 自定义图标的大小
        origin: new window.google.maps.Point(0, 0), // 图标的原点
        anchor: new window.google.maps.Point(10, 10) // 图标的锚点
      }}
      onClick={()=>openDialog(property.id)}
    >
      
    </Marker>
    
  )
}