import { Chip } from "@mui/material"
import { deleteProperty } from "api/property"
import { useSelector } from "react-redux"
import { closePropertyDialog, propertyStates } from "redux/propertySlice"
import { hasPermission, startLoading, stopLoading } from "utils/layout"

/**
 * 房源删除按钮
 * @returns 
 */
export default function PropertyDeleteButton() {

  const { property, reloadList } = useSelector(propertyStates)

  if(!hasPermission('developer') || property.mark_hide !== '1') return null

  const handleDelete = async () => {
    if(window.confirm("确定删除?")) {
      startLoading()
      await deleteProperty(property.id)
      closePropertyDialog()
      if(reloadList) reloadList()
      stopLoading()
    }
  }

  return <Chip label="删除" onClick={handleDelete} />
}