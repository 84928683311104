import RSSelector from "./RSSelector";

/**
 * 年份选择器
 * @param { number } year 默认年份
 * @param { function } onSelect 
 * @returns 
 */
export default function RSYearSelector({ year, onSelect }) {

  // 获取当前年份
  const currentYear = new Date().getFullYear();

  // 初始化一个空数组用于存储选项
  const options = [];

  // 从今年开始，往过去的三年生成选项
  for (let i = 0; i < 4; i++) {
      const y = currentYear - i;
      // 添加选项到数组
      options.push({ key: y, value: y });
  }

  const handleSelect = (option) => {
    onSelect(option.key)
  }

  return <RSSelector options={options} selectedKey={year} onSelect={handleSelect} />
}