import { Grid } from '@mui/material'
import AgentList from 'components/agent/AgentList'


/* 渠道账号管理 */
export default function Agents() {

  return(
    <Grid container>
      <Grid item xs={3}>
        <AgentList />
      </Grid>
    </Grid>
  )
}