import { call } from "api/core";

/* 房源 */

/**
 * 房源筛选列表
 * @param {*} filter 
 * @returns 
 */
export const filterProperty = async (filter) => {
  return await call ('web/property/filter', filter)
}

/**
 * 房源信息
 * @param {*} propertyID 
 * @returns 
 */
export const getProperty = async (propertyID) => {
  return await call('web/property/view/'+propertyID)
}