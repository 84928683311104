import { useEffect, useState } from "react";
import { getAgentUnpaidBookings } from "api/agent";
import RSCard from "ui/RSCard";
import AgentCommission from "./AgentCommission";
import AgentBookingsTable from "./AgentBookingsTable";


export default function AgentUnpaidBookings({ agentID }) {

  const [bookings, setBookings] = useState()

  const fetchBookings = async () => {
    const data = await getAgentUnpaidBookings(agentID)
    if(data) setBookings(data)
    else setBookings([])
  }

  useEffect(() => {
    fetchBookings()
  }, [])

 
  return (
    <RSCard title={'渠道待结算订单'} action={<AgentCommission agentID={agentID} bookings={bookings} setBookings={setBookings} />}>
      <AgentBookingsTable bookings={bookings} />
    </RSCard>
  )
}

