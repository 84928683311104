import { Stack } from "@mui/material";
import RSCard from "ui/RSCard";
import Simcard, { Simcards } from "./Simcard";
import { useRef } from "react";
import BeddingPack, { BeddingPacks } from "./BeddingPack";
import { AddCashback, CashbackList } from "./Cashback";

export default function GiftInfo({ booking }) {
  
  const ref = useRef()
  const beddingPackRef = useRef()
  const cashbackListRef = useRef()

  const handleReload = () => {
    ref.current.reload()
  }

  const handleReloadBeddingPacks = () => {
    beddingPackRef.current.reload()
  }

  const reloadCashbacks = () => {
    cashbackListRef.current.reload()
  }

  return (
    <RSCard 
      title="赠品"
      action={
        <Stack direction="row" spacing={1}>
          <Simcard 
            data={{
              booking_id: booking.id, 
              customer_id: booking.customer_id,
              r_name: booking.customer_name,
              r_address: booking.customer_address,
              r_tel: booking.customer_mobile
            }} 
            display="chip" 
            reload={handleReload}
          />
          <BeddingPack 
            data={{
              booking_id: booking.id, 
              customer_id: booking.customer_id,
              surname: booking.customer_name_en,
              email: booking.customer_email,
              room: booking.room,
              city: booking.city,
              mobile: booking.customer_mobile_en,
              address: booking.accommodation + ', '+booking.accommodation_address,
              postcode: booking.accommodation_postcode
            }} 
            display="chip" 
            reload={handleReloadBeddingPacks}
          />
          <AddCashback booking={booking} reloadCashbacks={reloadCashbacks} />
        </Stack>
      }
    >
      <Stack>
        <Simcards bookingID={booking.id} ref={ref} />
        <BeddingPacks bookingID={booking.id} ref={beddingPackRef} />
        <CashbackList bookingID={booking.id} ref={cashbackListRef} />
      </Stack>
    </RSCard>
  )
}