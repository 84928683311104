
/* 房源数据清理 */

import { Chip, Stack, Typography } from "@mui/material";
import { delUSProperty } from "api/clean";
import { useState } from "react";

export default function CleanData() {

  const [count, setCount] = useState(0)
  const [res, setRes] = useState('')

  const fetchData = async () => {
    const data = await delUSProperty()
    if(data) {
      const newCount = count + 1
      setCount(newCount)
      setRes(data)
      fetchData()
    }
  }

  return (
    <Stack direction="row">
      <Chip label="删除美国房源" onClick={fetchData} />
      <Typography>已删除 {count}</Typography>
      <Typography>{res}</Typography>
    </Stack>
  )
}