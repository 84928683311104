import { Chip } from "@mui/material";

export default function BookingState({ booking }) {

  return (
    <>
    {booking.state === '0' && <Chip label="办理中" />}
    {booking.state === '1' && <Chip label="已完成" color="success" />}
    {booking.state === '2' && <Chip label="已取消" color="error" variant="outlined" />}
    </>
  )
}