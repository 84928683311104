import List from '@mui/material/List';
import Drawer from '@mui/material/Drawer'

// Redux
import { useSelector } from 'react-redux';
import { siderStates } from 'redux/siderSlice';
import { Stack, Typography } from '@mui/material';
import StaffMenu from './menu/StaffMenu';
import AgentMenu from './menu/AgentMenu';
import { hasPermission } from 'utils/layout';
import FinanceMenu from './menu/FinanceMenu';
import BookingMenu from './menu/BookingMenu';
import PropertyMenu from './menu/PropertyMenu';
import AnalyticsMenu from './menu/AnalyticsMenu';
import DeveloperMenu from './menu/DeveloperMenu';


const drawerWidth = 200;

export default function Sider() {
  const { siderOpen } = useSelector(siderStates)

  return (
    <Drawer
      sx={{
        displayPrint: 'none',
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor="left"
      open={siderOpen}
    >
      <Stack spacing={1} ml={2} mt={1}>
        <img src="/logo/logo-200x28.png" alt="logo" width="150" />
        <Typography>运营管理系统</Typography>
      </Stack>

      <List>
        {hasPermission('booking') && <BookingMenu />}
        {hasPermission('property') && <PropertyMenu />}
        {hasPermission('finance') && <FinanceMenu />}
        {hasPermission('agent') && <AgentMenu />}
        {hasPermission('analytics') && <AnalyticsMenu />}
        {hasPermission('staff') && <StaffMenu />}
        {hasPermission('developer') && <DeveloperMenu />}
      </List>
    </Drawer>
  )
}