import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'loader',
  initialState: {
    loading: false,
  },
  reducers: {
    setLoading:(state, action) => {
      state.loading = action.payload
    },
  },
})

export const { 
  setLoading
} = slice.actions

export const loaderStates = state => state.loader

export default slice.reducer

