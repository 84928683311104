import { useState } from "react";
import { Chip, Grid, Skeleton } from "@mui/material";
import RSDialog from "ui/RSDialog";
import { getCommission, getCommissionBookings } from "api/finance";
import InvoiceInfo from "./components/InvoiceInfo";
import InvoiceBookings from "./components/InvoiceBookings";
import RSCard from "ui/RSCard";
import InvoiceBookingSelector from "./components/InvoiceBookingSelector";
import { CheckRounded, CloseRounded } from "@mui/icons-material";


export default function InvoiceChip({ invoiceID, invoiceRef, paid, onUpdate }) {
  
  const [open, setOpen] = useState(false)
  const [invoice, setInvoice] = useState()
  const [bookings, setBookings] = useState()
  const openDialog = async () => {
    setOpen(true)
    if(!invoice) await fetchInvoice()
    if(!bookings) await fetchBookings()
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const fetchInvoice = async () => {
    const data = await getCommission(invoiceID)
    if(data) setInvoice(data)
  }

  const fetchBookings = async () => {
    const data = await getCommissionBookings(invoiceID)
    if(data) setBookings(data)
  }

  const label = invoiceRef?invoiceRef:invoiceID
  const getIcon = () => {
    if(paid === '1') return <CheckRounded />
    else if(paid === '2') return <CloseRounded />
  }

  const getColor = () => {
    if(paid === '1') return 'success'
    else if(paid === '2') return 'error'
  }

  return (
    <>
    <Chip label={label} onClick={openDialog} icon={getIcon()} color={getColor()} />
    <RSDialog
      title={label}
      open={open}
      onClose={closeDialog}
      maxWidth="lg"
      fullWidth
    >
      <Grid container>

        <Grid item xs={3}>
          <RSCard title="佣金信息">
            {!invoice && <Skeleton />}
            {invoice && <InvoiceInfo invoice={invoice} setInvoice={setInvoice} onUpdate={onUpdate} />}
          </RSCard>
        </Grid>

        <Grid item xs={9}>
          <RSCard title="佣金订单" action={invoice?<InvoiceBookingSelector invoice={invoice} bookings={bookings} setBookings={setBookings} />:''}>
            {!bookings && <Skeleton />}
            {bookings && <InvoiceBookings bookings={bookings} setBookings={setBookings} />}
          </RSCard>
        </Grid>

      </Grid>
    </RSDialog>
    </>
  )
}