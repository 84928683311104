import { call } from "./core";

/**
 * 企业微信登录 Callback
 * @param { string } code
 * @param { string } appid
 * @param { string } state 
 * @returns 
 */
export const wecomCallback = async (data = {}) => {
  return await call('admin/auth/wecom', data)
}

export const wecomSignOut = async () => {
  await call('admin/auth/signOut')
}