import { Chip, Grid, Skeleton, Stack } from "@mui/material";
import { deleteBooking, getBooking } from "api/admin/booking";
import { useRef, useState } from "react";
import RSDialog from "ui/RSDialog";
import BookingInfo from "./dialog/booking/BookingInfo";
import BookingComments from "components/comment/BookingComments";
import CustomerInfo from "./dialog/customer/CustomerInfo";
import AgentInfo from "./dialog/agent/AgentInfo";
import BookingGroup from "./dialog/group/BookingGroup";
import { CheckRounded, CloseRounded } from "@mui/icons-material";
import AdminInfo from "./dialog/admin/AdminInfo";
import GiftInfo from "./dialog/gift/GiftInfo";
import TransactionCard from "./dialog/TransactionCard";
import PaymentCard from "./cards/payment/PaymentCard";
import BookingGuide from "./components/BookingGuide";

/**
 * 订单编号, 点击打开订单窗口
 * @param { string } bookingID Booking ID 
 * @param { array } bookings optional
 * @param { function } setBookings optional
 * @returns 
 */

export default function Booking({ bookingID, bookings, setBookings, bookingState }) {

  const [open, setOpen] = useState(false)
  const [booking, setBooking] = useState()
  const transactionCardRef = useRef()

  const openDialog = () => {
    setOpen(true)
    fetchBooking()
  }

  const closeDialog = () => {
    setOpen(false)
    setBooking(null)
  }

  const fetchBooking = async () => {
    if(!booking) {
      const data = await getBooking(bookingID)
      if(data) setBooking(data)
    }
  }

  // 当Booking有变化的时候，更新Bookings列表
  const updateBookings = (updatedBooking) => {
    setBooking(updatedBooking)
    if(bookings && setBookings) {
      setBookings(bookings.map(item =>
        item.id === updatedBooking.id ? updatedBooking : item
      ))
    }
  }  

  const getIcon = () => {
    if(bookingState === '1') return <CheckRounded />
    else if(bookingState === '2') return <CloseRounded />
  }

  const reloadTransactions = () => transactionCardRef.current.reload()

  // 删除订单
  const handleDelete = () => {
    if(window.confirm('确定删除?')) {
      deleteBooking(booking.id)
      setBookings(bookings.filter(item => item.id !== booking.id))
      closeDialog()
    }
  }

  return (
    <>
    <Chip label={bookingID} onClick={openDialog} icon={getIcon()} />
    <RSDialog
      title={"订单明细"}
      open={open}
      onClose={closeDialog}
      fullScreen
      action={
        <>
        {booking && booking.state === '2' && <Chip label="删除" onClick={handleDelete} />}
        </>
      }
    >
      {!booking && <Skeleton />}
      {booking && 
      <Grid container>

        <Grid item xs={6}>
          <Stack>
            <BookingInfo booking={booking} updateBookings={updateBookings} />
            <BookingComments bookingID={booking.id} />
          </Stack>
        </Grid>

        <Grid item xs={3}>
          <Stack>
            <CustomerInfo booking={booking} closeBookingDialog={closeDialog} />
            <AgentInfo booking={booking} setBooking={setBooking} />
            <AdminInfo booking={booking} setBooking={setBooking} />
            <BookingGroup booking={booking} />
          </Stack>
        </Grid>

        <Grid item xs={3}>
          <Stack>
            <TransactionCard booking={booking} ref={transactionCardRef} />
            <PaymentCard booking={booking} reloadTransactions={reloadTransactions} />
            <GiftInfo booking={booking} />
          </Stack>
        </Grid>

        

      </Grid>
       }
    </RSDialog>
    </>
  )
}

/**
 * <Grid item xs={3}>
          <Stack>
            <BookingGuide booking={booking} />
          </Stack>
        </Grid>
 */