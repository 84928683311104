import { Chip, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { deleteAgentAccount, getAgentAccounts, saveAgentPassword, updateAgent, updateAgentAccount } from "api/agent";
import { useState } from "react";
import RSCard from "ui/RSCard";
import RSDialog from "ui/RSDialog";
import RSSelector from "ui/RSSelector";
import RSTable from "ui/RSTable";
import RSTextEditor from "ui/RSTextEditor";
import { startLoading, stopLoading } from "utils/layout";


/**
 * 渠道平台账户设置
 * @returns 
 */
export default function AgentAccounts({ agent, setAgent, callback }) {

  const handleCallback = (updatedData) => {
    const updatedAgent = {...agent, ...updatedData}
    setAgent(updatedAgent)
    if(callback) callback(updatedAgent)
    updateAgent(updatedAgent)
  }

  return (
    <RSCard title="渠道系统" action={<AccountChip agent={agent} />}>
      {!agent && <Skeleton />}
      {agent &&
      <Stack>
        <RSTextEditor item={agent} entity="company_name" prefix="英文简称：" callback={handleCallback} />
        <RSTextEditor item={agent} entity="customer_id" prefix="客户ID：" callback={handleCallback} />
      </Stack>
      }
    </RSCard>
  )
}

function AccountChip({ agent }) {

  const [open, setOpen] = useState(false)
  const [accounts, setAccounts] = useState()

  const openDialog = () => {
    setOpen(true)
    fetchAccounts()
  }

  const closeDialog = () => {
    setOpen(false)
    setAccounts()
  }

  const fetchAccounts = async () => {
    startLoading()
    const data = await getAgentAccounts(agent.id)
    if(data) setAccounts(data)
    stopLoading()
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: 'Username',
      render: (account) => <RSTextEditor item={account} entity="username" callback={updateAgentAccount} />
    },
    {
      title: 'Password',
      render: (account) => <PasswordEditor item={account} entity="password" callback={saveAgentPassword}  />
    },
    {
      title: '渠道顾问',
      render: (account) => <RSTextEditor item={account} entity="name" callback={updateAgentAccount} />
    },
    {
      title: 'Type',
      render: (account) => <TypeSelector account={account} callback={updateAgentAccount} />
    },
    {
      title: '',
      render: (account) => <DelChip account={account} />
    }
  ]

  const AddChip = () => <Chip label="新建账号" onClick={handleAdd} />
  const DelChip = ({account}) => <Chip label="删除" onClick={()=>handleDel(account.id)} />

  const handleAdd = async () => {
    startLoading()
    await updateAgentAccount({company_id: agent.id})
    fetchAccounts()
  }

  const handleDel = async (accountID) => {
    startLoading()
    await deleteAgentAccount(accountID)
    fetchAccounts()
  }

  return (
    <>
    {agent && agent.customer_id > 0 && <Chip label="平台账号" onClick={openDialog} />}
    <RSDialog
      title="平台账号"
      open={open}
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
    >
      {accounts && 
      <RSCard title="账号列表" action={<AddChip />}>
        <RSTable data={accounts} columns={columns} />
      </RSCard>
      }
    </RSDialog>
    </>
  )
}

/**
 * 类型
 * @param {*} param0 
 * @returns 
 */
function TypeSelector({ account, callback }) {

  const options = [
    {key: '0', value: '普通'},
    {key: '1', value: '管理员'}
  ]

  const handleCallback = (option) => {
    callback({id:account.id, admin:option.key})
  }

  return <RSSelector options={options} selectedKey={account.admin} onSelect={handleCallback} />
}


function PasswordEditor(props) {

  const {
    item,
    entity,
    callback
  } = props

  const [editing, setEditing] = useState(false)
 
  const click = () => {
    setEditing(true)
  }


  const press = (e) => {
    if (e.key === 'Enter') {
      update(e.target.value);
    }
  }

  const update = async (value) => {
    setEditing(false)
    if(callback) {
      callback({
        id: item.id,
        [`${entity}`]: value
      })
    }
  }

  const blur = (e) => {
    setEditing(false)
  }

  return (
    <>
    {!editing && 
      <Typography 
        onClick={click} 
        sx={{
          cursor: 'pointer', 
          whiteSpace: 'pre-line'
        }}
      >
       ******
      </Typography>
    }

    {editing && 
      <TextField 
        onKeyDown={press} 
        autoFocus={true} 
        onBlur={blur} 
        size="small" 
      />
    }
    </>
  )
}