import { updateProperty } from "api/property";
import RSCheckChip from "ui/RSCheckChip";
import { fetchProperty, propertyStates } from "redux/propertySlice";
import { useSelector } from "react-redux";
import { startLoading, stopLoading } from "utils/layout";

export default function PropertyHideToggle() {

  const { property } = useSelector(propertyStates)

  const handleClick = async (checked) => { 
    startLoading()
    await updateProperty({
      id: property.id,
      mark_hide: checked
    })
    await fetchProperty(property.id)
    stopLoading()
  }

  return <RSCheckChip checked={property.mark_hide} label="隐藏" onClick={handleClick} />
}