import { Divider, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { updateBooking } from "api/admin/booking";
import RSCard from "ui/RSCard";
import RSTextEditor from "ui/RSTextEditor";
import { lease, rent } from "utils/booking";
import { countryIDtoCurrency, countryIDtoFlag, countryIDtoLeaseUnit } from "configs/country";
import BookingStateSelector from "../../components/BookingStateSelector";
import GenderSelector from "./GenderSelector";
import DegreeSelector from "./DegreeSelector";
import ISCardActions from "ui/ISCardActions";
import RowStack from "ui/RowStack";
import PropertyID from "components/property/PropertyID";


export default function BookingInfo({ booking, updateBookings }) {

  const update = (data) => {
    const updatedBooking = {...booking, ...data}
    updateBookings(updatedBooking)
    updateBooking(data)
  }

  const Action = () => 
    <ISCardActions>
      {booking && <BookingStateSelector booking={booking} update={update} />}
    </ISCardActions>

  return (
    <RSCard title={"订单 - "+booking.id} action={<Action />}>
      {!booking && <Skeleton />}
      { booking && 
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <b>客户信息</b>
            <RSTextEditor item={booking} entity="customer_name" callback={update} prefix="姓名：" />
            <RSTextEditor item={booking} entity="customer_name_en" callback={update} prefix="Name：" />
            <GenderSelector booking={booking} update={update} />
            <RSTextEditor item={booking} entity="customer_email" callback={update} prefix="邮箱：" />
            <RSTextEditor item={booking} entity="customer_mobile" callback={update} prefix="国内手机：" />
            <RSTextEditor item={booking} entity="customer_mobile_en" callback={update} prefix="国外手机：" />
            <RSTextEditor item={booking} entity="customer_nationality" callback={update} prefix="国籍：" />
            <RSTextEditor item={booking} entity="customer_wechat" callback={update} prefix="微信：" />
            <RSTextEditor item={booking} entity="customer_dob" callback={update} prefix="生日：" />
            <RSTextEditor item={booking} entity="customer_university" callback={update} prefix="学校：" />
            <DegreeSelector booking={booking} update={update} />
            <RSTextEditor item={booking} entity="customer_address" callback={update} prefix="地址：" />
            <RSTextEditor item={booking} entity="customer_postcode" callback={update} prefix="邮编：" />
            <Divider />
            <span></span>
            <b>担保人信息</b>
            <span></span>
            <RSTextEditor item={booking} entity="reference_name" callback={update} prefix="姓名：" />
            <RSTextEditor item={booking} entity="reference_relationship" callback={update} prefix="关系：" />
            <RSTextEditor item={booking} entity="reference_mobile" callback={update} prefix="手机：" />
            <RSTextEditor item={booking} entity="reference_email" callback={update} prefix="邮箱：" />
          </Stack>
        </Grid>

        <Grid item xs={6}>
          <Stack spacing={0.5}>
            <b>房源信息</b>
            <RowStack>
              <Typography>房源:</Typography> 
              <PropertyID pid={booking.accommodation_id} />
              <Typography>{booking.accommodation}</Typography>
            </RowStack>
            <Typography>房东：{booking.provider}</Typography>
            <Typography>城市：{countryIDtoFlag(booking.country_id)} {booking.city}</Typography>
           
            <RSTextEditor item={booking} entity="room_type" callback={update} prefix="房型：" />
            <RSTextEditor item={booking} entity="check_in" callback={update} prefix="开始：" />
            <RSTextEditor item={booking} entity="check_out" callback={update} prefix="结束：" />
            <Typography>租期：{lease(booking.check_in, booking.check_out)}</Typography>
            <RSTextEditor item={booking} entity="price" callback={update} prefix={"租金："+countryIDtoCurrency(booking.country_id)} suffix={'/'+countryIDtoLeaseUnit(booking.country_id)} />
            <Typography>总租金：{countryIDtoCurrency(booking.country_id)}{rent(booking)}</Typography>
            <RSTextEditor item={booking} entity="note" callback={update} prefix="客户备注：" multiline />
            <Divider />
            <span></span>
            <b>内部信息</b>
            <span></span>
            <RSTextEditor item={booking} entity="provider_customer_id" callback={update} prefix="官网客户ID："  />
            <RSTextEditor item={booking} entity="provider_booking_id" callback={update} prefix="官网订单ID："  />
            <RSTextEditor item={booking} entity="room" callback={update} prefix="房间号："  />
            <RSTextEditor item={booking} entity="admin_note" callback={update} prefix="内部备注：" multiline />
          </Stack>
        </Grid>
      </Grid>
      }
    </RSCard>
  )
}