import { useEffect, useRef, useState } from "react";
import ISChipDialog from "ui/ISChipDialog";
import AspectRatio from "ui/AspectRatio";

// Sortable
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { Grid, IconButton } from "@mui/material";
import { Close, UploadOutlined } from "@mui/icons-material";
import { startLoading, stopLoading } from "utils/layout";
import { deleteImg, getImgs, sortImgs, uploadImg } from "api/property";
import { useSelector } from "react-redux";
import { fetchProperty, propertyStates } from "redux/propertySlice";

/**
 * 房源图片编辑器
 * 通用
 * @returns 
 */
export default function PropertyPhotoEditor() {

  const { property } = useSelector(propertyStates)

  const [items, setItems] = useState()
  const ref = useRef()

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  function handleDragEnd(event) {
    const {active, over} = event;
    
    if (active.id !== over.id) {
      const oldIndex = items.map(i => i.id).indexOf(active.id);
      const newIndex = items.map(i => i.id).indexOf(over.id);
      const newItems = arrayMove(items, oldIndex, newIndex);
      setItems(newItems);
      sortImgs(property.id, newItems)
    }
  }

  const fetchImgs = async () => {
    const data = await getImgs(property.id)
    if(data) setItems(data)
  }

  const handleClick = () => {
    ref.current.click()
  }

  const handleUpload = async (e) => {
    startLoading()
    const files = e.target.files;
    const formData = new FormData()
    formData.append('property_id', property.id)

    for(let i=0; i<files.length; i++) {
      formData.append('files[]', files[i])
    }

    await uploadImg(formData)
    fetchImgs()
    stopLoading()
  }

  useEffect(()=>{
    setItems(property.imgs)
  }, [property.imgs])

  const handleClose = () => {
    fetchProperty(property.id)
  }

  return (
    <ISChipDialog
      chipLabel="图片"
      dialogTitle="图片管理"
      maxWidth="lg"
      fullWidth
      onClose={handleClose}
      iconAction={
        <>
          <input
            accept="image/*"
            id={"files"}
            type="file"
            multiple
            ref={ref}
            onChange={handleUpload}
            style = {{display:'none'}}
          />
          <label htmlFor={"files"}>
          <IconButton onClick={handleClick}>
            <UploadOutlined />
          </IconButton>
          </label>
        </>
      }
    >
      {
        items &&
        <DndContext 
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
      >
        <SortableContext 
          items={items}
        >
          <Grid container spacing={2}>
          {items.map((item, index) => <SortableItem key={index} id={item.id} item={item} items={items} setItems={setItems} />)} 
          </Grid>
        </SortableContext>
      </DndContext>
      }
    </ISChipDialog>
  )
}

function SortableItem(props) {
  const { item, items, setItems } = props
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const handleDelete = (imgID) => {
    if(window.confirm('确定删除?')){
      deleteImg(imgID)
      const newItems = items.filter(img => img.id !== imgID);
      setItems(newItems)
    }
  }
  
  
  return (
    <Grid item xs={2} ref={setNodeRef} style={style} >

      <IconButton  onClick={()=>handleDelete(item.id)} size="small" sx={{float: 'left', zIndex: 100}}>
        <Close />
      </IconButton>

      <AspectRatio ratio={2/3.2} >
        <img src={item.url} alt="" {...attributes} {...listeners}  />
      </AspectRatio>

    </Grid>
  )
}