import ProviderSelector from "components/provider/ProviderSelector";
import { startLoading } from "utils/layout";
import { updateProperty } from "api/property";
import { fetchProperty, propertyStates } from "redux/propertySlice";
import { useSelector } from "react-redux";


export default function PropertyProviderSelector() {
  const { property } = useSelector(propertyStates)

  const handleCallback = async (provider) => {
    startLoading()
    await updateProperty({
      id: property.id,
      provider_id: provider.id
    })
    fetchProperty(property.id)
  }

  return <ProviderSelector label={property.provider} providerID={property.provider_id} callback={handleCallback} />
}