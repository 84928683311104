import RSTable from "ui/RSTable"
import InvoiceChip from "components/provider/commission/InvoiceChip"
import { countryIDtoCurrency } from "configs/country"
import ProviderCommission from "components/provider/commission/ProviderCommission"
import InvoiceStateSelector from "components/provider/commission/components/InvoiceStateSelector"

export default function InvoiceList({invoices, setInvoices}) {

  const handleUpdate = (updatedData) => {
    const updatedInvoices = invoices.map( invoice => 
      invoice.id === updatedData.id ? { ...invoice, ...updatedData} : invoice
    )
    if(setInvoices) setInvoices(updatedInvoices)
  }

  const columns = [
    {
      title: 'ID',
      render: (invoice) => <InvoiceChip invoiceID={invoice.id} invoiceRef={invoice.reference_number} paid={invoice.paid} onUpdate={handleUpdate} />
    },
    {
      title: '供应商',
      render: (invoice) => <ProviderCommission providerID={invoice.provider_id} providerName={invoice.provider} />
    },
    {
      title: '应收',
      render: (invoice) => invoice.send_date + ' ' + countryIDtoCurrency(invoice.amount_currency_type) + invoice.amount
    },
    {
      title: '实收',
      render: (invoice) => invoice.date + ' ' + countryIDtoCurrency(invoice.currency_type) + invoice.total
    },
    {
      title: '状态',
      render: (invoice) => <InvoiceStateSelector invoice={invoice} />
    },
    {
      title: '备注',
      dataIndex: 'note',
      width: '200'
    }
  ]

  return <RSTable data={invoices} columns={columns} />
}