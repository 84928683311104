import { Grid, Pagination, Skeleton, Stack } from "@mui/material";
import PropertyGridItem from "./list/PropertyGridItem";

export default function PropertyGrid({ properties, page, pages, handleChangePage, subtitle, reloadProperties }) {

  return (
    <>
      {properties && 
      <Grid container>
        {properties.map(property => 
          <Grid item xs={2} key={property.id}>
            <PropertyGridItem property={property} subtitle={subtitle} reloadProperties={reloadProperties} />
          </Grid>
        )}
      </Grid>
      }
      <Stack mt={2}>
      {!properties && <Skeleton />}
      {pages > 1 && <Pagination count={pages} page={page} onChange={handleChangePage} /> }
      </Stack>
    </>
  )
}