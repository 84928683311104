import { Grid } from "@mui/material";
import { updateRoomType } from "api/room";
import { useState } from "react";
import ISChipDialog from "ui/ISChipDialog";
import RSCard from "ui/RSCard";


export default function RoomTypeImgEditor({ imgs, roomtype, callback }) {

  const [roomTypeImgs, setRoomTypeImgs] = useState([])

  const reloadRoomTypeImgs = () => {
    // 将逗号分隔的字符串转换为 ID 数组
    var roomtype_ids = roomtype.thumbnails.split(',');
    // 初始化一个空数组来存储筛选出的图像对象
    var roomtype_imgs_array = [];
    // 遍历每个 ID 并筛选对应的图像对象
    roomtype_ids.forEach(function(img_id) {
        imgs.forEach(function(img) {
            if (img.id === img_id) {
                roomtype_imgs_array.push(img);
            }
        });
    });
    setRoomTypeImgs(roomtype_imgs_array)
  }

  const selectImg = (imgID) => {
    const selectedImg = imgs.find(img => img.id === imgID);
    if (selectedImg && !roomTypeImgs.some(img => img.id === imgID)) {
        setRoomTypeImgs([...roomTypeImgs, selectedImg]);
    }
  }

  const unselectImg = (imgID) => {
    setRoomTypeImgs(roomTypeImgs.filter(img => img.id !== imgID));
  }

  const updateThumbnails = async () => {
    const idsString = roomTypeImgs.map(item => item.id).join(',');
    if(idsString !== roomtype.thumbnails) {
      updateRoomType({id: roomtype.id, thumbnails: idsString })

      
    
      const updatedRoomType = {
        ...roomtype,
        imgs: roomTypeImgs,
        thumbnails: idsString
      }
      callback(updatedRoomType)
      
    }
  }

  return (
    <ISChipDialog
      chipLabel="图片"
      maxWidth="lg"
      onOpen={reloadRoomTypeImgs}
      onClose={updateThumbnails}
    >
      <Grid container>
        <Grid item xs={6}>
          <RSCard title="房源图片">
            <Grid container>
              {
                imgs && imgs.map(img => 
                  <Grid item xs={3} key={img.id}>
                    <img src={img.url} width={"100%"} onClick={()=>selectImg(img.id)} />
                  </Grid>
                )
              }
            </Grid>
          </RSCard>
        </Grid>

        <Grid item xs={6}>
          <RSCard title="房型图片">
            <Grid container>
              {
                roomTypeImgs && roomTypeImgs.map(img => 
                  <Grid item xs={3} key={img.id}>
                    <img src={img.url} width={"100%"} onClick={()=>unselectImg(img.id)} />
                  </Grid>
                )
              }
            </Grid>
          </RSCard>
        </Grid>
      </Grid>
    </ISChipDialog>
  )
}