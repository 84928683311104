import { Chip } from "@mui/material"
import { paid } from "api/payment"

/**
 * 代付按钮
 * 代付后点击，可以通知客户已代付，可以自动生成代付出账记录
 */
export default function ButtonPaid({ paymentID, callback }) {
  
  const handleClick = async () => {
    if(window.confirm('确认已代付?')) {
      await paid(paymentID)
      callback()
    }
  }

  return <Chip label="代付" color="error" onClick={handleClick} />
}