import { call } from "./core";

/* 房型价格 */

// 新建房型
export const addRoomType = async (propertyID) => {
  await call ('admin/property/room/addRoomType/'+propertyID)
}

// 删除房型
export const delRoomType = async (roomID) => {
  await call ('admin/property/room/delRoomType/'+roomID)
}

// 更新房型
export const updateRoomType = async (data) => {
  await call ('admin/property/room/updateRoomType', data)
}

// 新建价格
export const addPrice = async (roomtypeID) => {
 await call ('admin/property/room/addPrice/'+roomtypeID)
}

// 删除价格
export const delPrice = async (priceID) => {
  await call ('admin/property/room/delPrice/'+priceID)
}

// 更新价格
export const updatePrice = async (data) => {
  await call ('admin/property/room/updatePrice', data)
}