/**
 * MP4 视频 
 */


import { useRef } from "react";

import { Typography } from "@mui/material";
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined'

import RowStack from "ui/RowStack";
import Dialog from "ui/Dialog";
import Link from "ui/Link";

export default function Video({ video }){

  const ref = useRef()

  const openDialog = () => {
    ref.current.open()
  }

  return (
    <RowStack>
      <PlayCircleOutlineOutlinedIcon />
      <Link onClick={openDialog}>
        <Typography>{video.title}</Typography>
      </Link>
      <Dialog
        title={video.title}
        ref={ref}
        noContentPadding
      >

      <video width="100%" height="600" controls>
        <source src={video.url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      </Dialog>
    </RowStack>
  )
}