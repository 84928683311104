import { call } from "./core";

/* 代付 */

/**
 * 某个订单的代付记录
 * @param {*} bookingID
 * @returns 
 */
export const getBookingPayments = async (bookingID) => {
  return await call('admin/service/payment/getListByBooking/'+bookingID)
}

/**
 * 保存代付记录
 * @param {*} data 
 */
export const savePayment = async (data) => {
  await call('admin/service/payment/save', data)
}

/**
 * 删除无效代付记录
 * @param {*} paymentID 
 */
export const deletePayment = async (paymentID) => {
  await call('admin/service/payment/delete/'+paymentID)
}

/**
 * 完成代付
 * @param {*} paymentID 
 */
export const paid = async (paymentID) => {
  await call('admin/service/payment/paid/'+paymentID)
}

export const refund = async (data) => {
  await call('admin/service/payment/refund', data)
}