import { IconButton } from '@mui/material'
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { siderStates, setSiderOpen } from 'redux/siderSlice';

export default function SiderToggleButton() {

  const dispatch = useDispatch()
  const { siderOpen } = useSelector(siderStates)

  const handleOpen = () => {
    dispatch(setSiderOpen(!siderOpen))
  }

  return (
    <IconButton
      color="inherit"
      onClick={handleOpen}
      sx={{ mr: 2 }}
    >
      {siderOpen?<NavigateBeforeOutlinedIcon />:<NavigateNextOutlinedIcon />}
    </IconButton>
  )
}

