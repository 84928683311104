import axios from 'axios'
import { showFail, signOut } from 'utils/layout';
import qs from 'qs'

axios.defaults.withCredentials = true;

let endpoint = 'https://api3.51room.com/';
if(process.env.NODE_ENV === 'development') {
  endpoint = "http://localhost:8080/";
}

/**
 * Call API
 * @param { string } uri api
 * @param { object } data POST Data 
 * @returns 
 */
export const call = async (uri, data, type = "postget") => {
  try {

    let headers = {"Content-Type": "application/json"}
    if(type === 'upload') headers = {"Content-Type": "multipart/form-data"}
    let response = null
    
    const url = endpoint+uri

    if(data) {
      console.log('Post: '+url)
      console.log(data)
      let postData = qs.stringify(data)
      if(type === 'upload') postData = data
      response = await axios.post(url, postData, headers);
    } else {
      console.log('Get: '+url)
      response = await axios.get(url, headers);
    }
    
    if(response) {
      console.log(response.data)
      const data = response.data;
      return data;
    }
    else return response;
  } catch (error) {
    if(error.response) {
      const res = error.response
      if(res.data) {
        // console.error(res.data)
        if(res.data.message) showFail(res.data.message)
        else if(res.data.messages) showFail(res.data.messages.error)
        else showFail(res.status+' '+res.statusText)
      }
      if(res.status === 401) signOut()
    }

    return null;
  }
}