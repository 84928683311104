import { call } from "./core";

/* 渠道 */

/**
 * 渠道信息
 * @param { string } agentID 
 * @returns Agent Object
 */
export const getAgent = async (agentID) => {
  return await call('admin/agent/getAgent/'+agentID)
}

/**
 * 更新渠道
 * @param { object } data Agent Object 
 */
export const updateAgent = async (data) => {
  await call('admin/agent/updateAgent', data)
}

/**
 * 搜索渠道
 * @param { string } keywords 
 * @returns 
 */
export const searchAgent = async (keywords) => {
  return await call('admin/agent/searchAgent', {keywords:keywords})
}

/**
 * 渠道列表
 * @param { string } type 渠道类型ID
 * @returns array
 */
export const getAgentList = async (type) => {
  return await call('admin/agent/getAgentList/'+type)
}

/**
 * 渠道未结算订单(未取消的，没有agent invoice id的)
 * @param { string } agentID 
 * @returns 
 */
export const getAgentUnpaidBookings = async (agentID) => {
  return await call('admin/agent/getUnpaidBookings/'+agentID)
}

/**
 * 渠道佣金结算记录
 * @param { string } agentID  
 * @returns 
 */
export const getAgentInvoices = async (agentID) => {
  return await call('admin/agent/getInvoices/'+agentID)
}

/**
 * 渠道佣金 信息更新
 * @param { object } data 
 */
export const updateAgentInvoice = async (data) => {
  await call('admin/agent/updateInvoice', data)
}

/**
 * 渠道佣金相关订单
 * @param { string } invoiceID 
 * @returns 
 */
export const getInvoiceBookings = async (invoiceID) => {
  return await call('admin/agent/getInvoiceBookings/'+invoiceID)
}

/**
 * 所以未结订单
 * @returns 
 */
export const getAllUnpaidBookings = async (year) => {
  return await call('admin/agent/getAllUnpaidBookings/'+year)
}

/**
 * 渠道平台账号
 * @param {*} agentID 
 * @returns 
 */
export const getAgentAccounts = async (agentID) => {
  return await call('admin/agent/getAgentAccounts/'+agentID)
}

/**
 * 更新平台账号
 * @param {*} data 
 */
export const updateAgentAccount = async (data) => {
  await call('admin/agent/updateAgentAccount', data)
}

/**
 * 删除账号
 * @param {*} accountID 
 */
export const deleteAgentAccount = async (accountID) => {
  await call('admin/agent/deleteAgentAccount/'+accountID)
}

/**
 * 保存密码
 * @param {*} data 
 */
export const saveAgentPassword = async (data) => {
  await call('admin/agent/saveAgentPassword', data)
}