import { ProviderCommissionDialog } from "components/provider/commission/ProviderCommission";
import ProviderSearch from "components/provider/ProviderSearch";
import { useEffect, useRef, useState } from "react";

/**
 * 搜索供应商 查看佣金列表
 * @returns 
 */
export default function ProviderSearchForCommission() {

  const ref = useRef()
  const [providerID, setProivderID] = useState()
  const [providerName, setProviderName] = useState()

  const handleCallback = (provider) => {
    setProivderID(provider.id)
    setProviderName(provider.name)
  }

  useEffect(() => {
    if(providerID) ref.current.open()
  }, [providerID])

  return (
    <>
      <ProviderSearch callback={handleCallback} label="搜索" />
      <ProviderCommissionDialog  providerID={providerID} providerName={providerName} ref={ref} />
    </>
  )
}