import { call } from "../core";

/* 订单 */

/**
 * 新订单
 * @param {*} options 
 * @returns 
 */
export const getNewBookings = async (options) => {
  return await call('admin/booking/booking/getNewBookings', options)
}

/**
 * 订单
 * @param { string } bookingID 
 * @returns 
 */
export const getBooking = async (bookingID) => {
  return await call('admin/booking/booking/getBooking/'+bookingID)
}

/**
 * 更新订单
 * @param { object } data {id, ...} 
 */
export const updateBooking = async (data) => {
  await call('admin/booking/booking/updateBooking', data)
}

export const deleteBooking = async (bookingID) => {
  await call('admin/booking/booking/deleteBooking/'+bookingID)
}

/**
 * 搜索订单
 * @param {*} keywords 
 * @returns 
 */
export const searchBooking = async (keywords) => {
  return await call('admin/booking/booking/searchBooking', {keywords: keywords})
}

/**
 * 搜索渠道
 * @param { string } keywords 
 * @returns 
 */
export const searchAgent = async (keywords) => {
  return await call('admin/booking/agent/searchAgent', {keywords:keywords})
}

/**
 * 切换订单状态
 * @param {*} bookingID 
 * @param {*} state 
 */
export const changeBookingState = async (bookingID, state) => {
  await call('admin/booking/details/changeState', {booking_id: bookingID, state: state})
}

/**
 * 订单留言
 * @param {*} bookingID 
 * @returns 
 */
export const getBookingComments = async (bookingID) => {
  return await call('admin/booking/comment/getBookingComments/'+bookingID)
}

/**
 * 添加订单留言
 * @param {*} data 
 */
export const addBookingComment = async (data) => {
  return await call('admin/booking/comment/addBookingComment', data)
}

/**
 * 删除订单留言
 * @param {*} commentID 
 */
export const delBookingComment = async (commentID) => {
  await call('admin/booking/comment/delBookingComment/'+commentID)
}

/**
 * 跟单顾问列表
 * @returns 
 */
export const getAllStaffs = async () => {
  return await call('admin/booking/booking/getAllStaffs')
}

/****************************************************************************/
/* Group 小组订单 */

/**
 * 小组订单列表
 * @param {*} groupID 
 * @returns 
 */
export const getGroupBookings = async (groupID) => {
  return await call('admin/booking/group/getBookings/'+groupID)
}

/**
 * 添加小组订单
 * @param {*} bookingID 
 */
export const addGroupMember = async (bookingID, groupID, otherBookingID) => {
  await call('admin/booking/group/addBooking', {booking_id:bookingID, group_id: groupID, other_booking_id: otherBookingID})
}

/**
 * 移除小组订单
 * @param {*} bookingID 
 */
export const removeGroupMember = async (bookingID, groupID) => {
  await call('admin/booking/group/removeBooking', {booking_id:bookingID, group_id: groupID})
}

/****************************************************************************/
/* Transactions 订单收支 */

/**
 * 订单收支列表
 * @param {*} bookingID 
 * @returns 
 */
export const getBookingTransactions = async (bookingID) => {
  return await call('admin/booking/payment/getBookingTransactions/'+bookingID)
}

/**
 * 保存收支记录
 * @param {*} data 
 */
export const saveTransaction = async (data) => {
  await call('admin/booking/payment/saveTransaction', data)
}

/**
 * 删除收支记录
 * @param {*} transactionID 
 */
export const deleteTransaction = async (transactionID) => {
  await call('admin/booking/payment/deleteTransaction/'+transactionID)
}

/****************************************************************************/
/* Gift 订单礼品 */

/**
 * 订单 电话卡
 * @param {*} bookingID 
 * @returns 
 */
export const getBookingSimcards = async (bookingID) => {
  return await call('admin/booking/gift/getBookingSimcards/'+bookingID)
}

export const updateSimcard = async (data) => {
  await call('admin/booking/gift/saveSimcard', data)
}

export const deleteSimcard = async (simcardID) => {
  await call('admin/booking/gift/deleteSimcard/'+simcardID)
}

/**
 * 订单 被枕
 * @param {*} bookingID 
 * @returns 
 */
export const getBookingBeddingPacks = async (bookingID) => {
  return await call('admin/booking/gift/getBookingBeddingPacks/'+bookingID)
}

export const updateBeddingPack = async (data) => {
  await call('admin/booking/gift/saveBeddingPack', data)
}

export const deleteBeddingPack = async (beddingPackID) => {
  await call('admin/booking/gift/deleteBeddingPack/'+beddingPackID)
}

/**
 * 预定指导
 * @param {*} providerID 
 * @param {*} bookingID 
 * @returns 
 */
export const getBookingProcess = async (providerID, propertyID) => {
  return await call('admin/booking/process/getProcess', {provider_id: providerID, property_id: propertyID})
}


/* 顾问相关订单 */
export const getStaffBookings = async (filter) => {
  return await call('admin/booking/booking/getStaffBookings', filter)
}