/* 员工销售业绩表现 */

import { getStaffPerformance } from "api/admin/staff"
import { useEffect, useState } from "react"
import RSCard from "ui/RSCard"
import RSYearSelector from "ui/RSYearSelector"
import PerformanceCardContent from "components/staff/PerformanceCardContent"
import { Skeleton } from "@mui/material"


export default function StaffPerformance({ staff }) {

  const date = new Date()
  const [year, setYear] = useState(date.getFullYear())
  const [data, setData] = useState()

  const getData = async () => {
    setData(null)
    const res = await getStaffPerformance({staff_id: staff.id, year: year})
    if(res) setData(res)
  }

  useEffect(() => {
    getData()
  }, [year, staff])

  return (
    <>
      {
        data ?
        <RSCard
          title={data.staff.name+' - 业绩'}
          action={<RSYearSelector year={year} onSelect={setYear} />}
        >
          <PerformanceCardContent data={data} />
        </RSCard>
        :
        <Skeleton />
      }
    </>
  )
}