/* 我的业绩 */

import { Grid } from "@mui/material"
import { getMyPerformance } from "api/admin/me"
import PerformanceCardContent from "components/staff/PerformanceCardContent"
import { useEffect, useState } from "react"
import RSCard from "ui/RSCard"
import RSYearSelector from "ui/RSYearSelector"


export default function MyPerformance() {

  const date = new Date()
  const [year, setYear] = useState(date.getFullYear())
  const [data, setData] = useState()

  const getData = async () => {
    const res = await getMyPerformance(year)
    if(res) setData(res)
  }

  useEffect(() => {
    getData()
  }, [year])

  return (
    <Grid container>
      <Grid item xs={12} lg={4}>
      {
        data && 
        <RSCard
          title={data.staff.name+' - 业绩'}
          action={<RSYearSelector year={year} onSelect={setYear} />}
        >
          <PerformanceCardContent data={data} />
        </RSCard>
    
      }
      </Grid>
    </Grid>
  )
  
}