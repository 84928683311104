import { getCityOptions } from "api/web/city"
import { useEffect, useState } from "react"
import RSSelector from "ui/RSSelector"

/**
 * 通用城市选择器
 * @param {*} cityID 
 * @param {*} countryID
 * @param {*} onSelect - (option) {cityID, cityName}
 * @returns 
 */
export default function CitySelector({ cityID, countryID, onSelect }) {
  
  const [options, setOptions] = useState()

  const fetchOptions = async () => {
    const data = await getCityOptions(countryID)
    if(data) setOptions(data)
  }

  useEffect(() => {
    fetchOptions()
  }, [])

  return (
    <>
    { 
      options &&
      <RSSelector 
        selectedKey={cityID} 
        options={options}
        onSelect={onSelect}
      />
    }
    </>
  )
}