import { useEffect, useState } from "react"
import { Button, FormControl, MenuItem, Select, Stack, TextField, InputLabel, Box} from "@mui/material"
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import RSCheckbox from "./RSCheckbox";
import { showFail, startLoading, stopLoading } from "utils/layout";


/*
const form = [
    {
      type: 'date',
      name: 'date',
      label: '转账日期'
    },
    {
      type: 'picker',
      name: 'currency',
      label: '币种',
      options: [
        {name: '英镑', value: 0},
        {name: '人民币', value: 1},
      ]
    },
    {
      type: 'input',
      name: 'amount',
      label: '金额'
    },
    {
      type: 'note',
      name: 'note',
      label: '备注'
    },
    {
      type: 'checkbox',
      name: 'check',
      label: 'hide'
    }
  ]
 <RSForm data={invoice} fields={form} api="Agent/InvoiceUpdate" callback={formCallback} />
*/


/**
 * 
 * @param { array } data 表单数据
 * @param { array } fields 表单字段
 * @param { function } onSave 保存数据
 * @param { function } onDelete 删除数据
 * @returns 
 */
export default function RSForm({ data, fields, onSave, onDelete }) {

  const [formData, setFormData] = useState()

  useEffect(() => {
    setFormData({...data})
  }, [data])

  // 表单字段变化
  const changeData = (name, value) => {
    setFormData({
      ...formData,
      [`${name}`]: value
    })
  }

  // 保存数据
  const handleSave = async () => {
    console.log(formData)
    
    for(let i=0; i < fields.length; i++) {
      if(fields[i].contents) {
        for(let j=0; j < fields[i].contents.length; j++)
        if(!validate(fields[i].contents[j])) return false
      } else {
        if(!validate(fields[i])) return false
      }
    }
   
    startLoading()
    await onSave(formData)
    stopLoading()
  }


  const validate = (f) => {
    if(f.required) {
      if(f.type === 'picker' &&  parseInt(formData[f.name]) === 0) {
        showFail(f.label+' 必填')
        return false
      } else if(!formData[f.name] || formData[f.name] === '') {
        showFail(f.label+' 必填')
        return false
      }
    } return true
  }

  // 删除数据
  const handleDelete = async () => {
    if(window.confirm('确定删除?')){
      startLoading()
      await onDelete()
      stopLoading()
    }
  }

  return (
  <>
  { data && 
    <Stack spacing={2}>
      {formData && fields && fields.map((f, index)=>
        <Box key={index}>
          { !f.contents && <FormField value={formData[f.name]} setValue={changeData} {...f} attr={f} />}
          { f.contents && 
            <Stack direction='row' spacing={2}>
              {f.contents.map(c => 
                <FormField key={c.name} attr={c} label={c.label} name={c.name} value={formData[c.name]} setValue={changeData} type={c.type} options={c.options} />
              )}
            </Stack>
          }
        </Box>
        
      )}
      <Stack direction="row-reverse" spacing={2}>
        <Button variant='contained' onClick={handleSave}>保存</Button>
        {data.id !== undefined && onDelete && <Button variant='text' onClick={handleDelete}>删除</Button>}
      </Stack>
    </Stack>
  }
  </>
  )
}

function FormField(props) {
  const { label, name, value, setValue, type, attr } = props


  return (
    <>
      {type === 'input' && <Input label={label} name={name} value={value} setValue={setValue} attr={attr} />}
      {type === 'picker' && <Picker {...props} />}
      {type === 'note' &&  <Input label={label} name={name} value={value} setValue={setValue} multiline row={10} />}
      {type === 'date' &&  <DatePicker label={label} name={name} date={value} setDate={setValue} />}
      {type === 'checkbox' && <Checkbox {...props} /> }
    </>
  )
}

/*********************************************************************/
/* Input */
function Input(props) {

  const { label, name, value, setValue, attr, ...rest } = props

  const handleChange = (e) => {
    setValue(name, e.target.value)
  }

  return (<TextField label={label} value={value?value:''} onChange={handleChange} fullWidth {...rest} type={attr.inputType!==undefined?attr.inputType:'text'} />)
}

/*********************************************************************/
/* Picker */
function Picker(props) {
  const { label, name, value, setValue, options } = props;

  const handleChange = (event) => {
    setValue(name, event.target.value);
  }

  return (
    <FormControl fullWidth>
      <InputLabel sx={{backgroundColor: '#fff', paddingX: '0.1rem'}}>{label}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
      >
        { options.map( option => <MenuItem value={option.value} key={option.value}>{option.name}</MenuItem> )}
      </Select>
    </FormControl>
  );
}

/*********************************************************************/
/* Date Picker */
function DatePicker(props) {

  const { label, name, date, setDate } = props
  const dateFormat = 'YYYY-MM-DD'

  const changeDate = (newDate) => {
    setDate(name, dayjs(newDate).format(dateFormat).toString())
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker  
        inputFormat={dateFormat}
        value={date}
        onChange={changeDate}
        renderInput={(params) => <TextField {...params} placeholder={dateFormat} label={label} fullWidth />} 
      />
    </LocalizationProvider>
  )
}

/*********************************************************************/
/* Checkbox */
function Checkbox({label, name, value, setValue}) {

  const handleChange = (bool) => {
    setValue(name, bool?'1':'0')
  }

  return <RSCheckbox label={label} defaultChecked={value==='1'?true:false} onChange={handleChange} />
}