import RSSelector from "./RSSelector";

/**
 * 月份选择器
 * @param { number } month
 * @param { function } onSelect 
 * @returns 
 */
export default function RSMonthSelector({ month, onSelect }) {

  // 初始化一个空数组用于存储选项
  const options = [];

  for (let i = 1; i <= 12; i++) {
      options.push({ key: i, value: i});
  }

  const handleSelect = (option) => {
    onSelect(option.key)
  }

  return <RSSelector options={options} selectedKey={month} onSelect={handleSelect} />
}