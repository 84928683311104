import PropertyList from "components/property/list/PropertyList";
import HKRoomFilter from "./HKRoomFilter";

export default function HKProperty() {
  return <PropertyList 
            countryID={11} 
            noCity 
            noType
            actions={[
              <HKRoomFilter />
            ]}
         />
}