import MenuItems from "../components/MenuItems";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';

const menu = {
  "text": "开发功能",
  "icon": SettingsOutlinedIcon,
  "permission": "developer",
  "sub": [
    {
      "text": "数据清理",
      "path": "/developer/data-clean"
    },    
  ]
}

export default function DeveloperMenu() {

  return <MenuItems menu={menu} />
}