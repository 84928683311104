import { useEffect, useRef, useState } from "react";
import ISChipDialog from "ui/ISChipDialog";
import PropertyGrid from "components/property/PropertyGrid";
import DeleteProviderAllPropertiesIconButton from "components/developer/DeleteProviderAllPropertiesIconButton";
import { getProviderProperties } from "api/admin/provider";
import RowStack from "ui/RowStack";
import PropertyAddNewIconButton from "components/property/editor/PropertyAddNewIconButton";
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { IconButton } from "@mui/material";

/**
 * 供应商房源列表
 * @param {*} param0 
 * @returns 
 */
export default function ProviderProperty({ provider }) {

  const ref = useRef()
  const [properties, setProperties] = useState()
  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)

  const [filter, setFilter] = useState({
    provider_id: provider.id
  })

  const fetchProperty = async () => {
    setProperties(null)
    setPages(1)
    const data = await getProviderProperties(filter, page)
    if(data) {
      setProperties(data.data)
      setPages(data.pages)
    } else {
      setProperties([])
      setPages(1)
    }
  }

  const handleChangePage = (e, v) => {
    setPage(v)
  }

  useEffect(() => {
    if(ref.current.isPresent()) fetchProperty()
  }, [page])

  return (
    <ISChipDialog
      ref={ref}
      chipLabel="房源"
      dialogTitle={provider.name}
      onOpen={fetchProperty}
      fullScreen
      iconAction={
        <RowStack>
          <ReloadButton action={fetchProperty} />
          <DeleteProviderAllPropertiesIconButton provider={provider} onClose={fetchProperty} />
          <PropertyAddNewIconButton provider={provider} callback={fetchProperty} />
        </RowStack>
      }
    >
      <PropertyGrid 
        subtitle="city"
        properties={properties}
        pages={pages}
        page={page}
        handleChangePage={handleChangePage}
        reloadProperties={fetchProperty}
      />
    </ISChipDialog>
  )
}

function ReloadButton({ action }) {
  const handleClick = () => {
    if(action) action()
  }
  return (
    <IconButton onClick={handleClick}>
      <ReplayOutlinedIcon />
    </IconButton>
  )
}