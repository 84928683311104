import bookingTableColumns from "components/booking/table/bookingTableColumns"
import ProviderCommission from "components/provider/commission/ProviderCommission"

export default function uninvoicedBookingsColumns() {
  const columns = bookingTableColumns()

  columns.push(
    {
      title: '房东',
      render: (booking) => <ProviderCommission providerID={booking.provider_id} providerName={booking.provider} />
    }
  )

  return columns
}