import Booking from "../Booking"
import BookingCustomer from "./BookingCustomer"
import BookingProperty from "./BookingProperty"
import BookingLease from "./BookingLease"
import BookingState from "./BookingState"

/**
 * 订单列表基本列
 * @returns 
 */
export default function bookingTableColumns(bookings, setBookings) {

  const columns = [
    {
      title: '#',
      render: (booking, index) => {return index+1}
    },
    {
      title: '订单 ID',
      render: (booking) => <Booking bookingID={booking.id} bookings={bookings} setBookings={setBookings} />
    },
    {
      title: '客户',
      render: (booking) => <BookingCustomer booking={booking} />
    },
    {
      title: '房源',
      render: (booking) => <BookingProperty booking={booking} />
    },
    {
      title: '租期',
      render: (booking) => <BookingLease booking={booking} />
    },
    {
      title: '渠道',
      dataIndex: 'agent_name'
    },
    {
      title: '状态',
      render: (booking) => <BookingState booking={booking} />
    }
  ]

  return columns
}