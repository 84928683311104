/* Cashback 返现 */

import { Box, Skeleton, Stack, Typography } from "@mui/material"
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import ISChipDialog from "ui/ISChipDialog"
import ISForm from "ui/ISForm"
import { simpleOptions as currencyOptions } from "components/selector/CurrencySelector";
import { countryIDtoCurrency } from "configs/country";
import ISCardActions from "ui/ISCardActions";
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import { call } from "api/core";
import { Check } from "@mui/icons-material";

/**********************************************************************
 * API
 */

const getBookingCashbacks = async (bookingID) => {
  return await call('admin/booking/cashback/getCashbacks/'+bookingID)
}

const saveCashback = async (data) => {
  await call('admin/booking/cashback/saveCashback', data)
}

const deleteCashback = async (cashbackID) => {
  await call('admin/booking/cashback/deleteCashback/'+cashbackID)
}



const form = [
  {
    contents: [
      {
        ele: 'picker',
        name: 'currency',
        label: '币种',
        options: currencyOptions
      },
      {
        ele: 'input',
        name: 'amount',
        label: '金额',
        type: 'number',
        required: true
      }
    ]
  },
  {
    ele: 'input',
    name: 'note',
    label: '备注'
  },
  {
    ele: 'input',
    name: 'account',
    label: '账户'
  }
]

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const AddCashback = ({ booking, reloadCashbacks }) => {

  const dialogRef = useRef()

  const cashback = {
    booking_id: booking.id,
    customer_id: booking.customer_id,
    currency: '0',
    amount: '0',
    account: ''
  }

  const handleSave = async (formData) => {
    await saveCashback(formData)
    dialogRef.current.close()
    reloadCashbacks()
  }

  return <ISChipDialog 
    ref={dialogRef}
    chipLabel={"返现"}
    dialogTitle="编辑返现"
    children={<ISForm data={cashback} form={form} onSave={handleSave} />}
  />
}

/**
 * 返现列表
 * @param {*} bookingID 
 * @returns 
 */
const CashbackList = forwardRef(({ bookingID }, ref) => {

  const [cashbacks, setCashbacks] = useState()
  const dialogRef = useRef()

  const fetchCashback = async () => {
    
    setCashbacks(null)
    const data = await getBookingCashbacks(bookingID)
    if(data) setCashbacks(data)
  }

  const handleSave = async (formData) => {
    await saveCashback(formData)
    dialogRef.current.close()
    fetchCashback()
  }

  const handleDelete = async (id) => {
    await deleteCashback(id)
    dialogRef.current.close()
    fetchCashback()
  }

  useEffect(() => {
    fetchCashback()
  }, [])

  useImperativeHandle(ref, () => ({
    reload() { fetchCashback() }
  }))

  return (
    <>
      {!cashbacks && <Skeleton />}
      {cashbacks && 
      <Stack>
        {cashbacks.map(cashback => 
        <ISCardActions key={cashback.id}>
          <SavingsOutlinedIcon />
          {cashback.state === '0' && 
          <ISChipDialog 
            ref={dialogRef}
            chipType="text"
            chipLabel={"返现 " + countryIDtoCurrency(cashback.currency) + ' ' + cashback.amount}
            dialogTitle="编辑返现"
            children={<ISForm data={cashback} form={form} onSave={handleSave} onDelete={()=>handleDelete(cashback.id)} />}
          />}
          {cashback.state === '1' && <Typography>{"返现 " + countryIDtoCurrency(cashback.currency)+' '+cashback.amount}</Typography>}
          {cashback.state === '1' && <Box flexGrow={1} />}
          {cashback.state === '1' && <Check />}
        </ISCardActions>
        )}
      </Stack>
      } 
    </>
  )
})

export { AddCashback, CashbackList }