import { Chip, Skeleton, Stack, TextField } from "@mui/material";
import { searchAgent, updateBooking } from "api/admin/booking";
import { useState } from "react";
import RSDialog from "ui/RSDialog";

export default function AgentSelector({ booking, setBooking }) {

  const [open, setOpen] = useState(false)
  const [agents, setAgents] = useState([])
  const [loading, setLoading] = useState(false)
  const [keywords, setKeywords] = useState('')

  const label = booking.agent_id === '0'?'-':(booking.agent)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleChange = (e) => {
    setKeywords(e.target.value)
  }

  const handlePress = (e) => {
    if(e.key === 'Enter' && keywords) search()
  }

  const search = async () => {
    setLoading(true)
    const data = await searchAgent(keywords)
    if(data) setAgents(data)
    setLoading(false)
  }

  const handleSelect = (agent) => {
    const data = {id: booking.id, agent_id:agent.id, agent:agent.name}
    setBooking({...booking, ...data})
    updateBooking(data)
    closeDialog()
  }

  return (
    <>
    <Chip label={label} onClick={openDialog} />
    <RSDialog
      title="搜索渠道"
      open={open}
      onClose={closeDialog}
    >
      <Stack>
        <TextField 
          value={keywords} 
          onChange={handleChange} 
          onKeyDown={handlePress}
          placeholder="渠道名或ID" 
          disabled={loading}
        />
        <Stack>
          {loading && <Skeleton />}
          {
          agents.map(agent => 
          <Stack key={agent.id} onClick={()=>handleSelect(agent)} direction="row" sx={{cursor: 'pointer'}}>
            {agent.id} {agent.name}
          </Stack>)
          }
        </Stack>
      </Stack>
    </RSDialog>
    </>
  )
}