import { Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { getStaffPermissions, updateStaff } from "api/admin/staff";
import permissions from "configs/permissions";
import ISChipDialog from "ui/ISChipDialog";
import RowStack from "ui/RowStack";

/**
 * 员工权限
 * @param {*} param0 
 * @returns 
 */
export default function StaffPermission({ staff }) {

  const [staffPermissions, setStaffPermissions] = useState([])
  
  const fetchStaffPermissions = async () => {
    const data = await getStaffPermissions(staff.id)
    if(data) {
      const staffPermissionsString = data

      const newArray = Object.keys(permissions).map((permission_en) => ({
        permission_en,
        permission_cn: permissions[permission_en],
        granted: staffPermissionsString.split(',').includes(permission_en),
      }));
  
      setStaffPermissions(newArray)
      
    } else {

      const newArray = Object.keys(permissions).map((permission_en) => ({
        permission_en,
        permission_cn: permissions[permission_en],
        granted: false,
      }));
  
      setStaffPermissions(newArray)
    }
  }

  useEffect(() => {
    setStaffPermissions([])
    fetchStaffPermissions()
  }, [staff])

  const handleClick = async (permission_en) => {
    const newArray =  staffPermissions.map((permission) =>
                        permission.permission_en === permission_en
                          ? { ...permission, granted: !permission.granted }
                          : permission
                      )
    setStaffPermissions(newArray)

    const updatedStaffPermissions = newArray
      .filter((permission) => permission.granted)
      .map((permission) => permission.permission_en)
      .join(',');

    updateStaff({id:staff.id, permissions: updatedStaffPermissions})
  };


  return (
    <ISChipDialog
      chipLabel="权限"
      dialogTitle={staff.name + ' - 权限设置'}
    >
      <RowStack>
        { 
          staffPermissions.map(permission => (
            <Chip 
              key={permission.permission_en} 
              label={permission.permission_cn} 
              color={permission.granted?'primary':'default'} 
              onClick={()=>handleClick(permission.permission_en)} 
            />
          ))
        }
      </RowStack>
    </ISChipDialog>
  )
}

