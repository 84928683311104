import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import SimCardOutlinedIcon from '@mui/icons-material/SimCardOutlined';
import { Box, Chip, Stack, Typography } from "@mui/material";
import { deleteSimcard, getBookingSimcards, updateSimcard } from "api/admin/booking";
import RSDialog from "ui/RSDialog";
import { Check } from "@mui/icons-material";
import RSForm from "ui/RSForm";

/**
 * 订单关联的电话卡
 * @param {*} param0 
 * @returns 
 */
export const Simcards = forwardRef(({ bookingID }, ref) => {

  const [simcards, setSimcards] = useState()

  const fetchData = async () => {
    const data = await getBookingSimcards(bookingID)
    if(data) setSimcards(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useImperativeHandle(ref, ()=> ({
    reload() {
      fetchData()
    }
  }))

  return (
    <>
    {simcards && 
      <Stack>
      {
        simcards.map(simcard=>
          <Stack key={simcard.id} direction="row" alignItems="center">
            <SimCardOutlinedIcon />
            <Simcard data={simcard} reload={fetchData} />
            <Box flexGrow={1}></Box>
            {simcard.parcel_code && <Check />}
          </Stack>
        )
      }
      </Stack>
    }
    </>
  )
})

/**
 * 电话卡 编辑信息
 * @param {*} param0 
 * @returns 
 */
export default function Simcard({ data, reload, display = 'text' }) {

  const [open, setOpen] = useState(false)
  const [simcard, setSimcard] = useState()

  const openDialog = () => {
    setOpen(true)
    if(data) setSimcard(data)
    else setSimcard({})
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleUpdate = async (data) => {
    closeDialog()
    await updateSimcard(data)
    if(reload) reload()
  }

  const handleDelete = async () => {
    closeDialog()
    if(simcard.id) await deleteSimcard(simcard.id)
    if(reload) reload()
  }

  const form = [
    { 
      contents: [
        {
          type: 'input',
          name: 'r_name',
          label: '收件人'
        },
        {
          type: 'input',
          name: 'r_tel',
          label: '收件电话'
        }
      ]
    },
    {
      type: 'input',
      name: 'r_address',
      label: '收件地址'
    }
  ]


  return (
    <>
    {display === 'text' && <Typography sx={{cursor: 'pointer'}} onClick={openDialog}>电话卡</Typography>}
    {display === 'chip' && <Chip onClick={openDialog} label="电话卡" /> }
    <RSDialog
      title="电话卡"
      open={open}
      onClose={closeDialog}
      fullWidth
    >
      <RSForm data={simcard} fields={form} onSave={handleUpdate} onDelete={handleDelete} />
    </RSDialog>
    </>
  )
}