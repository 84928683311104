import RSSelector from "ui/RSSelector";

const options = [
  {key: "1", value: "企微"},
  {key: "2", value: "Lloyds"},
  {key: "3", value: "Tide"},
  {key: "4", value: "MyMoney"},
  {key: "5", value: "支付宝"},
  {key: "6", value: "Paypal"},
  {key: "0", value: "未知"}
];

export { options }

/**
 * 货币选择器
 * @param { string } accountID
 * @param { function } onSelect 
 * @returns 
 */
export default function AccountSelector({ accountID, onSelect }) {

  return <RSSelector selectedKey={accountID} options={options} onSelect={onSelect} />
}

/**
 * 账号名称
 * @param {*} accountID 
 * @returns 
 */
export function getAccountName(accountID) {
  for(let i=0; i<options.length; i++) {
    if(options[i].key === accountID)
      return options[i].value
  }
  return null
}

