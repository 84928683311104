import { Chip, Pagination, Stack } from "@mui/material";
import { getCustomerBookings } from "api/customer";
import bookingTableColumns from "components/booking/table/bookingTableColumns";
import { useState } from "react";
import RSDialog from "ui/RSDialog";
import RSTable from "ui/RSTable";

export default function CustomerBookings({ customerID }) {

  const [page, setPage] = useState(1)
  const [pages, setPages] = useState(1)
  const [open, setOpen] = useState(false)
  const [bookings, setBookings] = useState([])
  const columns = bookingTableColumns(bookings, setBookings)

  const openDialog = () => {
    setOpen(true)
    fetchBookings(1)
  }

  const closeDialog = () => {
    setPage(1)
    setOpen(false)
  }

  const fetchBookings = async (page) => {
    setBookings(null)
    const data = await getCustomerBookings(customerID, page)
    if(data) {
      setBookings(data.bookings)
      setPages(data.pages)
    }
  }

  const handleChangePage = (e, v) => {
    setPage(v)
    fetchBookings(v)
  }

  return (
    <>
      <Chip label="客户订单" onClick={openDialog} />
      <RSDialog
        title="客户订单"
        open={open}
        onClose={closeDialog}
        maxWidth="md"
        fullWidth
      >
        <Stack>
          <RSTable columns={columns} data={bookings} />
          {pages > 1 && <Pagination count={pages} page={page} onChange={handleChangePage} /> }
        </Stack>
      </RSDialog>
    </>
  )
}