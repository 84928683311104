import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { Box, Chip, Stack, Typography } from "@mui/material";
import { deleteBeddingPack, getBookingBeddingPacks, updateBeddingPack } from "api/admin/booking";
import RSDialog from "ui/RSDialog";
import { Check } from "@mui/icons-material";
import RSForm from "ui/RSForm";

/**
 * 订单关联的电话卡
 * @param {*} param0 
 * @returns 
 */
export const BeddingPacks = forwardRef(({ bookingID }, ref) => {

  const [beddingPacks, setBeddingPacks] = useState()

  const fetchData = async () => {
    const data = await getBookingBeddingPacks(bookingID)
    if(data) setBeddingPacks(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useImperativeHandle(ref, ()=> ({
    reload() {
      fetchData()
    }
  }))

  return (
    <>
    {beddingPacks && 
      <Stack>
      {
        beddingPacks.map(beddingPack=>
          <Stack key={beddingPack.id} direction="row" alignItems="center">
            <Inventory2OutlinedIcon />
            <BeddingPack data={beddingPack} reload={fetchData} />
            <Box flexGrow={1}></Box>
            {beddingPack.parcel_code && <Check />}
          </Stack>
        )
      }
      </Stack>
    }
    </>
  )
})

/**
 * 电话卡 编辑信息
 * @param {*} param0 
 * @returns 
 */
export default function BeddingPack({ data, reload, display = 'text' }) {

  const [open, setOpen] = useState(false)
  const [BeddingPack, setBeddingPack] = useState()

  const openDialog = () => {
    setOpen(true)
    if(data) setBeddingPack(data)
    else setBeddingPack({})
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleUpdate = async (data) => {
    closeDialog()
    await updateBeddingPack(data)
    if(reload) reload()
  }

  const handleDelete = async () => {
    closeDialog()
    if(BeddingPack.id) await deleteBeddingPack(BeddingPack.id)
    if(reload) reload()
  }

  const form = [
    { 
      contents: [
        {
          type: 'input',
          name: 'surname',
          label: '收件人'
        },
        {
          type: 'input',
          name: 'email',
          label: 'Email'
        }
      ]
    },
    { 
      contents: [
        {
          type: 'input',
          name: 'arrival_date',
          label: '到达时间 YYYY-MM-DD'
        },
        {
          type: 'input',
          name: 'mobile',
          label: '英国手机'
        }
      ]
    },
    {
      type: 'input',
      name: 'address',
      label: '收件地址'
    },
    { 
      contents: [
        {
          type: 'input',
          name: 'room',
          label: '房间号'
        },
        {
          type: 'input',
          name: 'city',
          label: '城市'
        },
        {
          type: 'input',
          name: 'postcode',
          label: '邮编'
        }
      ]
    },
  ]


  return (
    <>
    {display === 'text' && <Typography sx={{cursor: 'pointer'}} onClick={openDialog}>被枕</Typography>}
    {display === 'chip' && <Chip onClick={openDialog} label="被枕" /> }
    <RSDialog
      title="被枕"
      open={open}
      onClose={closeDialog}
      fullWidth
    >
      <RSForm data={BeddingPack} fields={form} onSave={handleUpdate} onDelete={handleDelete} />
    </RSDialog>
    </>
  )
}