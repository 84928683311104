import { createSlice } from '@reduxjs/toolkit'

/**
 * 员工列表
 * 用于员工选择器，员工管理
 */
export const slice = createSlice({
  name: 'staffs',
  initialState: {
    CNTeam: [],
    ukTeam: []
  },
  reducers: {
    setCNTeam:(state, action) => {
      state.CNTeam = action.payload
    },
    setUKTeam:(state, action) => {
      state.UKTeam = action.payload
    },
  },
})

export const { setCNTeam, setUKTeam } = slice.actions

export const staffsStates = state => state.staffs

export default slice.reducer

