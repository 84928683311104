
/* 员工管理页面 */

import { Grid, Stack, Typography } from "@mui/material";
import { getStaffs } from "api/admin/staff";
import StaffList from "components/staff/StaffList";
import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";
import RowStack from "ui/RowStack";
import StaffPerformance from "./StaffPerformance";
import StaffPermission from "./StaffPermission";

export default function StaffManagement() {

  const [staffs, setStaffs] = useState()
  const [staff, setStaff] = useState()

  const fetchData = async () => {
    const data = await getStaffs()
    if(data) setStaffs(data)
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Grid container>
      <Grid item xs={2}>
      
        <StaffList staffs={staffs} setStaff={setStaff} />
      </Grid>
      <Grid item xs={6} lg={4}>
        {staff &&
        <Stack>
        <RSCard
          title={staff.name}
          action={
            <RowStack>
               <StaffPermission staff={staff} />
             
            </RowStack>
          }
        >
          <Stack>
            <Typography>入职时间：{staff.begin}</Typography>
          </Stack> 
        </RSCard>
          <StaffPerformance staff={staff} />
        </Stack>
        }
      </Grid>
    </Grid>
  )
}
