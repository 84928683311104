import RSTable from "ui/RSTable";
import agentBookingsColumns from "./agentBookingsColumns";

/**
 * 渠道订单列表
 * @param { array } bookings 
 * @returns 
 */
export default function AgentBookingsTable({ bookings, setBookings }) {
  const columns = agentBookingsColumns(bookings, setBookings)
  return <RSTable data={bookings} columns={columns} />
}

