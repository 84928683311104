import { propertyStates } from "redux/propertySlice";
import { Stack, Typography, Divider } from "@mui/material";
import { useSelector } from "react-redux";

/**
 * 房源优惠活动
 */
export default function PropertyPromotions() {
  const { property } = useSelector(propertyStates)

  if(!property.promotions || property.promotions.length < 1) return null

  return (
    <Stack>
    {property.promotions.map((promotion, index) =>
      <Typography key={index}>
        <b><span style={{color: 'red'}}>{promotion.title}: </span></b>
        {promotion.content}
      </Typography>
    )}
      <Divider />
    </Stack>
    
  )
}