import RSSelector from "ui/RSSelector";

/**
 * 佣金状态选择器
 * @param { object } invoice 
 * @param { function } update
 * @returns 
 */
export default function InvoiceStateSelector({ invoice, update }) {

  const options = [
    {key: '0', value: "等支付"},
    {key: '1', value: "已支付", color: "success"},
    {key: '2', value: "已撤销", color: "error", variant: 'outlined'}
  ]

  const handleUpdate = async (option) => {
    update({ id: invoice.id, paid: option.key})
  } 

  return <RSSelector options={options} selectedKey={invoice.paid} onSelect={handleUpdate} />
}