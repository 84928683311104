/* 订单搜索 */

import { Stack, TextField } from "@mui/material";
import { useState } from "react";
import { searchBooking } from "api/admin/booking";
import RSTable from "ui/RSTable";
import BookingCustomer from "./table/BookingCustomer";
import Booking from "./Booking";
import BookingState from "./table/BookingState";


export default function BookingSearch() {

  const [keywords, setKeywords] = useState('')
  const [bookings, setBookings] = useState([])

  const handleChange = (e) => {
    setKeywords(e.target.value)
  }

  const handlePress = (e) => {
    if(e.key === 'Enter' && keywords) search()
  }

  const search = async () => {
    setBookings(null)
    const data = await searchBooking(keywords)
    if(data) setBookings(data)
  }

  const columns = [
    {
      title: '订单',
      render: (booking) => <Booking bookingID={booking.id} bookings={bookings} setBookings={setBookings} />
    },
    {
      title: '客户',
      render: (booking) => <BookingCustomer booking={booking} />
    },
    {
      title: '状态',
      render: (booking) => <BookingState booking={booking} />
    }
  ]

  return (
    <Stack>
      <TextField 
        fullWidth
        placeholder="订单ID, 客户ID, 姓名或Email"
        onChange={handleChange} 
        onKeyDown={handlePress}
      />
      <RSTable columns={columns} data={bookings} />
    </Stack>
  )
}