import { Chip, Skeleton, Stack, TextField } from "@mui/material";
import { searchAgent } from "api/agent";
import { useState } from "react";
import RSDialog from "ui/RSDialog";
import Agent from "../Agent";

export default function AgentSearch() {

  const [open, setOpen] = useState(false)
  const [agents, setAgents] = useState([])
  const [loading, setLoading] = useState(false)
  const [keywords, setKeywords] = useState('')

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  const handleChange = (e) => {
    setKeywords(e.target.value)
  }

  const handlePress = (e) => {
    if(e.key === 'Enter' && keywords) search()
  }

  const search = async () => {
    setLoading(true)
    const data = await searchAgent(keywords)
    if(data) setAgents(data)
    setLoading(false)
  }

  return (
    <>
    <Chip label="搜索渠道" onClick={openDialog} />
    <RSDialog
      title="搜索渠道"
      open={open}
      onClose={closeDialog}
    >
      <Stack>
        <TextField 
          value={keywords} 
          onChange={handleChange} 
          onKeyDown={handlePress}
          placeholder="渠道名或ID" 
          disabled={loading}
        />
        <Stack>
          {loading && <Skeleton />}
          {agents.map(agent => <Agent key={agent.id} agentName={agent.name} agentID={agent.id} />)}
        </Stack>
      </Stack>
    </RSDialog>
    </>
  )
}