import { call } from "api/core";

/* 数据整理 */

/**
 * 澳洲房源首图整理
 * @returns 
 */
export const AUAccommodationImg = async () => {
  return await call('admin/developer/dataRefine/AUAccommodation')
}
