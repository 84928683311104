
import { Box, Card, Chip, Stack, Typography } from "@mui/material";
import { openPropertyDialog } from "redux/propertySlice";
import AspectRatio from "ui/AspectRatio";

export default function PropertyGridItem({ property, reloadProperties }) {

  const viewProperty = () => {
    openPropertyDialog(property.id, reloadProperties)
  }

  return (
    <Card>
      <Stack spacing={1} onClick={viewProperty}>
        <AspectRatio ratio={2/3.2}>
          <img src={property.thumbnail} alt="" />
        </AspectRatio>
        <Stack direction="row" alignItems="center" sx={{p:1}}>
          <Stack spacing={0}>
            <Typography style={{fontSize: 16}}>{property.name}</Typography>
            <Typography variant="caption">{property.type}</Typography>
            <Typography variant="caption">{property.provider}</Typography>
          </Stack>
          <Box flexGrow={1} />
          {property.mark_hide === '1' && <Chip label="隐藏" />}
        </Stack>
      </Stack>
    </Card>
  )
}