import { Box, Divider, Skeleton, Stack, Typography } from "@mui/material";
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined';
import { deleteTransaction, getBookingTransactions, saveTransaction } from "api/admin/booking";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { countryIDtoCurrency } from "configs/country";
import { getAccountName } from "components/selector/AccountSelector";
import dayjs from "dayjs";
import RSCard from "ui/RSCard";
import { options as accountOptions } from "components/selector/AccountSelector";
import { simpleOptions as currencyOptions } from "components/selector/CurrencySelector";
import ISForm from "ui/ISForm";
import ISChipDialog from "ui/ISChipDialog";
import ISCardActions from "ui/ISCardActions";

const form = [
  {
    contents: [
      {
        ele: 'picker',
        name: 'account',
        label: '账户',
        options: accountOptions
      },
      {
        ele: 'date',
        name: 'date',
        label: '日期'
      },
    ]
  },
  {
    contents: [
      {
        ele: 'picker',
        name: 'currency_type',
        label: '币种',
        options: currencyOptions
      },
      {
        ele: 'input',
        name: 'amount',
        label: '金额',
        inputele: 'number'
      }
    ]
  },
  {
    ele: 'input',
    name: 'note',
    label: '备注'
  },
]

/**
 * 收支记录卡片
 */
const TransactionCard = forwardRef(({ booking }, ref) => {

  const transactionsRef = useRef()
  const reloadTransactions = () => transactionsRef.current.reload()

  useImperativeHandle(ref, () => ({
    reload(){ reloadTransactions()}
  }))

  return (
    <RSCard 
      title="财务"
      action={
        <ISCardActions>
          <AddTransaction booking={booking} reloadTransactions={reloadTransactions} />
        </ISCardActions>
      }
    >
      <Transactions bookingID={booking.id} ref={transactionsRef} />
    </RSCard>
  )
})

/**
 * 收支记录列表
 */
const Transactions = forwardRef(({ bookingID }, ref) => {

  const [transactions, setTransactions] = useState()

  const fetchTransactions = async () => {
    setTransactions(null)
    const data = await getBookingTransactions(bookingID)
    if(data) setTransactions(data)
    else setTransactions([])
  }

  useEffect(() => {
    fetchTransactions()
  }, [])

  useImperativeHandle(ref, () => ({
    reload: () => fetchTransactions()
  }))

  return (
    <Stack spacing={1}>

      {transactions && transactions.map(transaction => 
        <Transaction key={transaction.id} transaction={transaction} reloadTransactions={fetchTransactions} />
      )}
        
      {!transactions && <Skeleton />}

    </Stack>
  )
})

/**
 * 代付记录
 * @param {*} param0 
 * @returns 
 */
const Transaction = ({ transaction, reloadTransactions }) => {

  const handleSave = async (data) => {
    await saveTransaction(data)
    reloadTransactions()
  }

  const handleDel = async () => {
    await deleteTransaction(transaction.id)
    reloadTransactions()
  }

  return (
    <>
      <Stack spacing={0.5}>
        <Stack direction="row" alignItems="center" spacing={1}>
          {Number(transaction.amount) > 0 ? <MoveToInboxOutlinedIcon />:<OutboxOutlinedIcon color="warning" /> }
          <Typography>{getAccountName(transaction.account)}</Typography>
          <Box flexGrow={1} />
          <Typography>{countryIDtoCurrency(transaction.currency_type)}</Typography>
          {transaction.locked === '1' && <Typography>{transaction.amount}</Typography>}
          {
          transaction.locked === '0' &&
          <ISChipDialog 
            chipType="text"
            chipLabel={transaction.amount}
            dialogTitle="编辑收支记录"
            children={ <ISForm data={transaction} form={form} onSave={handleSave} onDelete={handleDel} />}
          />
          }
        </Stack>
        <Typography variant="caption">{dayjs(transaction.date).format('YY-MM-DD')} {transaction.note}</Typography>
        <Divider />
      </Stack>
    </>
  )
}

const AddTransaction =  ({ booking, reloadTransactions }) => {

  const ref = useRef()

  const transaction = {
    rent_id: booking.id,
    customer_id: booking.customer_id,
    currency_type: '1', 
    account: '1', 
    amount: 0,
    date: dayjs().format('YYYY-MM-DD')
  }

  const handleSave = async (data) => {
    ref.current.close()
    await saveTransaction(data)
    reloadTransactions()
  }

  return <ISChipDialog 
    ref={ref}
    chipLabel="收支"
    dialogTitle="新建收支记录"
    children={ <ISForm data={transaction} form={form} onSave={handleSave} />}
  />
}

export default TransactionCard