import { updateVideo, uploadVideo } from "api/admin/property-editor/media";
import { useRef } from "react";
import ISChipDialog from "ui/ISChipDialog";
import RSTable from "ui/RSTable";
import { Chip, IconButton } from "@mui/material";
import { UploadFileOutlined } from "@mui/icons-material";
import { startLoading, stopLoading } from "utils/layout";
import RSTextEditor from "ui/RSTextEditor";
import { fetchProperty } from "redux/propertySlice";
import { useSelector } from "react-redux";
import { propertyStates } from "redux/propertySlice";

/**
 * 视频编辑
 * @returns 
 */
export default function VideoEditor() {
  const { property } = useSelector(propertyStates)

  const delVideo = async () => {
    if(window.confirm('确定删除?')){

    }
  }

  const changeTitle = async (data) => {
    startLoading()
      await updateVideo(data)
      await fetchProperty(property.id)
    stopLoading()
  }

  const columns = [
    {
      title: '标题',
      render: item => <RSTextEditor item={item} entity="title" callback={changeTitle} />
    },
    {
      title: '文件',
      dataIndex: 'oss'
    },
    {
      title: '编辑',
      render: item => <Chip label="删除" onClick={()=>delVideo(item.id)} />
    }
  ]

  return (
    <ISChipDialog
      chipLabel="视频"
      dialogTitle="视频"
      fullWidth
      iconAction={<VideoUploadButton property={property} />}
      maxWidth="md"
    >
      <RSTable data={property.videos} columns={columns} />
    </ISChipDialog>
  )
}

/* 上传视频按钮 */
function VideoUploadButton() {

  const { property } = useSelector(propertyStates)
  const ref = useRef()
  const selectFile = () => {
    ref.current.click()
  }

  const handleUpload = async (e) => {
    startLoading()
    const file = e.target.files[0];
    const formData = new FormData()
    formData.append('accommodation_id', property.id)
    formData.append('file', file)
    await uploadVideo(formData)
    await fetchProperty(property.id)
    stopLoading()
  }

  return (
    <>
      <input
        accept="video/mp4"
        type="file"
        ref={ref}
        onChange={handleUpload}
        style = {{display:'none'}}
      />
      <IconButton onClick={selectFile}>
        <UploadFileOutlined />
      </IconButton>
    </>
  
  )
}