import { Chip } from "@mui/material";

/**
 * 支付状态
 * @param { int } state 
 * @returns 
 */
export default function PaymentState({state}) {
  switch(Number(state)) {
    case 0:
      return <Chip label="待支付" />
    case 1:
      return <Chip label="已支付" color="success" />
    case 2:
      return <Chip label="已取消" />
    case 3:
      return <Chip label="已代付" color="success" />
    case 4:
      return <Chip label="已退款" />
  }
}