import { IconButton, LinearProgress, Stack, Typography } from "@mui/material";
import FolderDeleteOutlinedIcon from '@mui/icons-material/FolderDeleteOutlined';
import { hasPermission } from "utils/layout";
import RSDialog from "ui/RSDialog";
import { useEffect, useState } from "react";
import { deleteAllPropertiesByProvider } from "api/developer";

/**
 * 删除所有供应商房源按钮
 * 供应商必须先隐藏
 * @param { obj } provider - 供应商
 * @param { func } onClose - 关闭窗口时运行 
 * @returns 
 */

export default function DeleteProviderAllPropertiesIconButton({ provider, onClose }) {

  const [open, setOpen] = useState(false)
  const [res, setRes] = useState('')

  const handleDelete = () => {
    if(window.confirm("确定删除所有房源？")) {
      setOpen(true)
      fetchData()
    }
  }

  const handleClose = () => {
    setOpen(false)
    onClose()
  }

  const fetchData = async () => {
    const data = await deleteAllPropertiesByProvider(provider.id)
    if(data) { setRes(data) }
  }

  useEffect(() => {
    if(open) fetchData()
  }, [res])

  return (
    <>
    {hasPermission('developer') && provider.hide === '1' &&
    <>
      <IconButton onClick={handleDelete}>
        <FolderDeleteOutlinedIcon />
      </IconButton>

      <RSDialog
        title="正在批量删除房源"
        onClose={handleClose}
        open={open}
      >
        <Stack> 
          <LinearProgress />
          <Typography>{res}</Typography>
        </Stack>
      </RSDialog>
    </>
    }
    </>
  )
}