import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import MenuItems from "../components/MenuItems";

const menu = {
  "text": "房源管理",
  "icon": HolidayVillageOutlinedIcon,
  "permission": "agent",
  "sub": [
    {
      "text": "英国",
      "path": "/gb/property"
    },
    {
      "text": "澳洲",
      "path": "/au/property"
    },
    {
      "text": "爱尔兰",
      "path": "/ie/property"
    },
    {
      "text": "中国香港",
      "path": "/hk/property"
    }
  ]
}

export default function PropertyMenu() {
  return <MenuItems menu={menu} />
}
