import { Close } from "@mui/icons-material"
import { Box, Chip, IconButton, Skeleton, Stack, TextField, Typography } from "@mui/material"
import { addGroupMember, getGroupBookings, removeGroupMember } from "api/admin/booking"
import Booking from "components/booking/Booking"
import { useEffect, useState } from "react"
import RSCard from "ui/RSCard"

export default function BookingGroup({ booking }) {

  const [bookings, setBookings] = useState()
  const [loading, setLoading] = useState(false)
  const [edit, setEdit] = useState(false)

  const fetchGroup = async () => {
    if(booking.group_id === '0') return
    setLoading(true)
    const data = await getGroupBookings(booking.group_id)
    if(data) setBookings(data)
    setLoading(false)
  }

  useEffect(()=>{
    if(booking.group_id) fetchGroup()
  }, [])

  const removeMember = async (booking) => {
    if(window.confirm('确定移出小组?')) {
      setLoading(true)
      await removeGroupMember(booking.id, booking.group_id)
      fetchGroup()
    }
  }

  const startEdit = () => setEdit(true)

  const handlePress = async (e) => {
    if(e.key === 'Enter') {
      const otherBookingID = e.target.value.trim()
      setEdit(false)
      if(otherBookingID === '') return
      setLoading(true)
      await addGroupMember(booking.id, booking.group_id, otherBookingID)
      fetchGroup()
    }
  }

  return (
    <RSCard 
      title="小组" 
      action={<Chip label="新建" onClick={startEdit} />}
    >
      <Stack>
        {edit && 
        <TextField 
          size="small"
          placeholder="订单ID"
          onKeyDown={handlePress}
        />
        }
        {loading && <Skeleton />}
        {
          bookings && bookings.map(booking => 
            <Stack direction="row" alignItems="center">
              <Booking bookingID={booking.id} bookings={bookings} setBookings={setBookings} bookingState={booking.state} />
              <Typography>{booking.customer_name}</Typography>
              <Box flexGrow={1} />
              <IconButton size="small" onClick={()=>removeMember(booking)}>
                <Close />
              </IconButton>
            </Stack>
          )
        }
      </Stack>
    </RSCard>
  )
}