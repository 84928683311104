import store from "redux/store";
import { setLoading } from "redux/loaderSlice";
import { showMessage } from 'redux/snackSlice'
import { setUser } from "redux/appSlice";
import { wecomSignOut } from "api/auth";

/**
 * 登出
 */
export async function signOut() {
  await wecomSignOut()
  store.dispatch(setUser(null))
  document.cookie = `51room_at=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  window.location.reload()
}

// *****************************************************************
// 全局 Loader，

export function startLoading() {
  store.dispatch(setLoading(true))
}

export function stopLoading() {
  store.dispatch(setLoading(false))
}


// *****************************************************************
// 全局 Reminder，

export function showSuccess(msg) {
  store.dispatch(showMessage('success', msg))
}

export function showFail(msg) {
  store.dispatch(showMessage('error', msg))
}

/**
 * 权限检查
 * @param { string } permission 
 * @returns 
 */
export function hasPermission(permission) {
  const permissions = store.getState().app.user.permissions
  if(permissions && permissions.includes(permission)){
    return true
  } else {
    return false
  }
}