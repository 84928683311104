import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";
import RSTable from "ui/RSTable";
import ISCardActions from "ui/ISCardActions";
import { filterProvider } from "api/admin/provider";
import ProviderEditor from "../../provider/ProviderEditor";
import ProviderProperty from "../../provider/ProviderProperty";
import HideSelector from "components/selector/HideSelector";
import PropertyTypeSelector from "components/selector/PropertyTypeSelector";
import LinkChip from "ui/LinkChip";
import ProviderAnalytics from "components/provider/action/ProviderAnalytics";
import { Chip } from "@mui/material";
import InsertLinkOutlinedIcon from '@mui/icons-material/InsertLinkOutlined';

/* 供应商列表 */
export default function ProviderList({ 
  countryID,
  noType
}) {

  const [providers, setProviders] = useState()

  const [filter, setFilter] = useState({
    country_id: countryID,
    hide: '0',
    type: '9'
  })

  const fetchProviders = async () => {
    const data = await filterProvider(filter)
    if(data) setProviders(data)
  }

  useEffect(() => {
    fetchProviders()
  }, [filter])

  const columns = [
    {
      title: '#',
      render: (item, index) => index+1
    },
    {
      title: 'ID',
      dataIndex: 'id'
    },
    {
      title: '供应商',
      render: item => <>{item.name + (item.hide==='1'?'(隐藏)':'')}</>
    },
    {
      title: '操作',
      render: (item) => 
        <ISCardActions>
          <ProviderEditor providerID={item.id} callback={fetchProviders} />
          <ProviderProperty provider={item} />
          <ProviderAnalytics providerID={item.id} />
          <LinkChip label="官网" url={item.website} />
          <LinkChip label="网盘" url={item.drive_folder_id} />
        </ISCardActions>
    },
    {
      title: '房源数据',
      render: (item) => 
        <ISCardActions>
          {item.mark_auto_update === '1' && <Chip variant="outlined" color="info" avatar={<InsertLinkOutlinedIcon />} label="已对接" />}
        </ISCardActions>
    }
  ]

  const handleSelectHide = (selectedOption) => {
    setFilter({
      ...filter,
      hide: selectedOption.key
    })
  }

  const handleSelectType = (selectedOption) => {
    setFilter({
      ...filter,
      type: selectedOption.key
    })
  }

  return (
    <RSCard
      title="供应商"
      action={
        <ISCardActions>
          <HideSelector hide={filter.hide} onSelect={handleSelectHide} />
          {!noType && <PropertyTypeSelector typeID={filter.type} onSelect={handleSelectType} />}
        </ISCardActions>
      }
    >
      <RSTable data={providers} columns={columns} />
    </RSCard>
  )
}