import { call } from "../core";

/* 供应商 */

/**
 * 搜索供应商
 * @param { string } keywords 
 * @returns 
 */
export const searchProvider = async (keywords) => {
  return await call('admin/provider/provider/searchProvider', {keywords:keywords})
}

/**
 * 待结算订单列表
 * @param { string } provider_id 
 * @returns 
 */
export const getUnpaidBookings = async (provider_id) => {
  return await call('admin/provider/commission/getUnpaidBookings/'+provider_id)
}

/**
 * 供应商佣金结算列表
 * @param {*} providerID 
 * @param {*} page 
 * @returns 
 */
export const getProviderInvoices = async (providerID, page) => {
  return await call('admin/provider/commission/getInvoices', {provider_id: providerID, page: page})
}


/* 更新 */
export const updateProvider = async (data) => {
  return await call('admin/provider/management/update', data)
}


/* 供应商信息 */
export const getProviders = async (data) => {
  return await call('admin/provider/list', data)
}

/* 供应商信息 */
export const getProvider = async (providerID) => {
  return await call('admin/property/provider/view/'+providerID)
}

/* 房源数据源 链接 */
export const zooplaLinks = async (data) => {
  return await call('london/provider-zoopla-links', data)
}

/* 房源数据源 增加链接 */
export const zooplaAddLink = async (data) => {
  return await call('london/provider-zoopla-add-link', data)
}

/* 房源数据源 增加链接 */
export const zooplaDelLink = async (data) => {
  return await call('london/provider-zoopla-del-link', data)
}

/* 筛选 供应商 */
export const filterProvider =  async (filter) => {
  return await call('admin/provider/management/filter', filter)
}

/* 供应商房源 */
export const getProviderProperties = async (filter, page) => {
  return await call('admin/provider/property/filter', {filter:filter, page:page})
}

/* 供应商添加房源 */
export const addProviderProperty = async (data) => {
  await call('admin/provider/property/add', data)
}