import { Grid } from "@mui/material";
import AnnualCompleteBookingChart from "./components/AnnualCompleteBookingChart";


export default function UKAnalytics() {

  return (
    <Grid container>
      <Grid item xs={4}>
        <AnnualCompleteBookingChart country='gb' />
      </Grid>
    </Grid>
  )
}
