const permissions = {
  booking: "订单管理",
  property: "房源管理",
  delProperty: "删除房源",
  agent: "渠道管理",
  finance: '财务管理',
  analytics: '业务数据',
  staff: "员工管理",
  developer: "开发权限"
}

export default permissions