import RSSelector from "ui/RSSelector";

/**
 * 渠道类型选择
 * @param { string } type 类型ID
 * @param { function } onSelect 点击事件
 * @returns 
 */
export default function AgentTypeSelector({ type, onSelect }) {

  const options = [
    {value:'重点渠道', key: '5'},
    {value:'机构渠道', key: '0'},
    {value:'个人渠道', key: '6'},
    {value:'内部渠道', key: '1'}

  ]

  /* 
    {value:'搜索引擎', key: '2'},
    {value:'社交媒体', key: '3'},
    {value:'口碑营销', key: '4'}
  */

  return <RSSelector 
          options={options} 
          selectedKey={type} 
          onSelect={onSelect}
        />
}