import RSSelector from "ui/RSSelector";

/**
 * 货币选择器
 * @param { string } currencyID
 * @param { function } onSelect 
 * @returns 
 */
export default function CurrencySelector({ currencyID, onSelect }) {

  const options = [
    {key: "0", value: "GBP £"},
    {key: "9", value: "EUR €"},
    {key: "4", value: "AUD $"},
    {key: "6", value: "JPY ¥"},
    {key: "11", value: "HKD $"},
    {key: "1", value: "CNY ¥"},
  ]

  return <RSSelector selectedKey={currencyID} options={options} onSelect={onSelect} />
}

/* 用于收支记录的选项 */
export const simpleOptions = [
  {key: "0", value: "GBP £"},
  {key: "1", value: "CNY ¥"}
]

/**
 * 简单货币选择器
 * 实际收支用到的有人民币和英镑
 * @param { string } currencyID
 * @param { function } onSelect
 * @returns 
 */
export const SimpleCurrencySelector = ({ currencyID, onSelect }) => {
  return <RSSelector selectedKey={currencyID} options={simpleOptions} onSelect={onSelect} />
}