import { Dialog as MuiDialog, DialogContent, DialogTitle, IconButton, Typography } from "@mui/material"
import { Close } from '@mui/icons-material'
import { forwardRef, useImperativeHandle, useState } from "react"
import RowStack from "./RowStack"
import Spacer from "./Spacer"

/**
 * 基础组件 窗口组件
 * @param { string } title 窗口标题
 * @param { function } onOpen 打开窗口时候的动作
 * @param { function } onClose 关闭窗口时候的动作
 * @param { string } maxWidth sm | md | lg 窗口最大宽度
 * @param { boolean } noContentPadding 内容边框留白
 * @returns 
 */

const Dialog = forwardRef(({
  title,
  onOpen,
  onClose,
  noContentPadding,
  children,
  ...rest
}, ref) => {
 
  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
    if(onOpen) onOpen()
  }

  const closeDialog = () => {
    setOpen(false)
    if(onClose) onClose()
  }

  useImperativeHandle(ref, () => ({
    open() {
      openDialog()
    },
    close() {
      closeDialog()
    }
  }))

  return (
    <MuiDialog
      open={open} 
      onClose={closeDialog}
      {...rest}
    >
      <DialogTitle 
        sx={{ 
          background: '#eee', 
          paddingRight: '1rem', 
          paddingY: '0.5rem',
          "@media print": {
            display: 'none'
          }
        }}
      >
        <RowStack>
          <Typography variant="h5">{title}</Typography>
          <Spacer />
          <IconButton onClick={closeDialog}>
            <Close />
          </IconButton>
        </RowStack>
      </DialogTitle>

      <DialogContent dividers sx={{padding: noContentPadding?0:'16px 24px'}}>
        {children}
      </DialogContent>
    </MuiDialog>
  )
})

Dialog.displayName = "Dialog"
export default Dialog
