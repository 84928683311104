
import { getProvider, updateProvider } from "api/admin/provider";
import { options as countryOptions } from "components/selector/PropertyCountrySelector";
import { useRef, useState } from "react";
import ISChipDialog from "ui/ISChipDialog";
import ISForm from "ui/ISForm";
import { startLoading, stopLoading } from "utils/layout";

/**
 * 供应商信息编辑器
 * @param {*} privderID 
 * @returns 
 */
export default function ProviderEditor({ providerID, callback }) {

  const [provider, setProvider] = useState()

  const ref = useRef()

  const handleOpen = async () => {
    startLoading()
    const data = await getProvider(providerID)
    if(data) setProvider(data)
    stopLoading()
  }

  const form = [
    {
      contents: [
        {
          ele: 'picker',
          name: 'country_id',
          label: '国家',
          options: countryOptions
        },
        {
          ele: 'input',
          name: 'name',
          label: '名称'
        },
      ]
    },
    {
      contents: [
        {
          ele: 'input',
          name: 'website',
          label: '官网'
        },
        {
          ele: 'input',
          name: 'drive_folder_id',
          label: '网盘'
        },
      ]
     
    },
    {
      contents: [
        {
          ele: 'checkbox',
          name: 'hide',
          label: '取消合作'
        },
        {
          ele: 'checkbox',
          name: 'mark_student_accommodation',
          label: '学生公寓'
        }
      ]
    },
    {
      ele: 'note',
      name: 'process',
      label: '内部备注'
    },
    {
      ele: 'note',
      name: 'process_web',
      label: '外部备注'
    },
  ]

  const handleSave = async (data) => {
    await updateProvider(data)
    if(callback) callback(data)
    ref.current.close()
  }

  return (
    <ISChipDialog 
      ref={ref}
      chipLabel="编辑"
      dialogTitle="编辑供应商信息"
      fullWidth
      maxWidth="md"
      onOpen={handleOpen}
    >
      {provider && <ISForm data={provider} form={form} onSave={handleSave} /> }
    </ISChipDialog>
  )
}