import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import MenuItems from "../components/MenuItems";

const menu = {
  "text": "渠道管理",
  "icon": HandshakeOutlinedIcon,
  "permission": "agent",
  "sub": [
    {
      "text": "渠道佣金",
      "path": "/agent/management"
    },    
    {  
      "text": "未结订单",
      "path": "/agent/unpaid-bookings"
    },
  ]
}

export default function AgentMenu() {

  return <MenuItems menu={menu} />
}