import { Chip, Grid } from "@mui/material"
import { useState } from "react"
import RSDialog from "ui/RSDialog"
import RSCard from "ui/RSCard"
import { updateBooking } from "api/admin/booking"
import BookingTableForSelector from "components/booking/table/BookingTableForSelector"

/**
 * 选择返佣相关订单
 * @param { string } invoiceID
 * @param { array } bookings 返佣订单
 * @param { function } setBookings
 * @param { array } unpaidBookings 未结订单
 * @param { function } setUnpaidBookings
 * @returns 
 */

export default function AgentBookingsSelector({ invoiceID, bookings, setBookings, unpaidBookings, setUnpaidBookings }) {

  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  // 选中一个订单 左栏移动到右栏
  const selectABooking = (booking) => {
    setBookings([booking, ...bookings])
    setUnpaidBookings(unpaidBookings.filter(b => b.id !== booking.id))
    updateBooking({
      id: booking.id,
      agent_invoice_id: invoiceID
    })
  }

  // 选中一个订单 右栏移动到左栏
  const unSelectABooking = (booking) => {

    setUnpaidBookings([booking, ...unpaidBookings])
    setBookings(bookings.filter(b => b.id !== booking.id))

    updateBooking({
      id: booking.id,
      agent_invoice_id: 0
    })
   
  }

  return (
    <>
    <Chip label="筛选订单" onClick={openDialog} />
    <RSDialog
      title="渠道佣金 - 筛选订单"
      open={open}
      onClose={closeDialog}
      fullScreen
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <RSCard title="待结算订单">
            <BookingTableForSelector bookings={unpaidBookings} setBookings={setUnpaidBookings} side="left" onSelect={selectABooking} />
          </RSCard>
        </Grid>
        <Grid item xs={6}>
          <RSCard title="返佣关联订单">
            <BookingTableForSelector bookings={bookings} setBookings={setBookings} side="right" onSelect={unSelectABooking} />
          </RSCard>
        </Grid>
      </Grid>
    </RSDialog>
    </>
  )
}