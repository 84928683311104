import { Stack } from "@mui/material";
import RSCard from "ui/RSCard";
import CustomerBookings from "./CustomerBookings";
import CustomerID from "./CustomerID";

export default function CustomerInfo({ booking, closeBookingDialog }) {

  return (
    <RSCard title={"客户 - "+booking.customer_id}>
      <Stack>
        <Stack direction="row">
          <CustomerBookings customerID={booking.customer_id} />
          <CustomerID booking={booking} closeBookingDialog={closeBookingDialog} />
        </Stack>
      </Stack>
    </RSCard>
  )
}