import ISForm from "ui/ISForm";
import { deletePayment, savePayment } from "api/payment";

/**
 * 英镑代付表单
 * @param {obj} data
 * @param {func} callback
 * @returns 
 */
export default function PaymentForm({ 
  payment, 
  callback
}) {

  const form = [
    {
      ele: Number(payment.status) === 0 ? 'input' : 'text',
      name: 'amount',
      label: '金额',
      type: 'number',
      required: true
    },
    {
      ele: 'input',
      name: 'note',
      label: '备注',
    }
  ]

  const handleSave = async (formData) => {
    await savePayment(formData)
    if(callback) callback()
  }

  const handleDelete = async () => {
    await deletePayment(payment.id)
    if(callback) callback()
  }

  return (<ISForm data={payment} form={form} onSave={handleSave} onDelete={Number(payment.status) === 0 ? handleDelete : null}/>)
}