import { addANewCommission } from "api/finance";
import ProviderSearch from "components/provider/ProviderSearch";
import { startLoading, stopLoading } from "utils/layout";

export default function AddANewCommission({ callback }) {

  const addNew = async (provider) => {
    startLoading()
    const data = await addANewCommission(provider.id)
    if(data) callback(data)
    stopLoading()
  }

  return (<ProviderSearch label="新建" callback={addNew} />)
}