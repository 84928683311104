import { call } from "./core";

/* 客户 */

/**
 * 客户信息
 * @param {*} customerID
 */
export const getCustomer = async (customerID) => {
  return await call('admin/customer/getCustomer/'+customerID)
}

/**
 * 客户订单
 * @param {*} customerID 
 * @param {*} page 
 * @returns 
 */
export const getCustomerBookings = async (customerID, page) => {
  return await call('admin/customer/getCustomerBookings/'+customerID+'/'+page)
}