import { Box, Chip, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { getUnpaidCommissionSummary } from "api/finance";
import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";
import RSSelector from "ui/RSSelector";
import { countryIDtoCurrency } from "configs/country";

export default function CommissionSummary({ setCurrency, year, state, setState }) {

  const [sum, setSum] = useState()
  const [loading, setLoading] = useState(false)

  const fetchData = async () => {
    if(year) {
      setLoading(true)
      const data = await getUnpaidCommissionSummary(year, state)
      if(data) setSum(data)
      setLoading(false)
    }
  }

  useEffect(() => {
    setSum(null)
    fetchData()
  }, [year, state])

  return (
    <RSCard title={"应收佣金总计-"+year} action={<StateSelector state={state} setState={setState} />}> 
      <Stack>
      { loading && <Skeleton />}
      {
        sum && 
        <Stack>
          <SumChip amount={sum.GBP} currency={0} setCurrency={setCurrency} />
          <SumChip amount={sum.EUR} currency={9} setCurrency={setCurrency} />
          <SumChip amount={sum.AUD} currency={4} setCurrency={setCurrency} />
          <SumChip amount={sum.JPY} currency={6} setCurrency={setCurrency} />
          <SumChip amount={sum.HKD} currency={11} setCurrency={setCurrency} />
          <SumChip amount={sum.CNY} currency={1} setCurrency={setCurrency} />
        </Stack>
      }
      </Stack>
    </RSCard>
  )
}

function SumChip({ currency, setCurrency, amount }) {
  return (
    <Stack>
      <Stack direction="row">
        <Chip 
          label={countryIDtoCurrency(currency)} 
          onClick={()=>setCurrency(currency)} 
        />
        <Box flexGrow={1} />
        <Typography>{amount}</Typography>
      </Stack>
      <Divider />
    </Stack>
  )
}

function StateSelector({ state, setState }) {

  const options = [
    {key: '0', value: '未支付'},
    {key: '1', value: '已支付'}
  ]

  const updateState = (option) => {
    setState(option.key)
  }

  return <RSSelector selectedKey={state} options={options} onSelect={updateState} />
}