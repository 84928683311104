import { createSlice } from '@reduxjs/toolkit'

export const slice = createSlice({
  name: 'sider',
  initialState: {
    siderOpen: true,
    siderMenuSelected: null,
    siderItemSelected: '后台首页',
  },
  reducers: {
    setSiderOpen:(state, action) => {
      state.siderOpen = action.payload
    },
    setSiderMenuSelected:(state, action) => {
      state.siderMenuSelected = action.payload
    },
    setSiderItemSelected:(state, action) => {
      state.siderItemSelected = action.payload
    },
  },
})

export const { 
  setSiderOpen,
  setSiderMenuSelected,
  setSiderItemSelected,
} = slice.actions

export const siderStates = state => state.sider

export default slice.reducer