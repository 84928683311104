import RSSelector from "ui/RSSelector";

/**
 * 国家选择器
 * @param { string } currencyID
 * @param { function } onSelect 
 * @returns 
 */
export default function CountrySelector({ currencyID, onSelect }) {

  const options = [
    {key: null, value: "全部国家"},
    {key: "0", value: "英国"},
    {key: "9", value: "爱尔兰"},
    {key: "4", value: "澳洲"},
    {key: "6", value: "日本"},
    {key: "11", value: "中国香港"},
  ]

  return <RSSelector selectedKey={currencyID} options={options} onSelect={onSelect} />
}