import { Chip } from '@mui/material'
import Link from "./Link"

/**
 * Chip 形式的链接
 * @param {*} label
 * @param {*} href 
 * @param {*} chipProps 继承 MUI Chip 属性
 * @returns 
 */
export default function ChipLink({
  href,
  label,
  chipProps
}) {
  return (
    <Link href={href}>
      <Chip label={label} {...chipProps} />
    </Link>
  )
}