import { Chip } from "@mui/material";


export default function CSVExportChip({ label="导出CSV", filename="数据", data = [
  // 在这里添加你的表格数据
  { name: 'John Doe', age: 30, gender: 'Male' },
  { name: 'Jane Doe', age: 25, gender: 'Female' },
  // ...
] }) {


  const download = () => {
    const csvData = convertToCsv(data);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename+'.csv');

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  };

  const convertToCsv = (data) => {
    const header = Object.keys(data[0]).join(',');
    const rows = data.map((row) => Object.values(row).join(','));
    return `${header}\n${rows.join('\n')}`;
  };

  return <Chip label={label} onClick={download}  />
}