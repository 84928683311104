import { Divider, Stack, Typography } from "@mui/material";
import { getProvider } from "api/admin/provider";
import { useRef, useState } from "react";
import ISChipDialog from "ui/ISChipDialog";
import ProviderEditor from "./ProviderEditor";
import RowStack from "ui/RowStack";
import Spacer from "ui/Spacer";
import ProviderSearch from "./ProviderSearch";
import ExternalChipLink from "components/ui/ExternalChipLink";
import ExpandableTypography from "ui/ExpandableTypography";

/**
 * 供应商选择器
 */
export default function ProviderSelector({ label, providerID, callback }) {

  const ref = useRef()
  const [provider, setProvider] = useState()

  const fetchProvider = async () => {
    const data = await getProvider(providerID)
    if(data) setProvider(data)
  }

  const changeProvider = (provider) => {
    setProvider(provider)
    if(callback) callback(provider)
  }
 
  return (
    <ISChipDialog
      ref={ref}
      chipLabel={label?label:"房东"}
      dialogTitle="房东/供应商"
      fullWidth
      onOpen={fetchProvider}
    >
      { provider &&
      <Stack>

        <RowStack>
          <Typography variant="h6">{provider.name}</Typography>
          <Spacer />
          <ProviderEditor providerID={providerID} callback={fetchProvider} />
          {callback && <ProviderSearch label="换供应商" callback={changeProvider} />}
          <ExternalChipLink label="官网" url={provider.website} />
          <ExternalChipLink label="网盘" url={provider.drive_folder_id} />
        </RowStack>

        <Divider />

        <Typography variant="h6">内部备注</Typography>
        <ExpandableTypography text={provider.process} />

        <Divider />

        <Typography variant="h6">预定流程</Typography>
        <ExpandableTypography text={provider.process_web} />
      </Stack>
      }
    </ISChipDialog>
  )
}