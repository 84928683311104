import bookingTableColumns from "components/booking/table/bookingTableColumns"
import InvoiceChip from "components/provider/commission/InvoiceChip"

export default function agentBookingsColumns(bookings, setBookings) {
  const columns = bookingTableColumns(bookings, setBookings)

  columns.push(
    {
      title: '佣金 ID',
      render: (booking) => <InvoiceChip invoiceID={booking.commission_invoice_id} paid={booking.paid} />
    }
  )

  return columns
}