import React, { useState } from 'react';
import { Box, Chip, Stack, Typography } from '@mui/material';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import Link from './Link';

/**
 * 可以收缩的文字段落
 * @param {*} param0 
 * @returns 
 */
const ExpandableTypography = ({ 
  text,
  limit = 150
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Box sx={{ overflow: 'hidden' }}>
      {text && text !== "" &&
      <Stack>
      
        <Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>
          {expanded ? text : text.slice(0, limit)}
          {(!expanded && text.length>limit && "......")}
        </Typography>
        {text.length > 100 && (
          <Link onClick={toggleExpanded} variant='outlined'>
            {expanded ?
            <Chip label="收起" avatar={<ArrowCircleUpIcon />} />
            :
            <Chip label="展开" avatar={<ArrowCircleDownIcon />} />
            }
          </Link>
        )}
      </Stack>
      }
    </Box>
  );
};

export default ExpandableTypography;