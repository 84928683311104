// 订单信息展示 Helper

import { countryIDtoCurrency, countryIDtoLeaseUnit } from "./country"

/**
 * 租金 GBP £812/周起
 * @param {*} price 
 * @param {*} countryID 
 */
export function bookingPrice(price, countryID, simple = 'true') {
  let str = `${countryIDtoCurrency(countryID, simple)}${formatPrice(price)}/${countryIDtoLeaseUnit(countryID)}起`
  return str
}

/**
 * 租期信息 2024-09-01开始 52周
 * @param {*} date 
 * @param {*} lease 
 * @param {*} countryID 
 * @returns 
 */
export function bookingLease(date, lease, countryID) {
  let str = ""
  if(date !== "0000-00-00") str += `${date} 开始 `
  if(Number(lease) > 0) str += `${lease}${countryIDtoLeaseUnit(countryID)}`
  return str
}

/**
 * 价格格式化 - 简洁显示
 * 如123.00显示为123，123.40显示为123.4，123.45显示为123.45
 * @param {*} priceString 
 * @returns 
 */
export function formatPrice(priceString) {
  const priceNumber = parseFloat(priceString);
  const roundedNumber = Math.round(priceNumber * 100) / 100; // 将价格四舍五入到两位小数

  // 检查小数部分是否为零
  const isInteger = (roundedNumber % 1) === 0;

  // 如果小数部分为零，则只显示整数部分
  if (isInteger) {
    return roundedNumber.toFixed(0); // 将数值格式化为整数
  } else {
    return roundedNumber.toFixed(2); // 将数值格式化为保留两位小数
  }
}