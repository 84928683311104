/* 楼层 */

import { Typography } from "@mui/material";
import ApartmentRoundedIcon from '@mui/icons-material/ApartmentRounded';
import RowStack from "ui/RowStack";

export default function Floor({ number }){
  return (
    <RowStack>
      <ApartmentRoundedIcon />
      <Typography>{number} 楼</Typography>
    </RowStack>
  )
}