import { Chip } from "@mui/material";
import PropertyDialog from "./PropertyDialog";
import { useRef } from "react";


export default function PropertyClickableID({ propertyID }) {

  const ref = useRef()

  const openDialog = () => ref.current.open()

  return (
    <>
    <Chip label={propertyID} onClick={openDialog} />
    <PropertyDialog propertyID={propertyID} ref={ref} />
    </>
  )
}