/* 供应商 API */

import { call } from "api/core";

/**
 * 供应商 分析数据
 * 房源数量, 近期成单数量
 * @param {*} id Provider ID
 * @returns 
 */
export const getAnalyticsData = async (id) => {
  return await call('admin/provider/management/analytics/'+id)
}