import { Skeleton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";

/**
 * 员工列表
 * @returns 
 */
export default function StaffList({ staffs, setStaff }) {

  const [CNTeam, setCNTeam] = useState([])
  const [UKTeam, setUKTeam] = useState([])

  useEffect(() => {
    if(staffs) {
      setCNTeam(staffs.filter(staff => staff.office === '中国办'))
      setUKTeam(staffs.filter(staff => staff.office === '英国办'))
    }
  }, [staffs])

  return (
    <Stack>
      <RSCard title="中国办">
        <Stack spacing={1}>
          { CNTeam.length === 0 && <Skeleton /> }
          {
            CNTeam.map(staff => 
            <Typography key={staff.id} onClick={()=>setStaff(staff)} sx={{cursor:'pointer'}}>{staff.name} - {staff.name_en}</Typography>  
            )
          }
        </Stack>
      </RSCard>

      <RSCard title="英国办">
        <Stack spacing={1}>
          { UKTeam.length === 0 && <Skeleton /> }
          {
            UKTeam.map(staff => 
            <Typography key={staff.id} onClick={()=>setStaff(staff)} sx={{cursor:'pointer'}}>{staff.name} - {staff.name_en}</Typography>  
            )
          }
        </Stack>
      </RSCard>
    </Stack>
  )
}