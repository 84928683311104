import { Alert, Snackbar } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import { setOpen, snackStates } from '../../redux/snackSlice'

/* 提醒 */
export default function RSSnackbar() {
  const {open, message, severity} = useSelector(snackStates)
  const dispatch = useDispatch()
  const handleClose = () => dispatch(setOpen(false))
  
  return (
  <Snackbar
    anchorOrigin={{ vertical: 'top', horizontal: 'center'}}
    open={open}
    autoHideDuration={6000}
    onClose={handleClose}
  >
    <Alert severity={severity} sx={{ width: '100%' }} variant="filled" onClose={handleClose}>
      {message}
    </Alert>
  </Snackbar>
  )
}