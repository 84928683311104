import { createSlice } from '@reduxjs/toolkit'
import store from './store'
import { getProperty } from 'api/property'

/* 房源展示 */
export const slice = createSlice({
  name: 'property',
  initialState: {
    open: false, // 窗口开关
    property: null,
    reloadList: null, // 刷新列表方法
  },
  reducers: {
    setOpen:(state, action) => {
      state.open = action.payload
    },
    setProperty:(state, action) => {
      state.property = action.payload
    },
    setReloadList:(state, action) => {
      state.reloadList = action.payload
    },
  },
})

export const { 
  setOpen,
  setProperty,
  setReloadList
} = slice.actions
export const propertyStates = state => state.property
export default slice.reducer

/**
 * 打开房源窗口并读取房源数据
 * @param {*} propertyID 
 */
export async function openPropertyDialog(propertyID, reload = null) {
  store.dispatch(setOpen(true))
  if(reload) store.dispatch(setReloadList(reload))
  fetchProperty(propertyID)
}

/**
 * 关闭房源窗口
 */
export function closePropertyDialog() {
  store.dispatch(setOpen(false))
  store.dispatch(setProperty(null))
  store.dispatch(setReloadList(null))
}

/**
 * 获取房源数据
 * @param {*} propertyID 
 */
export async function fetchProperty(propertyID) {
  const property = await getProperty(propertyID)
  if(property) store.dispatch(setProperty(property))
}
