import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import AuthCard from "./components/AuthCard";
import { LinearProgress } from "@mui/material";
import { wecomCallback } from "api/auth";

export default function WeCom()
{
  const [searchParams] = useSearchParams();

  const code = searchParams.get('code')
  const appid = searchParams.get('appid')
  const state = searchParams.get('state')

  const nav = useNavigate()

  const signIn = async () => {
    const data = await wecomCallback({code: code, appid: appid, state: state})
    if(data) {
      nav('/')
    } else {
      nav('/auth/signIn')
    }
  }

  useEffect(() => {
    signIn()
  }, [])

  return (
    <AuthCard>
      <LinearProgress />
    </AuthCard>
  )
}