import { Stack, Typography } from "@mui/material";
import RSCard from "ui/RSCard";
import StaffSelector from "./StaffSelector";
import dayjs from "dayjs";

/**
 * 顾问
 * @param {*} booking
 * @param {*} setBooking
 * @returns 
 */
export default function AdminInfo({ booking, setBooking }) {

  return (
    <RSCard title="顾问" action={booking && <StaffSelector booking={booking} setBooking={setBooking} />}>
      { booking &&
      <Stack spacing={0}>
        <Typography>订单生成：{dayjs(booking.create_date).format('YYYY-MM-DD')}</Typography>
        <Typography>订单完成：{booking.complete_date}</Typography>
      </Stack>
      }
    </RSCard>
  )
}