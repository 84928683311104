import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { MenuItem } from "../components/MenuItems";

export default function BookingMenu() {

  const menu = {
    text: '订单管理',
    icon: DescriptionOutlinedIcon,
    path: "/booking/new-bookings"
  }

  return <MenuItem menu={menu} />
}