import { useState } from "react";
import { Stack } from "@mui/material";
import Dialog from "ui/Dialog";
import Image from "ui/Image";
import { propertyStates } from "redux/propertySlice";
import { useSelector } from "react-redux";

/**
 * 房源图片
 * @returns 
 */
export default function PropertyImgs() {
  const { property } = useSelector(propertyStates)

  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
    {
      property.imgs && property.imgs.length > 0 && 
        <Image
          ratio={2/3.2}
          src={property.imgs[0].url+"?x-oss-process=style/desktop_property_list"} 
          alt={property.name} 
          onClick={openDialog}
        />
    }

    <Dialog
      title="房源图片"
      open={open}
      onClose={closeDialog}
      noContentPadding
    >
    {
      property.imgs && property.imgs.length > 0 && 
      <Stack spacing={0.5}>
      {     
        property.imgs.map(img => <img src={img.url+"?x-oss-process=style/desktop_property_list"} key={img.id} alt={property.name} />)
      }
      </Stack>
    }
    </Dialog>
    </>
  )
}