import RSSelector from "ui/RSSelector";

export const options = [
  {key: "0", value: "英国"},
  {key: "4", value: "澳洲"},
  {key: "9", value: "爱尔兰"},
  {key: "6", value: "日本"},
  {key: "11", value: "中国香港"},
]

/**
 * 国家选择器
 * @param { string } countryID
 * @param { function } onSelect 
 * @returns 
 */
export default function PropertyCountrySelector({ countryID, onSelect }) {
  return <RSSelector selectedKey={countryID} options={options} onSelect={onSelect} />
}