import StaffComments from "./StaffComments";
import { addBookingComment, delBookingComment, getBookingComments } from "api/admin/booking";

export default function BookingComments({ bookingID }) {

  return (
    <StaffComments 
      getComments={()=>getBookingComments(bookingID)}
      addComment={(message)=>addBookingComment({tid: bookingID, message: message})}
      delComment={(commentID)=>delBookingComment(commentID)}
    />
  )
}