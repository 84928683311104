/* 系统用户菜单 */
import { useState } from 'react'
import { Divider, IconButton, Menu, MenuItem } from '@mui/material'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import { signOut } from 'utils/layout';
import { useNavigate } from 'react-router-dom';

export default function UserMenu() {

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const nav = useNavigate()


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const navTo = (path) => {
    handleClose()
    nav(path)
  }

  return (
    <>

    <IconButton  onClick={handleClick}>
      <AccountCircleOutlinedIcon />
    </IconButton>

    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
    >
      <MenuItem dense onClick={()=>navTo('my/performance')}>我的业绩</MenuItem>
      <Divider />
      <MenuItem onClick={signOut} dense>登出系统</MenuItem>
    </Menu>
    
    </>
  )
}