import { Chip, Typography } from "@mui/material"
import { getAgentInvoices, updateAgentInvoice } from "api/agent"
import dayjs from "dayjs"
import { useState } from "react"
import RSDialog from "ui/RSDialog"
import RSTable from "ui/RSTable"
import RSTextEditor from "ui/RSTextEditor"
import CurrencySelector from "components/selector/CurrencySelector"
import AgentInvoiceBookings from "./AgentInvoiceBookings"

/**
 * 渠道佣金管理
 * @param {*} agentID
 * @param {*} bookings 
 * @param {*} setBookings
 * @returns 
 */
export default function AgentCommission({ agentID, bookings, setBookings }) {

  const [open, setOpen] = useState(false)
  const [invoices, setInvoices] = useState([])

  const loadInvoices = async () => {
    const data = await getAgentInvoices(agentID)
    if(data) setInvoices(data)
  }

  const openDialog = () => {
    setOpen(true)
    loadInvoices()
  }

  const closeDialog = () => {
    setOpen(false)
    setInvoices([])
  }

  return (
    <>
    <Chip label="返佣" onClick={openDialog} />
    <RSDialog
      title="渠道佣金支付记录"
      open={open}
      onClose={closeDialog}
      maxWidth="md"
      fullWidth
    >
      <AgentInvoices agentID={agentID} invoices={invoices} loadInvoices={loadInvoices} bookings={bookings} setBookings={setBookings} />
    </RSDialog>
    </>
  )
}

/**
 * 渠道佣金支付记录列表
 * @param { array } invoices 
 * @returns 
 */
function AgentInvoices({agentID, invoices, loadInvoices, bookings, setBookings}) {

  const updateCurrency = (invoice, option) => {
    updateAgentInvoice({id: invoice.id, currency: option.key})
  }

  const columns = [
    {
      title: '结算 ID',
      render: (invoice) => <Typography>AL-{invoice.id}</Typography>,
      width: '10%'
    },
    {
      title: '日期',
      width: '15%',
      render: (invoice) => 
        <RSTextEditor 
          item={invoice} 
          entity="date" 
          callback={updateAgentInvoice}
        />
    },
    {
      title: '货币',
      render: (invoice) => <CurrencySelector currencyID={invoice.currency} onSelect={option => updateCurrency(invoice, option)} />
    },
    {
      title: '金额',
      width: '20%',
      render: (invoice) =>
        <RSTextEditor 
          item={invoice} 
          entity="amount" 
          callback={updateAgentInvoice}
        />
    },
    {
      title: '备注',
      width: '40%',
      render: (invoice) => 
        <RSTextEditor 
          item={invoice} 
          entity="note" 
          callback={updateAgentInvoice}
        />
    },
    {
      title: <AddNewInvoiceChip agentID={agentID} loadInvoices={loadInvoices} />,
      render: (invoice) => <AgentInvoiceBookings invoiceID={invoice.id} unpaidBookings={bookings} setUnpaidBookings={setBookings} />
    }
  ]

  return (
    <RSTable data={invoices} columns={columns} />
  )
}

/**
 * 按钮 新建渠道佣金记录
 * @returns 
 */
function AddNewInvoiceChip({ agentID, loadInvoices}) {

  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)
    const newInvoice = {
      date: dayjs().format('YYYY-MM-DD'),
      agent_id: agentID
    }
    await updateAgentInvoice(newInvoice)
    await loadInvoices()
    setLoading(false)
  }
  return (<Chip label="新建" disabled={loading} onClick={handleClick} />)
}