/* 房源编辑器 各类编辑器合集 */

import { Stack } from "@mui/material";
import PropertyComments from "components/comment/PropertyComments";
import { useSelector } from "react-redux";
import { propertyStates } from "redux/propertySlice";
import RowStack from "ui/RowStack";
import FrontendLink from "./FrontendLink";
import OfficialLink from "./OfficialLink";
import PropertyCitySelector from "./PropertyCitySelector";
import PropertyProviderSelector from "./PropertyProviderSelector";
import PropertyHideToggle from "./PropertyHideToggle";
import PropertyInfoEditor from "./PropertyInfoEditor";
import PromotionEditor from "./PromotionEditor";
import RoomTypeAndPriceEditor from "./RoomTypeAndPriceEditor";
import VideoEditor from "./VideoEditor";
import MediaEditor from "./MediaEditor";
import FloorPlanEditor from "./FloorPlanEditor";
import PropertyPhotoEditor from "./PropertyPhotoEditor";
import PropertyDeleteButton from "./PropertyDeleteButton";

export default function PropertyEditor() {

  const { property } = useSelector(propertyStates)

  return (
    <Stack>
      <RowStack>
        <FrontendLink />
        <PropertyCitySelector />
        <PropertyProviderSelector />  
        <OfficialLink />
        <PropertyHideToggle />
        <PropertyDeleteButton />
      </RowStack>

      <RowStack>
        <PromotionEditor />
        <PropertyInfoEditor />
        <PropertyPhotoEditor />
        <MediaEditor />
        <VideoEditor />
        <FloorPlanEditor />
        <RoomTypeAndPriceEditor />
      </RowStack>
      <PropertyComments propertyID={property.id} />
    </Stack>
  )
}