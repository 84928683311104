import { useEffect, useRef, useState } from "react";
import ISChipDialog from "ui/ISChipDialog";
import { Button, IconButton, Stack } from "@mui/material";
import { UploadOutlined } from "@mui/icons-material";
import { startLoading, stopLoading } from "utils/layout";
import { deleteFloorPlan, getFloorPlan, uploadFloorPlan } from "api/property";
import { useSelector } from "react-redux";
import { fetchProperty, propertyStates } from "redux/propertySlice";

/**
 * 房型图编辑器
 * @returns 
 */
export default function FloorPlanEditor() {

  const { property } = useSelector(propertyStates)

  const [items, setItems] = useState()
  const ref = useRef()

  
  const fetchImgs = async () => {
    const data = await getFloorPlan(property.id)
    if(data) setItems(data)
  }

  const handleClick = () => {
    ref.current.click()
  }

  const handleUpload = async (e) => {
    startLoading()
    const files = e.target.files;
    const formData = new FormData()
    formData.append('property_id', property.id)

    for(let i=0; i<files.length; i++) {
      formData.append('files[]', files[i])
    }

    await uploadFloorPlan(formData)
    fetchImgs()
    stopLoading()
  }

  useEffect(()=>{
    setItems(property.floorplans)
  }, [property.floorpalnss])

  const handleClose = () => {
    fetchProperty(property.id)
  }

  const handleDelete = async (id) => {
    if(window.confirm('确定删除?')) {
      startLoading()
      await deleteFloorPlan(id)
      fetchImgs()
      stopLoading()
    }
  }

  return (
    <ISChipDialog
      chipLabel="户型图"
      dialogTitle="户型图"
      maxWidth="sm"
      fullWidth
      onClose={handleClose}
      iconAction={
        <>
          <input
            accept="image/*"
            id={"files"}
            type="file"
            multiple
            ref={ref}
            onChange={handleUpload}
            style = {{display:'none'}}
          />
          <label htmlFor={"files"}>
          <IconButton onClick={handleClick}>
            <UploadOutlined />
          </IconButton>
          </label>
        </>
      }
    >
      {items && items.map((item, index) => 
        <Stack key={item.id}>
          <img src={item.url} alt="" width="100%" />
          <Button onClick={()=>handleDelete(item.id)}>删除图片</Button>
        </Stack>
      )} 
    </ISChipDialog>
  )
}