import { Box, Skeleton, Stack } from "@mui/material";
import { updateAgent } from "api/agent";
import RSCard from "ui/RSCard";
import RSTextEditor from "ui/RSTextEditor";
import AgentTypeSelector from "./AgentTypeSelector";
import RSCheckChip from "ui/RSCheckChip";

export default function AgentInfo({ agent, setAgent, callback }) {

  const handleCallback = (updatedData) => {
    const updatedAgent = {...agent, ...updatedData}
    setAgent(updatedAgent)
    if(callback) callback(updatedAgent)
    updateAgent(updatedAgent)
  }

  const handleSelectType = (selectedType) => {
    handleCallback({type: selectedType.key})
  }

  const handleToggleHide = (checked) => {
    handleCallback({hide: checked})
  }

  const handleToggleRate = (checked) => {
    handleCallback({high_rate: checked})
  }

  return (
    <RSCard title="渠道信息">
      {!agent && <Skeleton />}
      {agent &&
      <Stack>
        <RSTextEditor item={agent} entity="name" callback={handleCallback} prefix="名称：" />
        <RSTextEditor item={agent} entity="note" callback={handleCallback} multiline prefix="备注：" />
        <Stack direction="row">
          <AgentTypeSelector type={agent.type} onSelect={handleSelectType} />
          <RSCheckChip checked={agent.high_rate} label="高比例" onClick={handleToggleRate} />
          <Box flexGrow={1} />
          <RSCheckChip checked={agent.hide} label="隐藏" onClick={handleToggleHide} />
        </Stack>
      </Stack>
      }
    </RSCard>
  )
}