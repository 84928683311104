import { call } from "./core";

/* 前端 香港房源 */

// 房源列表
export const getHKPropertyList = async (page) => {
  return await call('web/HK/getPropertyList', {page: page})
}

// 大学列表
export const getHKUniversities = async () => {
  return await call('web/HK/getUniversityList')
}

// 地图房源列表
export const getHKPropertyListForMap = async () => {
  return await call('web/HK/getAllPropertiesForMap')
}

export const filterRooms = async (filter) => {
  return await call('web/HK/filterRooms', {filter: filter})
}