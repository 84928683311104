

import { Chip, Menu, Stack, Typography, MenuItem, Skeleton, Divider } from "@mui/material";
import { getAllStaffs, updateBooking } from "api/admin/booking";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCNTeam, setUKTeam, staffsStates } from "redux/staffsSlice";

/**
 * 顾问选择器
 * @param { object } booking 
 * @returns 
 */
export default function StaffSelector({ booking, setBooking }) {

  const [anchorEl, setAnchorEl] = useState(null)
  const open = Boolean(anchorEl);
  const { CNTeam, UKTeam } = useSelector(staffsStates)
  const dispatch = useDispatch()

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorEl(null)
  }

  const fetchStaffs = async () => {
    if(!CNTeam || CNTeam.length === 0) {
      const data = await getAllStaffs()
      if(data) {
        dispatch(setCNTeam(data.cn_team))
        dispatch(setUKTeam(data.uk_team))
      }
    }
  }

  const handleSelect = async (item) => {
    closeMenu()
    const data = { id: booking.id, admin_id: item.id, admin_name: item.name+' '+item.name_en}
    setBooking({ ...booking, ...data })
    updateBooking(data)
  } 

  useEffect(() => {
    fetchStaffs()
  }, [])

  return (
    <>
    <Chip label={booking.admin_name} onClick={openMenu} />

    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={closeMenu}
    >
      <Stack alignItems="center" sx={{borderBottom: '1px solid #ccc', paddingBottom: '0.5rem', marginBottom: '0.5rem'}}>
        <Typography><b>中国办</b></Typography>
      </Stack>
      {!CNTeam && <Skeleton />}
      {CNTeam && CNTeam.map((item, index) => <MenuItem key={index} onClick={()=>handleSelect(item)} dense>{item.name+' '+item.name_en}</MenuItem>)}
      <Divider />
      <Stack alignItems="center" sx={{borderBottom: '1px solid #ccc', paddingBottom: '0.5rem', marginBottom: '0.5rem'}}>
        <Typography><b>英国办</b></Typography>
      </Stack>
      {!UKTeam && <Skeleton />}
      {UKTeam && UKTeam.map((item, index) => <MenuItem key={index} onClick={()=>handleSelect(item)} dense>{item.name+' '+item.name_en}</MenuItem>)}
    </Menu>
    </>
  )
}