/**
 * 价格格式化 - 简洁显示
 * 如123.00显示为123，123.40显示为123.4，123.45显示为123.45
 * @param {*} priceString 
 * @returns 
 */
export function formatPrice(priceString) {
  const priceNumber = parseFloat(priceString);
  const roundedNumber = Math.round(priceNumber * 100) / 100; // 将价格四舍五入到两位小数

  // 检查小数部分是否为零
  const isInteger = (roundedNumber % 1) === 0;

  // 如果小数部分为零，则只显示整数部分
  if (isInteger) {
    return roundedNumber.toFixed(0); // 将数值格式化为整数
  } else {
    return roundedNumber.toFixed(2); // 将数值格式化为保留两位小数
  }
}