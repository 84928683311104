/* 收支明细 */

import { Skeleton } from "@mui/material";
import { getTransactions, updateTransaction } from "api/finance";
import Booking from "components/booking/Booking";
import AccountSelector from "components/selector/AccountSelector";
import { useEffect, useState } from "react";
import ISCardActions from "ui/ISCardActions";
import RSCard from "ui/RSCard";
import RSMonthSelector from "ui/RSMonthSelector";
import RSTable from "ui/RSTable";
import RSTextEditor from "ui/RSTextEditor";
import RSYearSelector from "ui/RSYearSelector";
import { countryIDtoCurrency } from "configs/country";
import RSCheckChip from "ui/RSCheckChip";

export default function Transactions() {
  const date = new Date()
  const [year, setYear] = useState(date.getFullYear())
  const [month, setMonth] = useState(date.getMonth() + 1)
  const [account, setAccount] = useState('1')
  const [transactions, setTransactions] = useState()

  const fetchData = async () => {
    setTransactions(null)
    const data = await getTransactions(account, year, month)
    if(data) setTransactions(data)
  }

  const handleSelect = (item, option) => {
    updateTransaction({id: item.id, account: option.key})
  }

  const columns = [
    {
      title: '日期',
      dataIndex: 'date'
    },
    {
      title: '订单',
      render: (item) => <Booking bookingID={item.rent_id} />
    },
    {
      title: '账户',
      render: (item) => <AccountSelector accountID={item.account} onSelect={option=>handleSelect(item, option)} />
    },
    {
      title: '货币',
      render: (item) => countryIDtoCurrency(item.currency_type)
    },
    {
      title: '收入',
      render: (item) => { if(Number(item.amount) > 0) return <RSTextEditor item={item} entity="amount" callback={data=>updateTransaction(data)} width="10px" /> },
      width: '10%'
    },
    {
      title: '支出',
      render: (item) => { if(Number(item.amount) <= 0) return <RSTextEditor item={item} entity="amount" callback={data=>updateTransaction(data)} width="10px" /> },
      width: '10%'
    },
    {
      title: '备注',
      dataIndex: 'note'
    },
    {
      title: '锁定',
      render: (item) => <RSCheckChip label="锁定" checked={item.locked} onClick={checked => updateTransaction({id: item.id, locked:checked})} />
    }
  ]

  useEffect(() => {
    fetchData()
  }, [year, month, account])

  return (
    <RSCard
      title="收支明细"
      action={
        <ISCardActions>
          <AccountSelector accountID={account} onSelect={option=>setAccount(option.key)} />
          <RSYearSelector year={year} onSelect={y=>setYear(y)} />
          <RSMonthSelector month={month} onSelect={m=>setMonth(m)} />
        </ISCardActions>
      }
    >
      {transactions && <RSTable data={transactions} columns={columns} />}
      {!transactions && <Skeleton />}
    </RSCard>
  )
}