// 订单数据计算

import { countryIDtoLeaseUnit } from "../configs/country";

/**
 * 首字母大写
 * @param {string} str 
 * @returns string
 * 1 bed flat => 1 Bed Flat
 */
export function formatName(str) {
  str = str.toLowerCase();
  var words = str.split(' ');
  for (var i = 0; i < words.length; i++) {
    var word = words[i];
    words[i] = word.charAt(0).toUpperCase() + word.slice(1);
  }
  return words.join(' ');
}

/**
 * 租期
 * @param { string } checkIn 入住日期 YYYY-MM-DD
 * @param { string } checkOut 结束日期 YYYY-MM-DD
 * @returns 
 */
export function lease(checkIn, checkOut) {
    // 将日期字符串转换为日期对象
    const checkInDate = new Date(checkIn);
    const checkOutDate = new Date(checkOut);
  
    // 计算租期的毫秒数差异
    const timeDiff = checkOutDate - checkInDate;
  
    // 计算周数和天数
    const weeks = Math.floor(timeDiff / (1000 * 60 * 60 * 24 * 7));
    const days = Math.floor((timeDiff % (1000 * 60 * 60 * 24 * 7)) / (1000 * 60 * 60 * 24));
  
     // 构建结果字符串
    let result;
    if (weeks > 0 && days > 0) {
      result = `${weeks}W${days}D`;
    } else if (weeks > 0) {
      result = `${weeks}W`;
    } else {
      result = `${days}D`;
    }
  
    return result;
}

/**
 * 总租金
 * @param {*} checkIn 
 * @param {*} checkOut 
 * @param {*} price 
 * @param {*} leaseUnit 
 * @returns 
 */
export function rent(booking) {

  const checkIn = booking.check_in
  const checkOut = booking.check_out
  const price = booking.price
  const leaseUnit = countryIDtoLeaseUnit(booking.country_id)

  const checkInDate = new Date(checkIn);
  const checkOutDate = new Date(checkOut);
  const timeDiff = checkOutDate - checkInDate;
  const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
  let dayPrice = 0
  if(leaseUnit === '月') dayPrice = price/30
  else if(leaseUnit === '周') dayPrice = price/7
  return (dayPrice * days).toFixed(2)
}