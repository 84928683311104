/* 全站搜索 */

import { Box, Drawer, IconButton, Stack } from "@mui/material"
import { Search as SearchIcon } from "@mui/icons-material"
import { useState } from "react"
import BookingSearch from "components/booking/BookingSearch"
import RSSelector from "ui/RSSelector"
import PropertySearch from "components/property/PropertySearch"

export default function Search() {

  const [open, setOpen] = useState(false)
  const [searchPanel, setSearchPanel] = useState('booking')

  const options = [
    {key:'booking', value: '订单搜索'},
    {key:'property', value: '房源搜索'}
  ]

  const handleSelect = (option) => setSearchPanel(option.key)
  
  const openDrawer = () => {
    setOpen(true)
  }

  const closeDrawer = () => {
    setOpen(false)
  }

  return (
    <>
      <IconButton onClick={openDrawer}>
        <SearchIcon />
      </IconButton>
      <Drawer
        anchor="right"
        open={open}
        onClose={closeDrawer}
      >
        <Box sx={{width: '300px'}}>
          <Stack>
            <RSSelector options={options} selectedKey={searchPanel} onSelect={handleSelect} />
            {searchPanel === 'booking' && <BookingSearch />}
            {searchPanel === 'property' && <PropertySearch />}
          </Stack>
        </Box>
      </Drawer>
    </>
  )
}