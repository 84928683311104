import { Close } from "@mui/icons-material";
import { Box, Chip, IconButton, Skeleton, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { appStates } from "redux/appSlice";
import RSCard from "ui/RSCard";
import RSDialog from "ui/RSDialog";
import RowStack from "ui/RowStack";
import Spacer from "ui/Spacer";
import { hasPermission } from "utils/layout";

/**
 * 员工备注 
 * 用于订单备注和房源备注
 * @param {func} getComments
 * @param {func} addComment 
 * @param {func} delComment
 */

export default function StaffComments({
  title="顾问留言", 
  getComments,
  addComment,
  delComment
}) {
  const [ loading, setLoading ] = useState(false)
  const [comments, setComments] = useState([])

  const fetchComments = async () => {
    setLoading(true)
    const data = await getComments()
    if(data) setComments(data)
    setLoading(false)
  }

  useEffect(() => {
    fetchComments()
  }, [])

  const handleAddComment = async (message) => {
    setLoading(true)
    const newComment = await addComment(message)
    setComments([newComment, ...comments])
    setLoading(false)
  }

  const handleDelComment = (commentID) => {
    if(window.confirm("确定删除?")) {
      setComments(comments.filter(comment => (commentID!==comment.id)))
      delComment(commentID)
    }
  }

  return (
    <RSCard 
      title={title} 
      action={<CommentChip addComment={handleAddComment} />} 
    >
      <Stack spacing={0.5}>
        
      { loading && <Skeleton />}
      {comments && comments.map(comment => <Comment key={comment.id} comment={comment} delComment={handleDelComment} />)}
      </Stack>
    </RSCard>
  )
}

/**
 * 一条留言
 * @param {*} param0 
 */
function Comment({ comment, delComment }) {
  
  const [showDelBtn, setShowDelBtn] = useState('none')
  const { user } = useSelector(appStates)

  return (
    <RowStack 
      onMouseEnter={()=>{setShowDelBtn('')}}
      onMouseLeave={()=>{setShowDelBtn('none')}}
    >
      <Stack spacing={0}>
        <Typography><b>{comment.name}: </b> {comment.message}</Typography>
        <Typography variant="caption">{comment.date}</Typography>
      </Stack>
    
      <Spacer />

      {( hasPermission("staff") || comment.admin_id === user.id ) && 
        <Chip label="删除" onClick={()=>delComment(comment.id)} style={{display: showDelBtn}} />
      }
    </RowStack>
  )
}

function CommentChip({ addComment }) {
  const [open, setOpen] = useState(false)
  const openDialog = () => { setOpen(true) }
  const closeDialog = () => { setOpen(false) }
  const [value, setValue] = useState('')

  const change = (e) => {
    setValue(e.target.value)
  }

  const press = (e) => {
    if (e.key === 'Enter' && !e.ctrlKey) {
      closeDialog()
      addComment(value)
    }
  }

  return (
    <>
      <Chip label="留言" onClick={openDialog} />
      <RSDialog 
        noTitle
        open={open}
        onClose={closeDialog}
        fullWidth
      >
        <TextField 
          fullWidth
          multiline
          onChange={change} 
          onKeyDown={press} 
          value={value}
        />
      </RSDialog>
    </>
  )
}