

/* 国家参数 */
const UK = {
  id: 0,
  name: "英国",
  nameEN: "United Kingdom",
  flag: 'gb',
  currency: '£',
  currencyCode: 'GBP',
  leaseUnitCN: '周',
  defaultCityID: 19,
  defaultCityNameCN: '伦敦'
}

const CN = {
  id: 1,
  currency: '¥',
  currencyCode: 'CNY',
}

/*
const US = {
  id: 2,
  name: '美国',
  currency: '$',
}
*/

/*
const SG = {
  id: 3,
  name: '新加坡',
  currency: 'S$',
}
*/

const AU = {
  id: 4,
  name: '澳洲',
  nameEN: "Australia",
  flag: 'au',
  currency: '$',
  currencyCode: 'AUD',
  leaseUnitCN: '周',
  defaultCityID: 55,
  defaultCityNameCN: '悉尼'
}

/*
const ES = {
  id: 5,
  name: '西班牙',
  currency: '€',
}
*/

const JP = {
  id: 6,
  name: '日本',
  nameEN: "Japan",
  flag: 'jp',
  currency: '¥',
  currencyCode: 'JPY',
  leaseUnitCN: '月',
  defaultCityID: 404,
  defaultCityNameCN: '东京'
}


const NZ = {
  id: 7,
  name: '新西兰',
  nameEN: "New Zealand",
  flag: 'nz',
  currency: '$',
  currencyCode: 'NZD',
  leaseUnitCN: '周',
  defaultCityID: 547,
  defaultCityNameCN: '惠灵顿'
}



/*
const KR = {
  id: 8,
  name: '韩国',
  currency: '₩',
}
*/

const IE = {
  id: 9,
  name: '爱尔兰',
  nameEN: "Ireland",
  flag: 'ie',
  currency: '€',
  currencyCode: 'EUR',
  leaseUnitCN: '周',
  defaultCityID: 489,
  defaultCityNameCN: '都柏林'
}

const CA = {
  id: 10,
  name: '加拿大',
  flag: 'ca',
  currency: '$',
  currencyCode: 'CAN',
}

const HK = {
  id: 11,
  name: '中国香港',
  flag: 'hk',
  currency: '$',
  currencyCode: 'HKD',
  leaseUnitCN: '月',
  defaultCityID: 542,
  defaultCityName: 'Hong Kong',
  defaultCityNameCN: '香港'
}

const country = [UK, CN, AU, JP, IE, CA, HK, NZ]

export function getCountryByID(countryID) {
  for(let i=0; i<country.length; i++) {
    if(country[i].id === parseInt(countryID))
      return country[i]
  }
  throw new Error("There are no such country configs!")
}

/* 编号 uk, au... */
export function countryIDtoCode(countryID) {
  const country = getCountryByID(countryID)
  if(!country.flag) throw new Error("This country config does not have a default city CODE!")
  return country.flag
}

export function countryIDToName(countryID) {
  const country = getCountryByID(countryID)
  if(!country) return '';
  return country.name
}

export function countryIDToDefaultCityID(countryID) {
  const country = getCountryByID(countryID)
  if(!country.defaultCityID) throw new Error("This country config does not have a default city ID!")
  return country.defaultCityID
}

export function countryIDToNameEN(countryID) {
  const country = getCountryByID(countryID)
  if(!country) return '';
  return country.nameEN?country.nameEN:country.name
}

export function countryIDtoFlag(countryID) {
  const country = getCountryByID(countryID)
  if(!country) return '';
  if(!country) return countryID
  if(!country.flag) return countryID
  return <span className={`fi fi-${country.flag}`} />
}

// uk => 0
export function countryCodeToID(countryCode) {
  for(let i=0; i<country.length; i++) {
    if(country[i].flag === countryCode)
      return country[i].id
  }
  return null
}

/**
 * 国家ID转货币付号
 * @param { string | int } countryID 
 * @returns 
 */
export function countryIDtoCurrency(countryID, simple = false) {
  const country = getCountryByID(Number(countryID))
  if(!country) return '';
  if(simple) return country.currency
  else return country.currencyCode+' '+country.currency
}

/**
 * 租期单位
 * @param { string | int } countryID
 * @returns 
 */
export function countryIDtoLeaseUnit(countryID) {
  const country = getCountryByID(Number(countryID))
  if(!country) return '';
  return country.leaseUnitCN
}

export default country