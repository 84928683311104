import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import { MenuItem } from "../components/MenuItems";

const menu = {
  text: '员工管理',
  icon: GroupOutlinedIcon,
  path: "/staff/management"
}

export default function StaffMenu() {

  return <MenuItem menu={menu} />
}