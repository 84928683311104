import getWebsite from "libs/site";
import { useSelector } from "react-redux";
import { propertyStates } from "redux/propertySlice";
import ChipLink from "ui/ChipLink";

/**
 * 前台房源URL
 * @returns 
 */
export default function FrontendLink() {
  const { property } = useSelector(propertyStates)
  const label = "前台"
  const website = getWebsite()
  const url = website + 'property/view/' + property.id
  return <ChipLink label={label} href={url} />
}