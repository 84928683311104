import { CardActions, Chip } from "@mui/material"
import { getInvoiceBookings } from "api/agent"
import { useState } from "react"
import RSDialog from "ui/RSDialog"
import AgentBookingsSelector from "./AgentBookingsSelecotr"
import RSTable from "ui/RSTable"
import bookingTableColumns from "components/booking/table/bookingTableColumns"
import { updateBooking } from "api/admin/booking"
import RSTextEditor from "ui/RSTextEditor"
import InvoiceChip from "components/provider/commission/InvoiceChip"
import CSVExportChip from "ui/CSVExportChip"
import { lease, rent } from "utils/booking"

/**
 * 返佣相关订单
 * @param {*} param0 
 * @returns 
 */
export default function AgentInvoiceBookings({ invoiceID, unpaidBookings, setUnpaidBookings }) {

  const [open, setOpen] = useState(false)
  const [bookings, setBookings] = useState([])
  const [commission, setCommission] = useState(0)

  const columns = getColumns(bookings, setBookings)

  const openDialog = () => {
    setOpen(true)
    loadBookings()
  }

  const loadBookings = async () => {
    setBookings(null)
    const data = await getInvoiceBookings(invoiceID)
    if(data) {
      setBookings(data)
      const totalAmount = data.reduce(function(sum, current) {
        return sum + Number(current.commission_amount);
      }, 0);
      setCommission(totalAmount)
    }
    else setBookings([])
  }

  const closeDialog = () => {
    setBookings([])
    setOpen(false)
  }

  return (
    <>
      <Chip label="订单" onClick={openDialog} />  
      <RSDialog
        title="返佣相关订单"
        open={open}
        onClose={closeDialog}
        maxWidth="lg"
        fullWidth
        action={
          <CardActions>
            {bookings && <Chip label={"返佣"+commission.toFixed(2)} />}
            {bookings && <CSVExportChip filename={'AL-'+invoiceID} data={getCSVData(bookings)} />}
            <AgentBookingsSelector invoiceID={invoiceID} bookings={bookings} setBookings={setBookings} unpaidBookings={unpaidBookings} setUnpaidBookings={setUnpaidBookings} />
          </CardActions>
        }
      >
        <RSTable data={bookings} columns={columns} />
      </RSDialog>
    </>
  )
}

/**
 * 返佣相关订单列表列
 * @param {*} bookings 
 * @param {*} setBookings 
 * @returns 
 */
function getColumns(bookings, setBookings) {
  const columns = bookingTableColumns(bookings, setBookings)

  columns.push(
    {
      title: '佣金 ID',
      render: (booking) => <InvoiceChip invoiceID={booking.commission_invoice_id} paid={booking.paid} />
    }
  )

  columns.push(
    {
      title: '佣金金额',
      render: (booking) => <RSTextEditor item={booking} entity="commission_amount" callback={updateBooking} />,
      width: '10%'
    }
  )

  columns.push(
    {
      title: '佣金备注',
      render: (booking) => <RSTextEditor item={booking} entity="commission_note" callback={updateBooking} />
    }
  )

  return columns
}


function getCSVData(bookings) {

  const data = bookings.map(booking => ({
    'Booking ID': booking.id,
    'Customer': booking.customer_name_en,
    'City': booking.city,
    'Accommodation': booking.accommodation,
    'Room type': booking.room_type,
    'Check in': booking.check_in,
    'Lease': lease(booking.check_in, booking.check_out),
    'Rent': booking.price,
    'Total rent': rent(booking),
    'State': booking.state==='2'?'Cancelled':'Complete',
    'Commission': booking.commission_amount,
  }))

  return data
}