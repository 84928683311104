import agentBookingsColumns from "../../../components/agent/componnets/agentBookingsColumns"
import Agent from "../../../components/agent/Agent"

export default function unpaidBookingsColumns() {

  const columns = agentBookingsColumns()

  columns.push(
    {
      title: '渠道',
      render: (booking) => <Agent agentID={booking.agent_id} agentName={booking.agent_name} display="chip" />
    }
  )

  return columns
}