import RowStack from "ui/RowStack"
import ButtonPaid from "./ButtonPaid"
import ButtonRefund from "./ButtonRefund"

export default function PaymentButton({ payment, reloadPayments, reloadTransactions }) {

  const callback = () => {
    reloadPayments()
    reloadTransactions()
  }

  switch(Number(payment.status)) {
    case 1:
      return (
        <RowStack>
          <ButtonPaid paymentID={payment.id} callback={callback} />
          <ButtonRefund payment={payment} callback={callback} />
        </RowStack>
      )
      
    case 3:
      return <ButtonRefund payment={payment} callback={callback} />

    case 4:
      return <ButtonRefund payment={payment} callback={callback} />  

    default:
      return null
  }
}