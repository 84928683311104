import { Skeleton, Stack } from "@mui/material";
import { getAgentList } from "api/agent";
import { useEffect, useState } from "react";
import RSCard from "ui/RSCard";
import AgentTypeSelector from "./componnets/AgentTypeSelector";
import Agent from "components/agent/Agent";
import AgentSearch from "./componnets/AgentSearch";

/**
 * 渠道列表
 * @returns 
 */
export default function AgentList() {

  const [agents, setAgents] = useState([])
  const [type, setType] = useState('5')

  const fetchAgentList = async () => {
    const data = await getAgentList(type)
    if(data) setAgents(data)
  }

  useEffect(() => {
    fetchAgentList()
  }, [type])

  const handleSelectType = (option) => {
    setType(option.key)
  }

  const handleAgentUpdated = (updatedAgent) => {
    const updatedAgents = agents.map( agent => 
      agent.id === updatedAgent.id ? { ...agent, ...updatedAgent} : agent
    )
    const filteredAgents = updatedAgents.filter(agent => (agent.type === type && Number(agent.hide) === 0))
    
    setAgents(filteredAgents)
  }

  const Action = () => 
  <Stack direction="row" alignItems="center" spacing={1}>
    <AgentTypeSelector type={type} onSelect={handleSelectType} />
    <AgentSearch />
  </Stack>

  return (
    <Stack>
      <RSCard title="渠道列表" action={<Action />}>
        <Stack spacing={1}>
          { agents.length === 0 && <Skeleton /> }
          {
            agents.map( agent => 
              <Agent 
                key={agent.id} 
                agentName={agent.name} 
                agentID={agent.id} 
                callback={handleAgentUpdated}
              /> 
            )
          }
        </Stack>
      </RSCard>
    </Stack>
  )
}