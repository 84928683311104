import { Stack, Typography } from "@mui/material";
import RSCard from "ui/RSCard";
import AgentSelector from "./AgentSelector";
import SuperiorSelector from "./SuperiorSelector";

/**
 * 渠道
 * @param {*} booking
 * @param {*} setBooking
 * @returns 
 */
export default function AgentInfo({ booking, setBooking }) {

  return (
    <RSCard title={"渠道"}>
      { booking &&
      <Stack>
        <Stack direction="row" alignItems="center">
          <Typography>获客</Typography>
          <AgentSelector booking={booking} setBooking={setBooking} />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Typography>代理</Typography>
          <SuperiorSelector booking={booking} setBooking={setBooking} />
        </Stack>
      </Stack>
      }
    </RSCard>
  )
}