import { call } from "../core";

/* 员工 */

/**
 * 员工列表
 * @returns array
 */
export const getStaffs = async () => {
  return await call('admin/staff/getStaffList')
}

/**
 * 更新员工信息
 * @param {object} data Staff Object
 */
export const updateStaff = async (data) => {
  await call('admin/staff/updateStaff', data)
}

/**
 * 员工完成订单列表
 * @param { string } staffID 
 * @param { string } year 
 * @returns 
 */
export const getStaffCompleteBookings = async ( staffID, year ) => {
  return await call('admin/staff/bonus/bookingList', {staff_id: staffID, year: year})
}

/**
 * 更新奖金金额
 * @param { object } data {id, bonus} 
 */
export const updateBonus = async (data) => {
  await call('admin/staff/bonus/updateBonus', data)
}

/**
 * 奖金记录
 * @param { string } staffID 
 * @param { string } year 
 * @returns 
 */
export const getBonusRecord = async ( staffID, year ) => {
  return await call('admin/staff/bonus/record', {staff_id: staffID, year: year})
}

/**
 * 更新奖金记录
 * @param { object } data Record Object 
 */
export const updateRecord = async (data) => {
  await call('admin/staff/bonus/updateRecord', data)
}

/**
 * 删除奖金记录
 * @param { string } id Record ID 
 */
export const deleteRecord = async (id) => {
  await call('admin/staff/bonus/deleteRecord/'+id)
}

/**
 * 员工权限
 * @returns string 
 */
export const getStaffPermissions = async (staffID) => {
  return await call('admin/staff/getStaffPermissions/'+staffID)
}

/**
 * 员工业绩数据
 * @param {*} filter - {staff_id, year} 
 * @returns 
 */
export const getStaffPerformance = async (filter) => {
  return await call('admin/staff/getPerformance', filter)
}