import { getCompleteBookingChartData } from "api/admin/analytics"
import BarChart from "components/chart/BarChart"
import { countryCodeToID, countryIDToName } from "configs/country"
import { useEffect, useState } from "react"
import RSCard from "ui/RSCard"
import RSSelector from "ui/RSSelector"
import RSYearSelector from "ui/RSYearSelector"
import RowStack from "ui/RowStack"

/**
 * 年度完成订单柱状图
 * @param {*} country - 'uk'
 * @returns 
 */
export default function AnnualCompleteBookingChart({ country }) {
  
  const date = new Date()
  const [year, setYear] = useState(date.getFullYear())
  const [data, setData] = useState()
  const [type, setType] = useState('0')
  const countryID = countryCodeToID(country)

  const typeOptions = [
    {key: '0', value: '全部'},
    {key: '1', value: '流量'},
    {key: '2', value: '渠道'}
  ]

  const fetchData = async () => {
    setData(null)
    const res = await getCompleteBookingChartData(countryID, year, type)
    if(res) setData(res)
  }

  useEffect(() => {
    if(year) fetchData()
  }, [year, type])

  const handleSelect = (option) => {
    setType(option.key)
  }


  return (
    <RSCard
      title={countryIDToName(countryID) + " - 完成订单"}
      action={
      <RowStack>
        <RSSelector options={typeOptions} selectedKey={type} onSelect={handleSelect} />
        <RSYearSelector year={year} onSelect={setYear} />
      </RowStack>
      }
    >
      <BarChart data={data} />
    </RSCard>
  )
}