import { Typography } from "@mui/material";

/**
 * 可点击的文字
 * @param { string } text 文字
 * @param { function } onClick 点击事件 
 * @returns 
 */
export default function RSTextButton({text, onClick}) {
  return (
    <Typography
      onClick={onClick}
      sx={{
        textDecoration: 'none',
        color: 'inherit',
        cursor: 'pointer'
      }}
    >
      {text}
    </Typography>
  )
}