import { GoogleMap, MarkerF } from '@react-google-maps/api'
import { useRef } from "react";
import Dialog from "ui/Dialog";
import { Typography } from "@mui/material";
import { useSelector } from 'react-redux';
import { propertyStates } from 'redux/propertySlice';
import RowStack from 'ui/RowStack';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import Link from 'ui/Link';
import { appStates } from 'redux/appSlice';

/**
 * 房源 - 谷歌地图
 */
export default function GoogleMapButton() {

  const ref = useRef()
  const { property } = useSelector(propertyStates)
  const { googleMapLoaded } = useSelector(appStates)

  if(property.lat === '' || property.lng === '') return null
  const center = {lat: Number(property.lat), lng: Number(property.lng)}

  const containerStyle = {
    width: '100%',
    height: '600px'
  };

  const openDialog = () => {
    ref.current.open()
  }

  return (
    <RowStack>
      <PublicOutlinedIcon />
      <Link onClick={openDialog}>
        <Typography>地图</Typography>
      </Link>

      <Dialog
        title="地图"
        ref={ref}
        fullWidth
        maxWidth="md"
        noContentPadding
      >

        { googleMapLoaded &&
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={14}
          >
            <MarkerF 
              title={property.name} 
              position={center} 
            />
          </GoogleMap>
        }

      </Dialog>
    </RowStack>
  )
}