import { useSelector } from "react-redux";
import { fetchProperty, propertyStates } from "redux/propertySlice";
import CitySelector from "components/selector/CitySelector";
import { updateProperty } from "api/property";
import { Chip } from "@mui/material";

/**
 * 房源城市选择
 * @returns 
 */
export default function PropertyCitySelector() {
  const { property } = useSelector(propertyStates)
  const handleSelect = async (option) => {
    await updateProperty({
      id: property.id,
      city: option.value,
      city_id: option.key
    })
    fetchProperty(property.id) 
  }
  if(property.country_id === '11') return <Chip label="香港" />
  return <CitySelector cityID={property.city_id} countryID={property.country_id} onSelect={handleSelect}  />
}