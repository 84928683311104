import { Box, Card, CardContent, Grid, Stack } from "@mui/material";

export default function AuthCard({children}) {
 
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: '100vh' }}
    >
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Stack spacing={2}>
              <Box alignSelf='center' mt={1} mx={1}>
                <img alt="logo" src="/logo/logo-399x143.png" width="250" />
              </Box>
              {children}
            </Stack>
          </CardContent>
        </Card>
      </Grid>         
    </Grid> 
  )
}