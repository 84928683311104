import { call } from "../core";

/**
 * 获得账号信息
 * @returns 
 */
export const getMyInfo = async() => {
  return await call('admin/me/auth/getMyInfo')
}

/* 我的业绩 */
export const getMyPerformance = async (year) => {
  return await call('admin/me/staff/getPerformance', {year: year})
}