/* 空房时间 */

import { Typography } from "@mui/material";
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import RowStack from "ui/RowStack";

export default function Available({ date }){
  return (
    <RowStack>
      <EventAvailableOutlinedIcon />
      <Typography>{date} 空</Typography>
    </RowStack>
  )
}