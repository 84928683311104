import { propertyStates } from "redux/propertySlice";
import { Box, Chip, Divider, Stack } from "@mui/material";
import { useSelector } from "react-redux";

const FeatureChip = ({label}) => {
  if(label.includes('*')) return <Chip label={label.replace('*', '')} color='error' size='small' />
  else return <Chip label={label} color="warning" size="small" />
}

/**
 * 房源特色 Chips 列表
 * @param { string } feature - xxx,xxx,xxx
 * @returns 
 */
export default function PropertyFeatures({ max, divider }) {

  const { property } = useSelector(propertyStates)
  const featuresStr = property.feature
  if( featuresStr === '' ) return null
  const features = featuresStr.split(',')

  return (
    <Stack>
      <Stack 
        direction="row" 
        spacing={0.5} 
        sx={{ 
          flexWrap: 'wrap',  
          '& > :not(:last-child)': { marginRight: '4px', marginLeft: 0 }, 
          '& > :last-child': { marginLeft: 0 } 
        }}
      >
        {
          features.map((item, index) => {
            if (max && index >= max) {
              return null; // Skip rendering this item
            }
            else return <Box pb={0.5}><FeatureChip key={item} label={item} /></Box>
          })
        }
      </Stack>
      {divider && <Divider />}
    </Stack>
  )
}