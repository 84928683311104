import { createBrowserRouter } from "react-router-dom";

import Layout from "layout/Layout";
import Error from "pages/layout/Error";

// Auth
import SignIn from "pages/auth/SignIn";
import WeCom from "pages/auth/WeCom";

// Booking
import NewBookings from "pages/booking/new-bookings/NewBookings";

// Agent
import Agents from "pages/agent/management/Agents";
import AgentAllUnpaidBookings from "pages/agent/unpaid-bookings/AgentAllUnpaidBookings";

// Staff
import Commission from "pages/finance/commission/Commission";
import UninvoicedBookings from "pages/finance/uninvoiced-bookings/UninvoicedBookings";
import Cashback from "pages/finance/Cashback";
import StaffManagement from "pages/staff/StaffManagement";
import Transactions from "pages/finance/Transactions";
import CleanData from "pages/system/CleanData";


// HK
import HKLayout from 'pages/hk/HKLayout';
import HKProperty from "pages/hk/HKProperty";
import HKMap from "pages/hk/HKMap";
import HKProvider from "pages/hk/HKProvider";

// Au
import AULayout from "pages/au/AULayout";
import AUCity from "pages/au/AUCity";
import AUProperty from "pages/au/AUProperty";
import AUProvider from "pages/au/AUProvider";

// GB
import GBLayout from "pages/gb/GBLayout";
import GBCity from "pages/gb/GBCity";
import GBProperty from "pages/gb/GBProperty";
import GBProvider from "pages/gb/GBProvider";

// IE
import IELayout from "pages/ie/IELayout";
import IECity from "pages/ie/IECity";
import IEProperty from "pages/ie/IEProperty";
import IEProvider from "pages/ie/IEProvider";

// MY
import MyPerformance from "pages/my/MyPerformance";
import UKAnalytics from "pages/analytics/UKAnalytics";
import AUAnalytics from "pages/analytics/AUAnalytics";
import HKAnalytics from "pages/analytics/HKAnalytics";

import DataCleanPage from "pages/developer/data-clean/page";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    errorElement: <Error />,
    children: [
      // My 我的系列
      {
        path: "my/performance",
        element: <MyPerformance />
      },

      // Booking
      {
        path: "booking/new-bookings",
        element: <NewBookings />
      },
      // Property
      {
        path: "gb/",
        element: <GBLayout />,
        children: [
          {
            path: "city",
            element: <GBCity />
          },
          {
            path: "property",
            element: <GBProperty />
          },
          {
            path: "provider",
            element: <GBProvider />
          }
        ]
      },
      {
        path: "au/",
        element: <AULayout />,
        children: [
          {
            path: "city",
            element: <AUCity />
          },
          {
            path: "property",
            element: <AUProperty />
          },
          {
            path: "provider",
            element: <AUProvider />
          }
        ]
      },
      {
        path: "ie/",
        element: <IELayout />,
        children: [
          {
            path: "city",
            element: <IECity />
          },
          {
            path: "property",
            element: <IEProperty />
          },
          {
            path: "provider",
            element: <IEProvider />
          }
        ]
      },
      {
        path: "hk/",
        element: <HKLayout />,
        children: [
          {
            path: "property",
            element: <HKProperty />
          },
          {
            path: "map",
            element: <HKMap />
          },
          {
            path: "provider",
            element: <HKProvider />
          },
        ]
      },
      // Agent
      {
        path: "agent/management",
        element: <Agents />
      },
      {
        path: "agent/unpaid-bookings",
        element: <AgentAllUnpaidBookings />
      },
      // Finance
      {
        path: "finance/transactions",
        element: <Transactions />
      },
      {
        path: "finance/commission",
        element: <Commission />
      },
      {
        path: "/finance/uninvoiced-bookings",
        element: <UninvoicedBookings />
      },
      {
        path: "/finance/cashback",
        element: <Cashback />
      },
      // Staff
      {
        path: "staff/management",
        element: <StaffManagement />
      },
      // ---------------------------------------------------------------------
      // 数据分析
      {
        path: "analytics/uk",
        element: <UKAnalytics />
      },
      {
        path: "analytics/au",
        element: <AUAnalytics />
      },
      {
        path: "analytics/hk",
        element: <HKAnalytics />
      },
      // ---------------------------------------------------------------------
      // 系统内部
      {
        path: "system/clean-data",
        element: <CleanData />
      },
      {
        path: "developer/data-clean",
        element: <DataCleanPage />
      }

    ]
  },
  {
    path: "/auth/signIn",
    element: <SignIn />
  },
  {
    path: "/auth/wecom",
    element: <WeCom />
  }
]);

export default router