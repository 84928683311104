import { Card, Chip, Divider, Stack, Typography } from "@mui/material";
import { formatPrice }from "libs/booking";
import RowStack from "ui/RowStack";
import Spacer from "ui/Spacer";
import RSDialog from "ui/Dialog";
import { useState } from "react";
import { countryIDtoCurrency, countryIDtoLeaseUnit } from "libs/country";
import { useSelector } from "react-redux";
import { propertyStates } from "redux/propertySlice";

export default function PropertyRooms() {

  const { property } = useSelector(propertyStates)

  return (
    <Stack>
    {property.roomtypes && property.roomtypes.map(roomtype => 
      <Card key={roomtype.id}>
       
        <Stack spacing={0.5} padding={1.5}>
          <Stack direction="row" spacing={2}>
            <Stack spacing={1}>  
              <Typography sx={{fontSize: 18}}><b>{roomtype.name}</b></Typography>
              {roomtype.description !== '' && <Typography>{roomtype.description}</Typography>}
            </Stack>
            <Spacer />
            <RoomTypeImgs imgs={roomtype.imgs} />
          </Stack>

          
          {roomtype.prices.map(price => 
            <Stack spacing={0.5} key={price.id}>
              <Divider />
              <RowStack>
              <Typography><b>{countryIDtoCurrency(property.country_id)}{formatPrice(price.price)}/{countryIDtoLeaseUnit(property.country_id)}起</b></Typography>
              <Spacer />
              { price.state === '1' && <Chip label="满租" variant="outlined" color="error" size="small" /> }
              </RowStack>
              <RowStack spacing={3}>
                <Typography>起租: {price.start_date}</Typography>
                <Typography>租期: {price.weeks}{countryIDtoLeaseUnit(property.country_id)}</Typography>
              </RowStack>
              
              { price.note !== '' && <Typography variant="body2">{price.note}</Typography> }
            </Stack>
          )}

        </Stack>
      </Card>
    )}
    </Stack>
  )
}


function RoomTypeImgs({ imgs }) {

  const [open, setOpen] = useState(false)

  const openDialog = () => {
    setOpen(true)
  }

  const closeDialog = () => {
    setOpen(false)
  }

  return (
    <>
    {
      imgs && imgs.length > 0 &&
      <img src={imgs[0].url+'?x-oss-process=style/roomtype_thumbnail'} width={100} onClick={openDialog} alt="" />
    }
    <RSDialog
      title="房型图片"
      open={open}
      onClose={closeDialog}
      noContentPadding
    >
      <Stack>
      {
        imgs && imgs.length > 0 && 
        imgs.map(img => <img src={img.url+"?x-oss-process=style/desktop_property_list"} key={img.id} alt="" />)
      }
      </Stack>
    </RSDialog>
    </>
  )
}